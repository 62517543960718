import React from "react"
import Slider from "react-slick";
import AnimatedPage from "../components/AnimatedPage"
import SmoothScrollingButton from "../components/SmoothScrollingButton"

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const cardStyle = {
    backgroundColor: "#F0F2FD",
    borderRadius: "2.5rem",
    padding: "3rem 3rem 5rem",
    minHeight: "20rem",
}

const liButtonStyle = {
    background: "linear-gradient(120.13deg, #4A67FF -3.15%, #3956F0 8.51%, #798EFF 71.07%, #8496F4 102.88%)",
    border: "none",
    borderRadius: "2.5rem",
    padding: "1.125rem 3.125rem",
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: "700",
    lineHeight: "1.594rem",
    maxWidth: "17.75rem",
    width: "100%",
    maxHeight: "4.625rem",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    alignItems: "center",
    position: "relative",
    top: "-2.5rem",
    left: "50%",
    transform: "translateX(-50%)",
    cursor: "pointer",
}

const LiButton = ({ link }) => {
    return <button style={liButtonStyle} className="registerButtonAnimation "
        onClick={() => window.open(link, '_blank')}
    ><img src={require('../../images/landingpage/linkedin-logo.svg')} alt='Linkedin Logo' />
    </button>
}

const TEAM_SECTION = [
    {
        img: require('../../images/landingpage/team-img1.png'),
        title: 'Matt Clark ACMA,',
        designation: "Co-founder & CFO",
        points: [
            'Experienced CFO of VC-backed technology companies (both US and UK)',
            'Strong commercial viewpoint, has previously also acted as COO'
        ],
        link: 'https://www.linkedin.com/in/matt-clark-91ba80a4/'
    },
    {
        img: require('../../images/landingpage/team-img2.png'),
        title: 'Mark Bernstein ACA,',
        designation: "Co-founder & CEO",
        points: [
            'Experienced technology sector growth manager/CEO/NED/NEC',
            'Qualified chartered accountant with EY 30 years on listed and private company boards',
            'IPO experience (four companies to IPOs on LSE/AIM); two from startup to IPO;(largest to $1bn)',
            'In previous startups  taken equity investment from IBM, Motorola, BT, Sky, Dixons',
            'Start-ups/scaleups and 25+ acquisitions, disposals & exits',
            'Experienced angel investor '
        ],
        link: 'https://www.linkedin.com/in/markbernstein/'
    },
    {
        img: require('../../images/landingpage/team-img3.png'),
        title: 'Conor Davey,',
        designation: "Co-founder & Advisor",
        points: [
            `Part of the core executive team that over 20 plus years took Williams 
            Lea from £1M to £1.4B of global revenue, latterly as Group CEO managing 10,000 plus FTEs in 33 countries`,
            'Demonstrable experience in scaling B2B services businesses in the UK and internationally.',
            `Strong track record of designing, closing and delivering long term 
            revenue agreements with large corporates and government organisations`,
            'Angel investor in early-stage businesses'
        ],
        link: 'https://www.linkedin.com/in/conor-davey-b21a10bb/'
    }
]


const AboutUs = () => {
    return (
        <AnimatedPage>
            <div className="poppins-font-style about-us-page *">
                <SmoothScrollingButton />
                <React.Fragment className="poppins-font-style *">
                    {/*The Founding Team section*/}
                    <section className="team-sec">
                        {<img src={require('../../images/landingpage/sphere.png')} alt='team img' className="ar pa" style={{ top: "14%", left: "-3%", }} />}
                        <h3 className="c15 fs2-125rem fw400 mb1rem tac">The Founding Team</h3>
                        <div className="df jcsb aife team-about-column">
                            {TEAM_SECTION.map((obj, i, arr) => {
                                return (
                                    <div className={`pr df fdc jcc w30 team-box ${i === 0 && "mt0rem"} ${i === 2 && 'mt5rem'} `}>
                                        <div className="df jcc">
                                            <img src={obj?.img} className="team-img " alt='team img' />
                                        </div>
                                        <div className="pr" >
                                            <div className="card-style-about" style={{ ...cardStyle, minHeight: i === 1 ? "21rem" : '23rem', maxHeight: i === 0 || i === 2 ? "23rem" : "28rem" }}>
                                                <h5 className="c15 fs2rem fw500 tac" style={{ lineHeight: "2.788rem" }}>{obj?.title}</h5>
                                                <h6 className="c15 fs1rem fw300 tac mb1rem" style={{ lineHeight: "1.74rem" }}>{obj.designation}</h6>
                                                <ul>
                                                    {obj?.points?.map((str, index, arr) => {
                                                        return (
                                                            <li className="c14 fw400 fs0-875rem pt0-5rem pb0-5rem" style={{ lineHeight: "1.3rem" }}>
                                                                {str}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            <LiButton link={obj?.link} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>

                    {/* The CEO Speaks section*/}
                    <section className="ceo-sec">
                        <div className="ceo-inner df aic fww bsbb">
                            <div className="left-ceo-text w70 bsbb">
                                {/* <h5 className="fs2rem fw500 c15" style={{ lineHeight: "2.488rem" }}>The CEO Speaks</h5> */}
                                <div className="blockquote-icon mb1rem">
                                    <svg className="w3rem" width="76" height="44" viewBox="0 0 76 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M37.3288 5.09278C37.8604 5.51805 37.9667 6.04962 37.7541 6.95331L27.5478 42.3032C27.3883 42.9943 26.8036 43.4727 26.0594 43.4727H2.03207C1.07523 43.4727 0.703125 43.0474 0.703125 42.1438V38.529C0.756283 37.838 0.96892 37.2001 1.34102 36.6685L28.0262 1.47805C28.4515 0.84016 29.3552 0.627527 30.0462 1.05279L37.3288 5.09278ZM74.7519 5.09278C75.2835 5.51805 75.3898 6.04962 75.1772 6.95331L64.9709 42.3032C64.8114 43.0474 64.1203 43.5259 63.3761 43.4727H39.4551C38.4983 43.4727 38.1262 43.0474 38.1262 42.1438V38.529C38.1794 37.838 38.392 37.2001 38.7641 36.6685L65.343 1.47805C65.7682 0.84016 66.6719 0.62753 67.363 1.10595L74.7519 5.09278Z" fill="#7385BE" />
                                    </svg>
                                </div>
                                <blockquote className="fw400 fs1rem c14 mb1rem mt1rem" style={{ lineHeight: '2rem', letterSpacing: '0.055rem' }}>
                                    Where I am appointed Chairman, the first thing I do is ask that all the siloed corporate information around the organisation is uploaded into a single structured "living" data room with the user of checklists. This increases efficiency by making key information more available to the team, enabling me to undertake my own comprehensive due diligence on the state of the business, and better prepares us for quicker investment/growth/sale. My boards undertake an internal Disclosure Schedule review process every quarter linked to that living data room.</blockquote>
                                {/* <h5 className="fs1-25rem fw500 c15 mt1-5rem" style={{ lineHeight: "2.488rem" }}>The CEO Speaks</h5>  */}
                                <p className="fs1rem c15 fw500">Mark Bernstein, ACA</p>
                            </div>
                            <div className="righ-ceo-pic w30 tac">
                                <img className="w70" src={require('../../images/landingpage/ceo-pic.png')} alt='ceo-pic' />
                            </div>
                        </div>
                    </section>

                    {/* Our story section*/}
                    <section className="story-sec bsbb">
                        {/* <h4 className="fw500 mb1-5rem" style={{ fontSize: '3.12rem', lineHeight: "3.888rem" }}>Our Story</h4> */}
                        <p className="fs1rem fw400 mb1-5rem" style={{ lineHeight: "2rem", letterSpacing: "0.055rem" }}>
                            Originally the three of us got together in 2021 with the intention of finding one or more companies in which to become active non-executive investors together.  We became frustrated by the time we wasted on early-stage companies with impressive pitch decks which did not withstand our cursory due diligence and which was time-wasting for everyone – including the companies themselves .The process is also frustrating and inefficient for management teams, who can spend 50% of their precious time seeking investment or exit  – and for whom each diligence process is a discrete, differently formatted exercise.
                        </p>
                        <p className="fs1rem fw400 mb1-5rem" style={{ lineHeight: "2rem", letterSpacing: "0.055rem" }}>
                            We thought that there must be a better answer.  Using technology to do the heavy lifting wherever possible.
                        </p>
                        <p className="fs1rem fw600 mb1-5rem" style={{ lineHeight: "2rem", letterSpacing: "0.055rem" }}>
                            So we developed the concept of an automated, re-usable, self-diligence platform.
                        </p>
                        <p className="fs1rem fw400" style={{ lineHeight: "2rem", letterSpacing: "0.055rem" }}>
                            We have spent the last two years building Diligentsia for launch later in 2024.  As more users come on board we will build out more features of use to the circular community we are creating.
                        </p>
                    </section>

                    {/* Our thesis section*/}
                    <section className="silder-box">
                        <div className="c15 fw500 df jcc " style={{ fontSize: '50px', lineHeight: '55.2px' }}> Our Thesis</div>
                        {/* <AboutUsCrousel data={ABOUT_US_CONTENT} hasNoPadding={true} /> */}
                        <Slider {...settings}>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis1-1.png")} alt="about-us-image1" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis2-1.png")} alt="about-us-image2" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis3-1.png")} alt="about-us-image3" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis4-1.png")} alt="about-us-image4" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis1-2.png")} alt="about-us-image1" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis2-2.png")} alt="about-us-image2" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis3-2.png")} alt="about-us-image3" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis4-2.png")} alt="about-us-image4" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis1-3.png")} alt="about-us-image1" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis2-3.png")} alt="about-us-image2" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis3-3.png")} alt="about-us-image3" />
                                </div>
                            </div>
                            <div>
                                <div className="img-slider-box">
                                    <img src={require("../../images/landingpage/thesis4-3.png")} alt="about-us-image4" />
                                </div>
                            </div>
                        </Slider>
                        <div className="w100 df jcc fdc pr1rem pl1rem">
                            <div className='w100 df jcc fw500 tac fs1rem' style={{ bottom: '6.5rem', color: "#FF8787", lineHeight: "2rem" }}>What portfolio improvement would you have achieved if you had avoided your worst investments?</div>
                            <div className='w100 df jcc fw500 tac mb8rem fs1rem' style={{ color: "#FF8787" }}>Could better diligence improve your stats?</div>
                        </div>
                        <div className="bg1 aic" style={{ padding: "0rem 7.5rem 0", textAlign: "center" }}>
                            <p className="fw400 fs1-25rem c14 mb2rem" style={{ lineHeight: '2rem', letterSpacing: '0.055rem' }}>Even though their portfolio can come out ahead, <b>most</b> early-stage investors lose <b>most</b> of their investment on <b>most</b> of their investments. This is inefficient allocation of capital, and a waste of entrepreneurial blood, sweat and tears. Something we identify with!</p>
                            <p className="fw400 fs1-25rem c14 mb2rem" style={{ lineHeight: '2rem', letterSpacing: '0.055rem' }}>… and ultimately reduces the pool of investment available to younger, smaller companies.</p>
                            <p className="fw400 fs1-25rem c14 mb2rem" style={{ lineHeight: '2rem', letterSpacing: '0.055rem' }}>The founders of Diligentsia have been on both sides of this fence.  We think that whilst there are many elements of diligence that can’t be automated, there is a layer that can, and which can be used as part of a funnel – reducing failures and increasing efficiency and financial returns for everyone.</p>
                            <p className="fw600 fs1-25rem c14 mb2rem" style={{ lineHeight: '2rem', letterSpacing: '0.055rem' }}>We invite you to come help us prove our thesis.</p>

                        </div>
                    </section>

                    {/* action section*/}
                    <section className="action-sec dg" style={{ gridTemplateColumns: "auto 16rem", columnGap: "10rem" }}>
                        <h4 className="fs2rem fw500 mb2rem c15" style={{ lineHeight: "3.033rem", maxWidth: "42rem" }}>
                            We genuinely welcome your feedback – we want to hear from you!
                        </h4>
                        <div className="tac bg1 cp" style={{ borderRadius: "1.25rem" }} onClick={() => window.open('https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true', '_blank')}>
                            <img src={require('../../images/landingpage/schedule.png')} alt='img' style={{ margin: "3rem 1rem 1rem", maxWidth: "7rem" }} />
                            <div className="bg4 fw700 cp" style={{ borderRadius: "0 0 1.25rem 1.25rem", fontSize: "1.375rem", padding: "1rem" }}>
                                <div className="df c1 jcsb fs1rem"> Book a Call  <img src={require('../../images/landingpage/arrow-right.svg')} alt='arrow-right' /></div>
                                <p className="fw400 c1 tal" style={{ lineHeight: "2rem", fontSize: "0.8rem" }}>No recruitment agencies etc</p>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            </div>
        </AnimatedPage >
    )

}
export default AboutUs



