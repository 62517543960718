export const HOME = "/";

export const HOME_PAGE = "/homepage";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const ABOUT = "/about-us";
export const CONTACT = "/contact-us";
export const TEST_PAGE = "/test-page";

export const INVESTMENT_READY = "/investment-ready-companies";
export const GROWTH_READY = "/growth-ready-companies";
export const EXIT_READY = "/exit-ready-companies";

export const ACCOUNTANTS = "/accountants";
export const LAWYERS = "/lawyers";

export const ANGEL_NETWORKS = "/angel-networks";
export const ANGEL_INVESTORS = "/angel-investors";
export const VENTURE_CAPITAL = "/venture-capital-firms";
export const PRIVATE_EQUITY = "/private-equity-firms";

export const TERMS = "/terms";
export const POLICIES = "/policies";

export const BILLING = "/billing/:id";
export const EXPANSIONPACK = "/expansionpack/:id";
export const DIRECTORSPACK = "/directorspack/:id";
export const INDUSTRYPACK = "/industrypack/:id";
export const ESGPACK = "/esgpack/:id";
export const ExpansionReferencing = "/expansionreferencing";
export const COMPANY = "/company/:id"; // company ID
export const DASHBOARD = "/dashboard";
export const ADVISOR = "/advisor-dashboard";
export const INVESTOR = "/investor-dashboard";

// export const GROUP = "/group/:id"; // group ID
export const GROUP = "/group"; // group ID
export const PROFILE = "/profile";
export const ADVISORSETTINGS = "/advisor-setting";
export const INVESTORSETTINGS = "/investor-setting";
export const REPORT = "/report/:type/:id";
export const SUPPORT = "/support";
export const ADMIN = "/admin";

//New Landing page Routes
export const LANDING_PAGE = '/landing-page';
export const LANDING_PAGE_COMPANIES = LANDING_PAGE;
export const LANDING_PAGE_ACCOUNTANT = `${LANDING_PAGE}-accountants`;
export const LANDING_PAGE_LAWYER = `${LANDING_PAGE}-lawyers`;
export const LANDING_PAGE_VCS = `${LANDING_PAGE}-vcs`;
export const LANDING_PAGE_PES = `${LANDING_PAGE}-pes`;
export const LANDING_PAGE_ANGEL_NETWORKS = `${LANDING_PAGE}-angel-networks`;
export const LANDING_PAGE_ANGELS = `${LANDING_PAGE}-angels`;

// New terms and condition routes
export const TERMS_CONDITION = '/terms-conditions';
export const TERMS_CONDITION_COMPANY = TERMS_CONDITION;
export const TERMS_CONDITION_ACCEPTABLE_USE = `${TERMS_CONDITION}-acceptable-use`;
export const TERMS_CONDITION_INDIVIDUALS_TERMS = `${TERMS_CONDITION}-individuals-terms`;

//New about-us
export const ABOUT_US = '/about-us';
// New Pricing plan 
export const PRICING_PLAN = '/pricing-plans';
//Contact us
export const CONTACT_US = "/contact-us"
//Partner Page
export const PARTNER_PROGRAM = "/partner-program"

// Blogs routes
export const BLOGS = '/blogs';
export const BLOG_DETAILS = '/blog/:slug';

// New Policies routes
export const POLICY = '/policy';
export const POLICY_COOKIE = POLICY;
export const POLICY_DMCA = `${POLICY}-dmca`;
export const POLICY_PRIVACY = `${POLICY}-privacy`;
export const POLICY_DATA_ETHICS = `${POLICY}-data-ethics`;
export const POLICY_ALL_USE = `${POLICY}-all-use`;

// Checklist
export const CREATECHECKLIST = '/createChecklist';


export const NO_FOOTER = [HOME, HOME_PAGE, LOGIN, REGISTER, CREATECHECKLIST];
export const LOGGED_IN_ROUTES = [DASHBOARD, ADVISOR, PROFILE, REPORT, BILLING, EXPANSIONPACK, DIRECTORSPACK, INDUSTRYPACK, ESGPACK, SUPPORT, COMPANY, GROUP, ADMIN, INVESTOR, ADVISORSETTINGS, INVESTORSETTINGS];
