

export default function SectionCreation({ isManualAI, setIsManualAI, setStepTab }) {

    const qustionsSections = [{
        sectionName: 'Section 1',
        inputText: 'Add your section heading here',
    },
    {
        sectionName: 'Section 2',
        inputText: 'Add your section heading here',
    },
    {
        sectionName: 'Section 3',
        inputText: 'Add your section heading here',
    },
    {
        sectionName: 'Section 4',
        inputText: 'Add your section heading here',
    },
    {
        sectionName: 'Section 5',
        inputText: 'Add your section heading here',
    },
    ];

    return (
        <>
            <div className='checklist-form-area df fww w100'>
                <div className='checklist-form w100 mb5rem'>
                    <>
                        {qustionsSections.map((data) => {
                            return (
                                <div className='df aic w100 mb0-5rem pr'>
                                    <span className='pa ml1rem c15'>
                                        {data.sectionName}
                                    </span>
                                    <input
                                        name='searchCompany'
                                        id='searchCompany'
                                        type='text'
                                        placeholder={data.inputText}
                                        className='pl6rem bss bw2px bo5 w100 br25px p1rem'
                                    />
                                </div>
                            )
                        })}
                    </>

                    <div className='df aic w100 mb0-5rem pr cp bss bw2px bo5 w100 br25px p1rem bsbb'>
                        <span className='pa ml0-5rem c15 w2rem h2rem br100 bg3 cp tac lh2' style={{ lineHeight: '40px' }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3098 11.5825H11.5805V18.3118C11.5805 19.1859 10.8714 19.895 9.9973 19.895C9.1232 19.895 8.41406 19.1859 8.41406 18.3118V11.5825H1.6848C0.810699 11.5825 0.101562 10.8734 0.101562 9.99925C0.101562 9.12515 0.810699 8.41602 1.6848 8.41602H8.41406V1.68675C8.41406 0.812653 9.1232 0.103516 9.9973 0.103516C10.8714 0.103516 11.5805 0.812653 11.5805 1.68675V8.41602H18.3098C19.1839 8.41602 19.893 9.12515 19.893 9.99925C19.893 10.8734 19.1839 11.5825 18.3098 11.5825Z" fill="#FAFAFA" />
                            </svg>
                        </span>
                        <span className='pr c15 ml3rem'>
                            Add Section
                        </span>
                    </div>

                    {/* AI Logo */}
                    {isManualAI &&
                        <div className="ai-logo pa" style={{ transform: 'translate(-50%, -50%)', left: '50%', top: '50%' }}>
                            <svg width="533" height="444" viewBox="0 0 533 444" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.08">
                                    <path d="M391.899 421.101L236.964 14.9119C235.29 10.5257 232.308 6.74937 228.416 4.0855C224.524 1.42164 219.906 -0.00336658 215.178 5.97253e-06H177.592C172.858 -0.00310047 168.236 1.42396 164.34 4.09129C160.445 6.75862 157.461 10.5397 155.786 14.9309L1.09927 421.101C0.124901 423.661 -0.208861 426.418 0.126531 429.134C0.461923 431.851 1.45641 434.446 3.02489 436.697C4.59337 438.948 6.68911 440.789 9.13215 442.061C11.5752 443.333 14.2926 443.998 17.0518 444H56.6848C61.5226 444.001 66.2403 442.507 70.1826 439.726C74.1248 436.944 77.0956 433.014 78.6817 428.481L104.083 356.122C104.083 355.951 104.217 355.781 104.294 355.591L141.861 248.646C141.861 248.323 142.052 248.001 142.167 247.679L155.556 209.735L174.282 156.613C181.551 135.972 210.989 135.972 218.276 156.613L236.983 209.735L309.306 415.238L314.011 428.519C315.608 433.037 318.58 436.951 322.518 439.721C326.455 442.491 331.162 443.979 335.989 443.981H375.947C378.705 443.983 381.423 443.32 383.866 442.05C386.31 440.781 388.406 438.942 389.975 436.692C391.543 434.441 392.538 431.847 392.874 429.131C393.209 426.416 392.875 423.66 391.899 421.101Z" fill="url(#paint0_linear_4294_615)" />
                                    <path d="M517.315 444H471.685C467.525 444 463.536 442.351 460.594 439.416C457.653 436.48 456 432.499 456 428.348V15.6329C456.005 11.485 457.66 7.50882 460.601 4.57764C463.542 1.64645 467.529 8.46447e-06 471.685 1.15116e-05H517.315C519.374 -0.00248186 521.413 0.40009 523.316 1.1846C525.219 1.96911 526.948 3.12018 528.405 4.57208C529.862 6.02397 531.017 7.7481 531.806 9.646C532.594 11.5439 533 13.5783 533 15.6329V428.348C533 430.404 532.594 432.439 531.806 434.338C531.018 436.237 529.863 437.962 528.406 439.416C526.95 440.869 525.22 442.022 523.317 442.808C521.414 443.595 519.375 444 517.315 444Z" fill="url(#paint1_linear_4294_615)" />
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_4294_615" x1="-1118.55" y1="-1003.86" x2="1638.13" y2="1775.47" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#00EEEF" />
                                        <stop offset="1" stop-color="#AF00D4" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_4294_615" x1="-921.502" y1="-1191" x2="1835.26" y2="1571.66" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#00EEEF" />
                                        <stop offset="1" stop-color="#AF00D4" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    }
                </div>
            </div>
            <div className='bottom-btn-area df aic w100 jcsb mt5rem bsbb'>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>4/6 Logo Upload & Permissions</p>
                <button onClick={() => setStepTab('Logo Upload & Permissions')} className='shadow c1 bg4 bg3-hover c1 cp fw400 fs1rem pr br38px w200px h3rem df aic jcc'>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>
        </>
    )
}