import React from "react";
import * as styles from "../../../pages/blogs/BlogPage.module.css";
import { Link } from "react-router-dom";
import AuthorInfo from "../AuthorInfo";

function BlogCard({ post, featured = false }) {
  return (
    <>
      <article
        className={`${styles.blogCard} ${featured ? styles.featured : "w100"}`}
      >
        {featured ? (
          <div className="pr">
            <img src={post.image} alt="" className="w100" />
            <div className={styles.FeaturedBadge}>Featured</div>
          </div>
        ) : (
          <img src={post.image} alt={post.image} className="w100" />
        )}

        <div className={styles.blogContent}>
          {
            post.category.map((cat, index) => <><span key={`${cat}-${index}`} className={styles.blogCategory}>{cat} {(index + 1) === post.category.length ? "" : " | "}</span> </>)
          }

          <Link to={`/blog/${post.slug}`}>
            <h2 className={styles.blogTitle}>{post.title}</h2>
          </Link>
          <p className={styles.blogExcerpt}>{post.excerpt}</p>
          <AuthorInfo post={post} />
        </div>
      </article>
    </>
  );
}

export default BlogCard;
