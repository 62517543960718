import React from 'react';
import moment from "moment";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import BusinessCards from "../../Advisor/BusinessCards.js"
import { hasValidAdvisor } from '../../Advisor/BusinessCards.js';
import { toggleModal } from "../../../../reducers/modalReducer";
import { showMessage, getRequest, postRequest } from ".././../../../config.js";

export const UserIcon = ({ color, onClick = () => { } }) => {
	return (
		<svg width="48" height="36" viewBox="0 0 77 71" fill="none" xmlns="http://www.w3.org/2000/svg" className="cp member-advisor-modal-icon" onClick={onClick}>
			<path d="M55.8372 3H14.1628C8.00093 3 3 7.992 3 14.1429V43.8571C3 50.008 8.00093 55 14.1628 55H55.8372C61.9991 55 67 50.008 67 43.8571V14.1429C67 7.992 61.9991 3 55.8372 3ZM62.5349 43.8571C62.5349 47.5417 59.5284 50.5429 55.8372 50.5429H14.1628C10.4716 50.5429 7.46512 47.5417 7.46512 43.8571V14.1429C7.46512 10.4583 10.4716 7.45714 14.1628 7.45714H55.8372C59.5284 7.45714 62.5349 10.4583 62.5349 14.1429V43.8571ZM32.0828 31.5257C33.4223 30.0697 34.2558 28.1383 34.2558 26.0286C34.2558 21.512 30.5944 17.8571 26.0698 17.8571C21.5451 17.8571 17.8837 21.512 17.8837 26.0286C17.8837 28.1383 18.7172 30.0697 20.0567 31.5257C18.0028 32.8331 16.3953 34.7943 15.5619 37.2011C15.1749 38.36 15.7702 39.6377 16.9609 40.024C18.1219 40.4103 19.4019 39.7863 19.7888 38.6274C20.7116 35.9829 23.2121 34.2 26.0698 34.2C28.9274 34.2 31.4577 35.9829 32.3507 38.6274C32.6781 39.5486 33.5414 40.1429 34.4642 40.1429C34.7023 40.1429 34.9405 40.1131 35.1786 40.024C36.3395 39.6377 36.9646 38.36 36.5777 37.2011C35.7442 34.7943 34.1367 32.8331 32.0828 31.5257ZM22.3488 26.0286C22.3488 23.9783 24.0158 22.3143 26.0698 22.3143C28.1237 22.3143 29.7907 23.9783 29.7907 26.0286C29.7907 28.0789 28.1237 29.7429 26.0698 29.7429C24.0158 29.7429 22.3488 28.0789 22.3488 26.0286ZM55.093 23.0571C55.093 24.2754 54.0809 25.2857 52.8605 25.2857H43.9302C42.7098 25.2857 41.6977 24.2754 41.6977 23.0571C41.6977 21.8389 42.7098 20.8286 43.9302 20.8286H52.8605C54.0809 20.8286 55.093 21.8389 55.093 23.0571ZM55.093 34.9429C55.093 36.1611 54.0809 37.1714 52.8605 37.1714H43.9302C42.7098 37.1714 41.6977 36.1611 41.6977 34.9429C41.6977 33.7246 42.7098 32.7143 43.9302 32.7143H52.8605C54.0809 32.7143 55.093 33.7246 55.093 34.9429Z" fill="#151313" />
			<circle cx="58" cy="54" r="14" fill="white" />
			<path d="M58.7846 38.0391C50.4167 38.0391 43.5703 44.8855 43.5703 53.2533C43.5703 61.6212 50.4167 68.4676 58.7846 68.4676C67.1525 68.4676 73.9989 61.6212 73.9989 53.2533C73.9989 44.8855 67.1525 38.0391 58.7846 38.0391ZM65.3267 49.6019L58.3282 58.7305C58.0239 59.0348 57.5675 59.3391 57.111 59.3391C56.6546 59.3391 56.1982 59.1869 55.8939 58.7305L52.2425 54.0141C51.786 53.4055 51.786 52.3405 52.5467 51.8841C53.3075 51.4276 54.2203 51.4276 54.6767 52.1883L57.111 55.3833L62.8925 47.7762C63.3489 47.1676 64.4139 47.0155 65.0225 47.4719C65.7832 47.9283 65.7832 48.8412 65.3267 49.6019Z" fill={color ? color : "#505050"} />
		</svg>
	)
}

export default function Team({ profileClass, company, teamMembers, setEditUser, setUserAction, resendInviteMember, handleDeleteMember }) {

	const dispatch = useDispatch();

	let user = useSelector((state) => state.user);
	let modal = useSelector((state) => state.modal);

	let [selectedData, setSelectedData] = useState({});


	const handleMemberEdit = async (member) => {
		let permissions = company['memberPermissions'][member['_id']];
		await setEditUser({ ...member, permissions });
		setUserAction('Edit');
		dispatch(toggleModal({ open: 'editUser' }));
	};

	return (
		<>
			<div className="df jcc fdc pr mb5rem">
				<div className="df jcsb py1rem pl6rem pr7rem pr">
					<div className="df bg1 br50px mr0-5rem bw2px bss pr1rem p0-5rem bsbb aic mr1rem cp add-btn-dashboard" onClick={() => { setEditUser({}); dispatch(toggleModal({ open: 'editUser' })); setUserAction('Search'); }}>
						<span className='bg1 bsbb mr0-5rem br100 w40px h40px tac df jcc aic'>
							<svg className='h30px w30px' width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clip-path="url(#clip0_2656_879)">
									<path d="M19 0C8.52298 0 0 8.52298 0 19C0 29.477 8.52298 38 19 38C29.477 38 38 29.477 38 19C38 8.52298 29.477 0 19 0Z" fill="#4A67FF" />
									<path d="M27.3098 20.5825H20.5805V27.3118C20.5805 28.1859 19.8714 28.895 18.9973 28.895C18.1232 28.895 17.4141 28.1859 17.4141 27.3118V20.5825H10.6848C9.8107 20.5825 9.10156 19.8734 9.10156 18.9993C9.10156 18.1252 9.8107 17.416 10.6848 17.416H17.4141V10.6868C17.4141 9.81265 18.1232 9.10352 18.9973 9.10352C19.8714 9.10352 20.5805 9.81265 20.5805 10.6868V17.416H27.3098C28.1839 17.416 28.893 18.1252 28.893 18.9993C28.893 19.8734 28.1839 20.5825 27.3098 20.5825Z" fill="#FAFAFA" />
								</g>
								<defs>
									<clipPath id="clip0_2656_879"><rect width="38" height="38" fill="white" /></clipPath>
								</defs>
							</svg>
						</span>
						<span className='c15'>Add Team</span>
					</div>
				</div>
				{teamMembers && teamMembers.length > 0
					? <table id="table" className="table-advisor">
						<thead className="ps t0">
							<tr>
								<th class="fs1-25rem c15">Name</th>
								<th class="fs1-25rem c15">Email</th>
								<th class="fs1-25rem c15">Title</th>
								<th class="fs1-25rem c15">Business Card</th>
								<th class="fs1-25rem c15">Added By</th>
								<th class="fs1-25rem c15">Status</th>
								<th class="fs1-25rem c15">Updated Date</th>
								<th class="fs1-25rem c15">Action</th>
							</tr>
						</thead>
						<tbody>
							{company?.['creatorId'] &&
								<tr>
									<td className="df jcs aic">
										{company['creatorId']?.['profile']
											? <img src={company['creatorId']?.['profile']} className={`w3rem h3rem df jcc aic br100 cp mr1rem bss bow1px bo7 ${profileClass}`} />
											: <span class={`w3rem h3rem df jcc aic br100 cp mr1rem bss bow1px bo7 ${profileClass}`}>{company?.['creatorId']?.['firstName'][0]?.toUpperCase() + company?.['creatorId']?.['lastName']?.[0]?.toUpperCase()}</span>
										}
										<span className={`fs1rem ttc tas c15 lh1`}>{company['creatorId']?.['firstName'] + company['creatorId']?.['lastName']} <br /><span className='c4 fs0-75rem'>Admin</span></span>
									</td>
									<td className="c6 fs1rem">
										<a className="c3" href={`mailto:${company?.['creatorId']?.['email']}`}>{company['creatorId']?.['email']}</a>
									</td>
									<td className="c15 fs1rem ttc">{company?.['creatorId']?.['title']}</td>
									<td><UserIcon onClick={() => setSelectedData({ ...company['creatorId'], hasEditButton: false })} color={hasValidAdvisor(company['creatorId']) ? '#008000' : '#F44336'} /></td>
									<td className="c15 fs1rem ttc">Owner</td>
									<td className={`${company?.['creatorId']?.["emailVerified"] ? "c7 " : "c13 "} fs1rem cp`}>
										{company?.['creatorId']?.["emailVerified"] ? ("Active") : (<span data-tooltip="Resend Invite">Inactive</span>)}
									</td>
									<td className="c15 fs1rem">
										{company?.['creatorId']?.["updatedAt"] ? moment(company?.['creatorId']?.["updatedAt"]).format("ll") : "-"}
									</td>
									<td className="c15 fs1rem">-</td>
								</tr>
							}
							{teamMembers.map(member => {
								return (
									<tr>
										<td className="df jcs aic">
											{member?.['profile']
												? <img src={member?.['profile']} className={`w3rem h3rem df jcc aic br100 cp mr1rem bss bow1px bo7 ${profileClass}`} />
												: <span class={`w3rem h3rem df jcc aic br100 cp mr1rem bss bow1px bo7 ${profileClass}`}>{member?.['firstName'][0]?.toUpperCase() + member?.['lastName']?.[0]?.toUpperCase()}</span>
											}
											<span className={`fs1rem ttc tas c15 lh1`}>{member?.['firstName'] + member?.['lastName']}</span>
										</td>
										<td className="c6 fs1rem">
											<a className="c3" href={`mailto:${member?.['email']}`}>{member?.['email']}</a>
										</td>
										<td className="c15 fs1rem ttc">{member?.['designation'] ? member?.['designation'] : '-'}</td>
										<td><UserIcon onClick={() => setSelectedData({ ...member, hasEditButton: false })} color={hasValidAdvisor(member) ? '#008000' : '#F44336'} /></td>
										<td className="c15 fs1rem ttc">Owner</td>
										<td className={`${member?.["emailVerified"] ? "c7 " : "c13 "} fs1rem cp`}>
											{member?.["emailVerified"] ? ("Active") : (<span data-tooltip="Resend Invite">Inactive</span>)}
										</td>
										<td className="c15 fs1rem">
											{member?.["updatedAt"] ? moment(member?.["updatedAt"]).format("ll") : "-"}
										</td>
										<td className="c15 fs1rem">
											<div className="row">
												{!member?.["emailVerified"] && (
													<button className="cp" onClick={() => resendInviteMember(member["_id"])} title="Resend Invite">
														<i data-tooltip="Resend Invite" class="fa fa-repeat c7" style={{ fontSize: "20px" }} />
													</button>
												)}
												<button onClick={() => handleMemberEdit(member)} className='bg0'>
													<i data-tooltip="Edit Advisor" class="c8 cp" style={{ fontSize: "20px" }}>
														<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M25.666 12.8327C25.3566 12.8327 25.0599 12.9556 24.8411 13.1744C24.6223 13.3932 24.4994 13.6899 24.4994 13.9993V20.9993C24.4993 21.9276 24.1306 22.8178 23.4742 23.4742C22.8178 24.1306 21.9276 24.4993 20.9993 24.4994H6.99935C6.07109 24.4993 5.18085 24.1306 4.52448 23.4742C3.8681 22.8178 3.49935 21.9276 3.49935 20.9993V6.99935C3.49935 6.07109 3.8681 5.18085 4.52448 4.52448C5.18085 3.8681 6.07109 3.49935 6.99935 3.49935H13.9993C14.3088 3.49935 14.6055 3.37643 14.8243 3.15764C15.0431 2.93885 15.166 2.6421 15.166 2.33268C15.166 2.02326 15.0431 1.72652 14.8243 1.50772C14.6055 1.28893 14.3088 1.16602 13.9993 1.16602H6.99935C6.2333 1.16602 5.47476 1.3169 4.76703 1.61005C4.0593 1.9032 3.41623 2.33288 2.87456 2.87456C1.7806 3.96852 1.16602 5.45225 1.16602 6.99935V20.9993C1.16602 21.7654 1.3169 22.5239 1.61005 23.2317C1.9032 23.9394 2.33288 24.5825 2.87456 25.1241C3.96852 26.2181 5.45225 26.8327 6.99935 26.8327H20.9993C21.7654 26.8327 22.5239 26.6818 23.2317 26.3886C23.9394 26.0955 24.5825 25.6658 25.1241 25.1241C25.6658 24.5825 26.0955 23.9394 26.3886 23.2317C26.6818 22.5239 26.8327 21.7654 26.8327 20.9993V13.9993C26.8327 13.6899 26.7098 13.3932 26.491 13.1744C26.2722 12.9556 25.9754 12.8327 25.666 12.8327Z" fill="#4A67FF" />
															<path d="M9.19269 12.2035C8.8662 12.5296 8.60741 12.917 8.43122 13.3435C8.25502 13.7699 8.1649 14.2271 8.16603 14.6885V16.3335C8.16603 17.2617 8.53478 18.152 9.19115 18.8084C9.84753 19.4647 10.7378 19.8335 11.666 19.8335H13.311C13.7725 19.8346 14.2296 19.7445 14.656 19.5683C15.0825 19.3921 15.4699 19.1333 15.796 18.8068L25.806 8.79682C26.1323 8.47166 26.3912 8.08529 26.5679 7.65985C26.7445 7.23442 26.8355 6.7783 26.8355 6.31766C26.8355 5.85701 26.7445 5.40089 26.5679 4.97546C26.3912 4.55003 26.1323 4.16365 25.806 3.83849L24.161 2.19349C23.8359 1.8672 23.4495 1.6083 23.0241 1.43165C22.5986 1.255 22.1425 1.16406 21.6819 1.16406C21.2212 1.16406 20.7651 1.255 20.3397 1.43165C19.9142 1.6083 19.5279 1.8672 19.2027 2.19349L9.19269 12.2035ZM22.5044 3.83849L24.161 5.49516C24.3783 5.71375 24.5003 6.00944 24.5003 6.31766C24.5003 6.62587 24.3783 6.92157 24.161 7.14016L23.1577 8.16682L19.8327 4.84182L20.836 3.83849C20.9447 3.72737 21.0744 3.63908 21.2177 3.5788C21.3609 3.51852 21.5148 3.48747 21.6702 3.48747C21.8256 3.48747 21.9795 3.51852 22.1227 3.5788C22.2659 3.63908 22.3957 3.72737 22.5044 3.83849ZM10.4994 14.6885C10.4985 14.5349 10.5279 14.3827 10.586 14.2406C10.644 14.0985 10.7296 13.9692 10.8377 13.8602L18.1994 6.49849L21.501 9.80015L14.1394 17.1618C14.0303 17.27 13.9011 17.3555 13.7589 17.4136C13.6168 17.4716 13.4646 17.501 13.311 17.5002H11.666C11.3566 17.5002 11.0599 17.3772 10.8411 17.1584C10.6223 16.9397 10.4994 16.6429 10.4994 16.3335V14.6885Z" fill="#4A67FF" />
														</svg>
													</i>
												</button>
												<button onClick={() => handleDeleteMember(member['_id'])}>
													<i data-tooltip="Delete Advisor" class="c9 cp" style={{ fontSize: "20px" }}>
														<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M22.1641 8.16602C21.8546 8.16602 21.5579 8.28893 21.3391 8.50772C21.1203 8.72652 20.9974 9.02326 20.9974 9.33268V22.3888C20.9639 22.9788 20.6987 23.5317 20.2595 23.927C19.8202 24.3223 19.2426 24.528 18.6524 24.4993H9.3424C8.75218 24.528 8.17454 24.3223 7.73532 23.927C7.2961 23.5317 7.03086 22.9788 6.9974 22.3888V9.33268C6.9974 9.02326 6.87448 8.72652 6.65569 8.50772C6.43689 8.28893 6.14015 8.16602 5.83073 8.16602C5.52131 8.16602 5.22456 8.28893 5.00577 8.50772C4.78698 8.72652 4.66406 9.02326 4.66406 9.33268V22.3888C4.69736 23.5978 5.20841 24.7442 6.08527 25.5772C6.96214 26.4101 8.13335 26.8615 9.3424 26.8327H18.6524C19.8614 26.8615 21.0327 26.4101 21.9095 25.5772C22.7864 24.7442 23.2974 23.5978 23.3307 22.3888V9.33268C23.3307 9.02326 23.2078 8.72652 22.989 8.50772C22.7702 8.28893 22.4735 8.16602 22.1641 8.16602Z" fill="#F14D4D" />
															<path d="M23.3333 4.66602H18.6667V2.33268C18.6667 2.02326 18.5437 1.72652 18.325 1.50772C18.1062 1.28893 17.8094 1.16602 17.5 1.16602H10.5C10.1906 1.16602 9.89383 1.28893 9.67504 1.50772C9.45625 1.72652 9.33333 2.02326 9.33333 2.33268V4.66602H4.66667C4.35725 4.66602 4.0605 4.78893 3.84171 5.00772C3.62292 5.22652 3.5 5.52326 3.5 5.83268C3.5 6.1421 3.62292 6.43885 3.84171 6.65764C4.0605 6.87643 4.35725 6.99935 4.66667 6.99935H23.3333C23.6428 6.99935 23.9395 6.87643 24.1583 6.65764C24.3771 6.43885 24.5 6.1421 24.5 5.83268C24.5 5.52326 24.3771 5.22652 24.1583 5.00772C23.9395 4.78893 23.6428 4.66602 23.3333 4.66602ZM11.6667 4.66602V3.49935H16.3333V4.66602H11.6667Z" fill="#F14D4D" />
															<path d="M12.8333 19.8333V11.6667C12.8333 11.3572 12.7104 11.0605 12.4916 10.8417C12.2728 10.6229 11.9761 10.5 11.6667 10.5C11.3572 10.5 11.0605 10.6229 10.8417 10.8417C10.6229 11.0605 10.5 11.3572 10.5 11.6667V19.8333C10.5 20.1428 10.6229 20.4395 10.8417 20.6583C11.0605 20.8771 11.3572 21 11.6667 21C11.9761 21 12.2728 20.8771 12.4916 20.6583C12.7104 20.4395 12.8333 20.1428 12.8333 19.8333Z" fill="#F14D4D" />
															<path d="M17.4974 19.8333V11.6667C17.4974 11.3572 17.3745 11.0605 17.1557 10.8417C16.9369 10.6229 16.6401 10.5 16.3307 10.5C16.0213 10.5 15.7246 10.6229 15.5058 10.8417C15.287 11.0605 15.1641 11.3572 15.1641 11.6667V19.8333C15.1641 20.1428 15.287 20.4395 15.5058 20.6583C15.7246 20.8771 16.0213 21 16.3307 21C16.6401 21 16.9369 20.8771 17.1557 20.6583C17.3745 20.4395 17.4974 20.1428 17.4974 19.8333Z" fill="#F14D4D" />
														</svg>
													</i>
												</button>
											</div>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					: <div class="df jcc text-gradient fs1-75rem fw500 lh2 mt2rem">No Members Added!</div>
				}
			</div>

			{/* Business Card Modal */}
			<Modal open={Object.keys(selectedData).length} onClose={() => setSelectedData({})} className="td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<BusinessCards
					user={user}
					company={company}
					selectedData={selectedData}
					profileClass={profileClass}
					// onEditClick={onEditClick}
					setSelectedData={setSelectedData} />
			</Modal>
		</>
	);
}
