import React from 'react'
import CustomTab from '../../components/termsAndConditions/CustomTab'
import SmoothScrollingButton from '../../components/SmoothScrollingButton'
import AnimatedPage from '../../components/AnimatedPage'


const CompanyTerms = () => {

    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page terms-and-conditions-page *'>
                <CustomTab type="terms-and-conditions" className="terms-and-conditions-tab ttc" activeIdentifier="company-terms" title={'Terms & Conditions'} width="41%" />
                <div className='policy-box'>
                    <h1 className="pb2rem fs1-5rem fw500 tal c15" style={{ fLineHeight: "39.81px" }}>FOR COMPANIES</h1>
                    <p className="fw400 tal c14 fs1rem" style={{ lineHeight: "32px" }}>
                        This agreement only applies to customers that purchase a Diligentsia service for corporate organisations and is not applicable for Diligentsia services purchased by consumers or individuals.
                        This Diligentsia Services Agreement (the "DSA") is between the applicable Diligentsia entity set forth in Appendix 1 ("Diligentsia") and the organization agreeing to these terms ("Customer").
                        Diligentsia and Customer are each referred to as a “Party” and collectively as the “Parties.” This DSA governs access to and use of the Services and any Beta Services. This DSA, any Service-Specific Terms, each applicable Order Form, the Data Processing Agreement and, if applicable, the HIPAA Business Associate Agreement are collectively referred to as the "Agreement." By clicking "I agree," signing your Order Form for the Services, or using the Services, you agree to the Agreement as a Customer.
                        If you are agreeing to this Agreement for use of the Services by an organization, you are agreeing on behalf of that organization. You must have the authority to bind that organization to these terms, otherwise you must not sign up for the Services.
                    </p>
                    <ol type="1" className='fw500 tal c14 fs1-5rem'>
                        <li className="pt1-5rem pb1-5rem">Services</li>
                        <ol type="a" className="fw400 fs1rem c14 tal" style={{ lineHeight: "32px" }}>
                            <li>Provision. The Agreement governs access to, and use of the Services, and any associated Software. Customer may access and use the Services in accordance with the Agreement</li>
                            <li>Service-Specific Terms. Certain Services, or portions thereof, may be subject to additional terms, including third party terms and conditions, that are specific to the particular Services and are set forth in the Service-Specific Terms. By accessing or using Services covered by any Service-Specific Terms, you agree to the applicable Service-Specific Terms. If there is a conflict between these Terms and the Service-Specific Terms, the Service-Specific Terms will control with respect to the applicable Services or portions thereof.</li>
                            <li>Modifications. Diligentsia may update the Services from time to time. If Diligentsia changes the Services in a manner that materially reduces their functionality, Diligentsia will notify Customer at the email address associated with the account, and Customer may provide notice within thirty days of the change to terminate the Agreement. This termination right will not apply to updates made to features provided on a beta or evaluation basis. Without limiting the foregoing, from time to time, Diligentsia may add additional features to enhance the user experience of the Services at no additional charge. However, these free features may be withdrawn without further notices</li>
                            <li>Software.
                                <ol type="i">
                                    <li>Generally. Some of the Services may allow Customer and End Users to download Software that may update automatically. Diligentsia hereby grants to Customer during the Term a limited non-exclusive license to use the Software solely in connection with the Services and in accordance with the Agreement. This license is non-transferable (subject to Section 14.8), irrevocable (except as set forth in Section 9), non-sublicensable, and will be fully paid up upon Customer’s payment of the Fees.</li>
                                    <li>Open Source. If any component of the Software is offered under an open source license, Diligentsia will make the license available to Customer and to the extent the provisions of that license grant Customer additional rights, those provisions will expressly override some terms of the Agreement solely with respect to that component of the Software.</li>
                                </ol>
                            </li>
                            <li>Add-Ons. Customer may purchase Add-Ons for use in conjunction with certain Services. Add-Ons may not be purchased on a standalone basis and must be purchased in connection with a new or existing subscription to the applicable Services for which they are offered. Add-Ons may be subject to additional Service-Specific Terms. Without limiting the foregoing, Add-Ons that are identified as being offered by third parties are not part of the Services and are subject to Section 3.9 of the Agreement.</li>
                        </ol>
                        <li className="pt2rem pb2rem">Subscriptions</li>
                        <ol type="a" className="fw400 fs1rem c14 tal" style={{ lineHeight: "32px" }}>
                            <li>Services Term. Unless otherwise set forth on the Order Form, the Services are sold on a subscription basis. Diligentsia will deliver the Services to Customer for the Services Term. Unless the parties agree otherwise in writing, any increases in quantities of Services purchased during any Services Term will have a prorated term ending on the last day of the pre-existing Services Term.</li>
                            <li>Automatic Renewals. Unless otherwise specified on the Order Form, following the Initial Services Term or a Renewal Term, Customer’s subscription to the Services will automatically renew for a Renewal Term at the then-current quantity or subscription tier, unless either Party gives the other written notice of termination (including any partial termination to reduce quantities) at least thirty days prior to the expiration of the then-current Services term. If Customer has purchased the Services online via a self-serve mechanism and provided a payment method to Diligentsia for recurring charges, Customer may elect to terminate the Agreement via the Admin Console prior to the day a Renewal Term begins.</li>
                            <li>Additional Subscriptions. The Services may be configured to allow Administrators or End Users to purchase additional subscriptions or quantities of Services or Add-Ons. Customer is responsible for understanding the settings and controls of the Services for purchasing additional Services. Diligentsia will charge Customer the applicable pro-rated amount for additional Services or Add-Ons for the remainder of the then-current Services Term based on Customer’s then-current price unless otherwise set forth on the Order Form.</li>
                            <li>Usage-based Subscriptions. If Customer elects to purchase any Services based on usage, Customer acknowledges that Diligentsia will charge Customer the Fees for the Services as set forth in the Order Form or Service-Specific Terms based on the usage calculated by Diligentsia.</li>
                        </ol>
                        <li className="pt2rem pb2rem">Customer Obligations</li>
                        <ol type="a" className="fw400 fs1rem c14 tal" style={{ lineHeight: "32px" }}>
                            <li>Registration. Customer and its End Users may need to register for an End User Account to place orders or to access or use the Services. Account information must be accurate, current, and complete, and Customer agrees to keep this information up-to-date.</li>
                            <li>Customer Administration of the Services. Customer may specify End Users as Administrators, including through multiple tiers of Administrators. Administrators may be able to: (a) access, disclose, restrict access to, or remove Customer Data; (b) purchase additional licenses, capacity, or other applicable quantity for the Services; and (c) provision, monitor, restrict, or terminate access to the Services by End Users. Customer is responsible for maintaining the confidentiality of passwords and Admin Accounts and managing access to Admin Accounts. Diligentsia’s responsibilities do not extend to the internal management or administration of the Services for Customer.</li>
                            <li>Third Party Administration. Customer acknowledges that, if Customer purchases the Services through a reseller and designates any of the reseller's personnel as Administrators of Customer's Services account, the reseller may be able to control account information, including Customer Data, and access Customer's Services account as described above.</li>
                            <li>End Users.
                                <ol type="i">
                                    <li>Provisioning. For services sold based on End User Licenses, Customer may provision End User Accounts up to the number of End User Licenses purchased through one or more Order Forms. Each End User Account requires a paid End User License, and End User Accounts may not be shared by multiple individuals. End User Accounts may only be provisioned to, registered for, and used by a single End User. End User Accounts that are set as suspended by Customer require a paid End User License. If Customer deletes an End User Account, the End User License may be reallocated to provision a new End User Account.</li>
                                    <li>Responsibility. Customer is responsible for use of the Services by its End Users. Customer will obtain and maintain from End Users any consents necessary to allow Administrators to engage in the activities described in the Agreement and to allow Diligentsia to deliver the Services.</li>
                                </ol>
                            </li>
                            <li>Unauthorized Use or Access. Customer will prevent unauthorized use of the Services by its End Users and terminate any unauthorized use of or access to the Services. The Services are not intended for End Users under the age of 18. If the law where the End User resides requires that the End User must be older in order for Diligentsia to lawfully provide the Services to the End User without parental consent, the End User must be that older age. Customer will ensure that it does not allow any person under 18 to use the Services. Customer will promptly notify Diligentsia of any unauthorized use of or access to the Services.</li>
                            <li>Restrictions. Customer will not: (a) sell, resell, or lease the Services, Software, or End User Licenses; (b) use the Services or Software for activities where use or failure of the Services or Software could lead to physical damage, death, or personal injury; (c) reverse engineer the Services or Software, or attempt or assist anyone else to do so, unless this restriction is prohibited by law; (d) use the Services or Software, including the export or re-export of Customer Data, in violation of Export Control Laws; (e) violate or circumvent any Service Limits of the Services or otherwise configure the Services to avoid Service Limits; (f) access the Services for the purpose of building a competitive product or service or copying its features or user interface; (g) use the Services for evaluation, benchmarking, or other comparative analysis intended for publication without Diligentsia’s prior written consent; (h) remove or obscure any proprietary or other notices contained in the Services, including in any reports or output obtained from the Services; (i) use or permit the Services to be used for any illegal or misleading purpose; or (j) establish an account on the Services as an individual for personal, family, or household purposes.</li>
                            <li>Compliance. Customer and its End Users must use the Services in compliance with the Acceptable Use Policy. Customer will comply with laws and regulations applicable to Customer's use of the Services. Customer will not take any action that would cause Diligentsia to violate EU Data Protection Laws, the U.S. Foreign Corrupt Practices Act of 1977, as amended, the U.K. Bribery Act of 2010, or any other applicable data protection, anti-bribery, anti-corruption, or anti-money laundering law. Customer must satisfy itself that: (i) the Services are appropriate for its purposes, taking into account the nature of the Customer Data; and (ii) the technical and organizational requirements applicable to Diligentsia under EU Data Protection Laws or other data protection laws, if applicable, are satisfied by the Security Measures and the Agreement.</li>
                            <li>HIPAA. If Customer will store, transmit or otherwise process any information via the Services that falls within the definition of “Protected Health Information” under the HIPAA Privacy Rule (45 C.F.R. Section 164.051), Customer must separately enter into a HIPAA Business Associate Agreement with Diligentsia prior to storing, transmitting, or otherwise processing this information. NOTWITHSTANDING THE FOREGOING, NOT ALL SERVICES OFFERED BY DILIGENTSIA ARE DESIGNED FOR PROCESSING PROTECTED HEALTH INFORMATION. IF CUSTOMER USES A SERVICE THAT IS NOT DESIGNED FOR PROCESSING PROTECTED HEALTH INFORMATION OR HAS NOT ENTERED INTO THE HIPAA BUSINESS ASSOCIATE AGREEMENT, CUSTOMER MAY NOT USE THE SERVICES TO STORE, TRANSMIT, OR PROCESS THIS INFORMATION.</li>
                            <li>Third-Party Apps and Integrations. If Customer uses any third-party service or applications, such as a service that uses a Diligentsia API, with the Services: (a) Diligentsia will not be responsible for any act or omission of the third-party, including the third-party’s access to or use of Customer Data; and (b) Diligentsia does not warrant or support any service provided by the third-party. Customer will comply with any API limits associated with the Services plan purchased by Customer.</li>
                        </ol>
                        <li className="pt2rem pb2rem">Customer Data</li>
                        <ol type="a" className="fw400 fs1rem c14 tal" style={{ lineHeight: "32px" }} >
                            <li>Customer Data Use. This Agreement constitutes Customer’s instructions to Diligentsia to Process Customer Data. Diligentsia, Diligentsia personnel and its Subcontractors will only Process, access, use, store, and transfer Customer Data as Customer instructs in order to deliver the Services and to fulfill Diligentsia’s obligations in the Agreement. If Customer accesses or uses multiple Services, Customer acknowledges and </li>
                        </ol>
                    </ol>
                    {/* <h1 className="df jcc fw500 fs2rem p3rem"
                        style={{ color: "#FF8787", lineHeight: "39.81px" }}>......More text after this
                    </h1> */}
                    <p className="pt2rem pb2rem fw500 fs1-5rem tal c14" style={{ LineHeight: "32px" }}>Appendix 1:</p>
                    <p className="fw400 fs1rem tal c14" style={{ LineHeight: "32px" }}>
                        Diligentsia Entities
                        The Agreement will be between Customer and the following Diligentsia entities, as applicable:
                        Diligentsia Limited, a company registered in England & Wales under company number 14256673
                    </p>
                    <p cclassName="pt2rem pb2rem fw500 fs1-5rem tal c14" style={{ LineHeight: "32px" }}>Appendix 2:</p>
                    <p className="fw400 fs1rem tal c14" style={{ LineHeight: "32px" }}>Service Specific Terms</p>
                    <p className="pt2rem pb2rem fw400 fs-125rem tal c14 " style={{ fontStyle: "italic", lineHeight: "32px" }}>
                        Posted: October 25, 2022
                        <br></br>
                        Effective: October 25, 2022
                    </p>
                    <p className="pt2rem pb2rem fw400 fs1-25rem tal c14" style={{ LineHeight: "32px" }}>
                        These Service-Specific Terms set forth additional terms and conditions that are specific to the Services listed below.</p>
                    <p className="fw400 fs1rem tal c14" style={{ LineHeight: "32px" }}>
                        Diligentsia may update these Service-Specific Terms from time to time, including to add additional Service-Specific Terms for new Services made available by Diligentsia, by posting an updated version. If an update materially changes any Service-Specific Terms for any Services already purchased by Customer, Diligentsia will notify Customer in accordance with Section 14.1 of the DSA.</p>
                    <p className="pt2rem pb2rem fw500 fs1-5rem tal c14" style={{ LineHeight: "32px" }} >
                        Diligentsia Free, Lite, Pro, Pro PLUS, Portfolio Pro & Expansion Packs
                    </p>
                    <ol type="1" className="fw400 fs1rem tal c14" style={{ LineHeight: "32px" }}>
                        <li>Service Description. The Diligentsia Free, Lite, Pro, Pro PLUS, Portfolio Pro & Expansion Packs services are described at https://www.Diligentsia.co.uk/plans or other link that Diligentsia may provide. The Diligentsia Education services are described at https://www.Diligentsia.com/education.Security & Audits</li>
                        <li>Security & Audits</li>
                        <li>Security Measures. The Security Measures for these Services are described at https://assets.Diligentsia.co.uk/documents/en/legal/security-measures.pdf or other link that Diligentsia may provide.</li>
                        <li>Customer Domains. Prior to providing the Services, Diligentsia may require Customer to verify that Customer owns or controls the Customer Domains. If Customer does not own or control the Customer Domains, then Diligentsia will have no obligation to provide Customer with the Services.</li>
                    </ol>
                </div >
            </div >
        </AnimatedPage >

    )
}
export default CompanyTerms



