import React from 'react'
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...(window.innerWidth < 768 && { adaptiveHeight: true })
};


const AboutUsCrousel = ({ crouselClass, data, hasNoPadding }) => {
    return <>
        <div className={`${crouselClass} ${hasNoPadding ? ' pt1rem pb3rem bscov pr pl4rem pr4rem silder-box pr oh' : " pt1rem pb3rem bscov pr pl4rem pr4rem silder-box pr oh"}`} style={{ backgroundImage: !hasNoPadding && `url(${require('../../../../images/landingpage/reactangle-image.png')})` }}>
            <Slider
                {...settings}
                interval={hasNoPadding && 6000}
                autoPlay={hasNoPadding ? true : false}>
                {data?.slider?.map((component, idx) => {
                    return (
                        <>
                            <div className={`df fdc aic tac tslp`} >
                                <div className="fw500 fs2-125rem mb1rem c15 aboutTitleSection" style={{ lineHeight: '44px', width: '100%' }}>{data?.title?.[idx]}</div>
                                <div className="fw400 fs1-25rem c15 mb1rem smfs1rem" style={{ lineHeight: '29px' }}>{data?.subTitle?.[idx]}</div>
                            </div>
                            <span class={idx == 0 && 'first-slide'}>{component}</span>
                        </>
                    )
                })}

            </Slider>

        </div >
    </>
}
export default AboutUsCrousel
