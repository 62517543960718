import { useEffect } from 'react';

export function useDidAgentStyles() {
    useEffect(() => {
        // Create a mutation observer to detect when the div is added
        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.type === 'childList') {
                    const didAgentRoot = document.querySelector('[data-testid="didagent_root"]');
                    if (didAgentRoot && didAgentRoot.shadowRoot) {
                        // Add custom styles to the shadow DOM
                        const style = document.createElement('style');
                        style.textContent = `.didagent_target{height: 100%}
                        .didagent__fabio[data-position=right] {position: relative;z-index: 9;width: 424px;right:0;height: 700px;display: flex;align-items: center;}
                        .didagent__fabio .didagent__fabio__container[data-enabled=true]{height: 790px; top: 0 !important; width: 424px}
                        .didagent__fabio>button{height: 424px;}
                        @media(max-width:768px) {
                        .didagent__fabio[data-position=right] {width: 100%}
                        .didagent__fabio .didagent__fabio__container[data-enabled=true] {height: 700px; top: 30px !important; width: 100%; position: absolute;}
                        .didagent__background {scale: 100%}
                        }
                        `;
                        didAgentRoot.shadowRoot.appendChild(style);
                        observer.disconnect();
                    }
                }
            }
        });

        // Start observing the document body for changes
        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);
}
