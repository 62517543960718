import React from 'react'
import Button from '../Button'
import { postRequest, showMessage } from '../../../config';

const ChangePassword = () => {

    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState({
        password: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const inputChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangePassword = async () => {
        if (!data.password) return showMessage("Enter your current password");
        if (!data.newPassword) return showMessage("Enter a new password");
        if (!data.confirmNewPassword)
            return showMessage("Confirm your new password");
        if (data.newPassword !== data.confirmNewPassword)
            return showMessage("New and confirmed passwords don't match");
        setLoading(true)
        const oldPassword = data.password;
        const newPassword = data.newPassword;

        const res = await postRequest(`/data/changePassword`, { oldPassword, newPassword });
        if (res["code"] == 200) {
            setUser({ password: '', newPassword: '', confirmNewPassword: '' });
            setLoading(false)
            showMessage(res["message"]);
        }
    };

    return (
        <>
            <h2 className="c4 fs1-5rem fs2rem-sm ttu w100 tac mt1rem">Change Password</h2>

            <div className="pr">
                <input
                    name="password"
                    type="password"
                    placeholder="Current Password"
                    className="bg1 pl4rem shadow w100 br5px p1rem"
                    value={data.password}
                    onChange={inputChanged}
                />
                <svg
                    className="center-vertically f3 pa l0 ml1rem w2rem"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        className="fa-primary"
                        d="M336 0c-97.2 0-176 78.8-176 176c0 14.71 2.004 28.93 5.406 42.59l128 127.1C307.1 349.1 321.3 351.1 336 351.1c97.2 0 176-78.8 176-176S433.2 0 336 0zM376 176c-22.09 0-40-17.91-40-40S353.9 96 376 96S416 113.9 416 136S398.1 176 376 176z"
                    />
                    <path
                        className="fa-secondary"
                        d="M165.4 218.6l-156 156C3.371 380.6 0 388.8 0 397.3V496C0 504.8 7.164 512 16 512l96 0c8.836 0 16-7.164 16-16v-48h48c8.836 0 16-7.164 16-16v-48h57.37c4.242 0 8.312-1.688 11.31-4.688l32.72-32.72L165.4 218.6z"
                    />
                </svg>
            </div>
            <div className="pr">
                <input
                    name="newPassword"
                    type="password"
                    placeholder="New Password"
                    className="bg1 pl4rem shadow w100 br5px p1rem"
                    value={data.newPassword}
                    onChange={inputChanged}
                />
                <svg
                    className="center-vertically f3 pa l0 ml1rem w2rem"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                >
                    <path
                        className="fa-primary"
                        d="M64 223.1h320c35.35 0 64 28.65 64 64v160c0 35.34-28.65 64-64 64H64c-35.35 0-64-28.66-64-64v-160C0 252.7 28.65 223.1 64 223.1z"
                    />
                    <path
                        className="fa-secondary"
                        d="M368 144V224h-64V144C304 99.88 268.1 64 224 64S144 99.88 144 144V224h-64V144c0-79.41 64.59-144 144-144S368 64.59 368 144z"
                    />
                </svg>
            </div>
            <div className="pr">
                <input
                    name="confirmNewPassword"
                    type="password"
                    placeholder="Confirm New Password"
                    className="bg1 pl4rem shadow w100 br5px p1rem"
                    value={data.confirmNewPassword}
                    onChange={inputChanged}
                />
                <svg
                    className="center-vertically f3 pa l0 ml1rem w2rem"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                >
                    <path
                        className="fa-primary"
                        d="M64 223.1h320c35.35 0 64 28.65 64 64v160c0 35.34-28.65 64-64 64H64c-35.35 0-64-28.66-64-64v-160C0 252.7 28.65 223.1 64 223.1z"
                    />
                    <path
                        className="fa-secondary"
                        d="M368 144V224h-64V144C304 99.88 268.1 64 224 64S144 99.88 144 144V224h-64V144c0-79.41 64.59-144 144-144S368 64.59 368 144z"
                    />
                </svg>
            </div>

            <Button
                onClick={() => handleChangePassword()}
                text="Change Password"
                loading={loading}
                icon={<><svg
                    className="pen ml0-5rem h1rem f1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                >
                    <path
                        className="fa-primary"
                        d="M215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
                    />
                    <path
                        className="fa-secondary"
                        d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
                    />
                </svg></>}
            />

        </>
    )
}

export default ChangePassword