import dayjs from 'dayjs';
import moment from "moment";
import { useState } from 'react';
import Switch from '@mui/material/Switch';
import Dropdown from 'react-bootstrap/Dropdown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { showMessage } from "../../config";

export default function ChecklistSetup({ isManualAI, setIsManualAI, setStepTab, checklistObj, setChecklistObj }) {

    const handleNextStep = () => {
        if (!checklistObj['moduleName']) return showMessage('Please enter checklist name');
        else if (!checklistObj['price']) return showMessage('Please select checklist price');
        else if (!checklistObj['moduleCreator']) return showMessage('Please enter creator name');
        else setStepTab('Choose Checklist Features')
    };

    return (
        <>
            <div className='checklist-form-area df fww w100 mb5rem'>
                <div className='checklist-form w50'>
                    <div className='pr mt1rem'>
                        <label className='w100 fw500 c15 db' for='checkListName'>Your Checklist Name</label>
                        <input onChange={(e) => setChecklistObj({ ...checklistObj, moduleName: e.target.value })} id="checkListName" placeholder="e.g. Human Resources Checklist 1.0" className='lpins w100 br10px p1rem mt0-5rem' type="text" />
                    </div>
                    <div className='pr mt1rem zi9'>
                        <label className='w100 fw500 c15 db op50' for='checkListName'>Price</label>
                        <Dropdown className="dropdown-add-group">
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-btn cp op50 lpins br10px p1rem pr mt0-5rem'>
                                {checklistObj?.['price'] ? <span>{checklistObj['price']}&#163;</span> : 'FREE'}
                                <span className="icon-right pa r0 mr1rem">
                                    <i className="icon">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3369 16.67C11.1615 16.671 10.9876 16.6374 10.8251 16.571C10.6627 16.5047 10.515 16.4069 10.3904 16.2834L2.39204 8.285C2.14102 8.03398 2 7.69352 2 7.33852C2 6.98352 2.14102 6.64306 2.39204 6.39204C2.64306 6.14102 2.98352 6 3.33852 6C3.69352 6 4.03398 6.14102 4.285 6.39204L11.3369 13.4573L18.3888 6.40537C18.6438 6.18698 18.9719 6.07286 19.3074 6.08582C19.6429 6.09878 19.9611 6.23786 20.1986 6.47528C20.436 6.71269 20.575 7.03094 20.588 7.36644C20.601 7.70194 20.4868 8.02998 20.2685 8.285L12.2701 16.2834C12.0218 16.5297 11.6866 16.6685 11.3369 16.67Z" fill="#4A67FF" />
                                        </svg>
                                    </i>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-content cp shadow fww">
                                <Dropdown.Item onClick={() => setChecklistObj({ ...checklistObj, price: 50 })} className='tal'>50&#163;</Dropdown.Item>
                                <Dropdown.Item onClick={() => setChecklistObj({ ...checklistObj, price: 80 })} className='tal'>80&#163;</Dropdown.Item>
                                <Dropdown.Item onClick={() => setChecklistObj({ ...checklistObj, price: 100 })} className='tal'>100&#163;</Dropdown.Item>
                                <Dropdown.Item onClick={() => setChecklistObj({ ...checklistObj, price: 150 })} className='tal'>150&#163;</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='pr mt1rem'>
                        <label className='w100 fw500 c15 db' for='checkListName'>Creator Name</label>
                        <input onChange={(e) => setChecklistObj({ ...checklistObj, moduleCreator: e.target.value })} id="creatorName" placeholder="Please type your name here" className='lpins w100 br10px p1rem mt0-5rem' type="text" />
                    </div>
                    <div className='date-time-picker pr mt1rem'>
                        <label className='w100 fw500 c15 db op50' for='creationDate'>Creation Date</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker defaultValue={dayjs(Date.now())} onChange={(date) => setChecklistObj({ ...checklistObj, createdAt: moment(date['$d']).format() })} />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className='selection-area-right w50 mt1rem pl5rem bsbb'>
                    <h4 className='c15 fs1-25rem mb0-5rem'>Manual V AI-generated?</h4>
                    <div className='manual-ai-area w100 pr fww'>
                        <div className='switch-area df aic w50'>
                            <p className='c15'>Manual</p>
                            <Switch name='manualai' className="success w100" checked={isManualAI} onChange={() => { setIsManualAI(!isManualAI), setChecklistObj({ ...checklistObj, isManualAI: !isManualAI }) }} />
                            <p className='c15'>AI-generated</p>
                        </div>
                        <div className='right-ai-area w50'>
                            <div className='img-box pr'>
                                <img className="" src={require(`../../../images/aifemale.png`)} alt="" />
                                <div className='ovelay-box'>
                                    <p className='c15 fs0-75rem' style={{ color: '#4459AC' }}>Hi, I’m Intelligentsia, your AI assistant. Just click me to ask me for help on your checklist creation</p>
                                </div>
                            </div>
                        </div>
                        <div className='tip-box w100 p2rem bg20 w100' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                <i className='icon mr1rem'>
                                    <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                </i>
                                Top Tip
                            </h5>
                            <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                                Our checklists consist of 10 sections, each of which has 10 questions, and associated risks and recommendations depending upon the ​answer provided to each question. ​<br /><br />
                                Select <b>“Manual”</b> if you wish to create a new ​checklist yourself, or you have an existing checklist you wish to recreate. Your checklist less than 10x10?- don’t worry – you can add/delete sections & questions and we will calculate our completeness and risk scores on the actual number of sections and questions. ​​<br /><br />
                                Select <b>“AI-generated”</b> if you want our AI to quickly create a draft checklist for you to ​review and amend if required.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-btn-area df aic w100 jcsb mt5rem bsbb'>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>2/6 Choose Checklist Features</p>
                <button onClick={() => handleNextStep()} className='shadow c1 bg4 bg3-hover c1 cp fw400 fs1rem pr br38px w200px h3rem df aic jcc'>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>
        </>
    )
}