import moment from "moment";
import Modal from "@mui/material/Modal";
import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';

import 'react-circular-progressbar/dist/styles.css';
import { toggleModal } from "../../../reducers/modalReducer";
import GoogleDrive from "../../../components/svgs/GoogleDrive";
import { getRequest, postRequest, showMessage } from '../../../config';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default function WorkflowReport({ getCompany, setCompany, calculateStats, sectionRefs, user, company, module, tab, setTab, aiEnhanced, setAiEnchaned, twoMinuteTestAIReport, sortSections }) {

	const dispatch = useDispatch();

	let modal = useSelector((state) => state.modal);

	let [noteId, setNoteId] = useState('');
	let [answerId, setAnswerId] = useState('');
	let [editNote, setEditNote] = useState('');
	let [taskNotes, setTaskNotes] = useState([]);
	let [isMarked, setIsMarked] = useState(false);
	let [addTextarea, setAddTextarea] = useState(1);
	let [isSaveNote, setIsSaveNote] = useState(false);
	let [statusDropdown, setStatusDropdown] = useState(false);
	let [checklistModule, setChecklistModule] = useState(false);
	let [priorityDropdown, setPriorityDropdown] = useState(false);
	let [directorCertification, setDirectorCertification] = useState('None');

	const getHeight = child => child.offsetHeight + parseFloat(getComputedStyle(child).marginTop) + parseFloat(getComputedStyle(child).marginBottom);

	useEffect(() => {
		if (!module) setTab(tab);
		if (tab && tab.includes('directors-questionnaire')) {
			if (module?.stats?.completionPercent >= 90 && module?.stats?.riskPercent <= 50) setDirectorCertification('Gold')
			else if (module?.stats?.completionPercent >= 75 && module?.stats?.riskPercent <= 50) setDirectorCertification('Silver')
			else setDirectorCertification('None')
		}
	}, []);

	useEffect(() => {
		if (user['role'] == 'Guest' && company?.['keyPerformanceIndicators']?.length == 0) getCompany();
	}, []);

	useEffect(() => {
		if (module?.['type']) {
			if (!([`two-minute-test-${company['testType']}`, 'repository', 'software-development', 'esg', 'warranty-disclosure', `business-audit-${company['testType']}`, 'directors-questionnaire'].includes(module['type']))) setChecklistModule(true);
		}
	}, [module]);

	useEffect(() => {
		document.getElementById("pdfContent").classList.add("workflow-pdf", "p2rem");
		let wrapper = document.getElementById("pdf");
		wrapper.innerHTML = "";
		let children = Array.from(document.getElementById("pdfContent").children);
		let i = 0;

		while (children.length && i < 250) {
			i++;
			let page = document.createElement("div");
			if (i > 1) page.classList.add("workflow-report");
			page.classList.add("workflow-pdf", "oyh", "bg1", "bsbb", "oh", "pr", "shadow", "a4-pdf");
			if (aiEnhanced) page.classList.add('report-ai');

			wrapper.appendChild(page);
			let watermark = document.createElement("img");
			// watermark.src = "/images/favicon.png";
			watermark.classList.add("op20", "pa");
			watermark.style.transform = "translate(-50%, -50%) rotate(30deg)";
			watermark.style.top = "50%";
			watermark.style.left = "50%";
			watermark.style.width = "297mm";
			page.appendChild(watermark);
			let pageHeight = getHeight(page);
			let usedPageHeight = 96;
			let childrenToRemove = 0;

			let footer = document.createElement("div");
			footer.classList.add("tac", "fw600", "df", "fdc", "jcfe", "h100");
			footer.innerText = "Page " + i
			let footerHeight = 8;

			if (i > 1) {
				let header = document.createElement("img");
				// header.src = "/images/logo.png";
				header.classList.add("db", "ma", "h3rem");
				header.innerText = "Page " + i
				page.appendChild(header);

				titleHeading(page);

				usedPageHeight += getHeight(header);
				if (i > 1) {
					if (module?.['type'].includes("warranty-disclosure")) warrantyDisclosureHeaders(page);
					if (module?.['type'].includes("directors-questionnaire")) directorsQuestionnaireHeaders(page);
					if (["software-development", "esg"].includes(module?.['type'])) riskRecommendationHeaders(page);
					if (checklistModule) checklistHeaders(page);
				}
			}

			for (let child of children) {
				let childHeight = getHeight(child);
				if (usedPageHeight + childHeight + footerHeight < pageHeight || childHeight > pageHeight - 150) {
					child.classList.add("pr");
					page.appendChild(child.cloneNode(true));
					usedPageHeight += childHeight;
					childrenToRemove++;
				} else {
					page.classList.add("df", "fdc");
					page.appendChild(footer);
					break;
				}
			}
			if (childrenToRemove == 0) {
				console.log("Remove", children.length, childrenToRemove);
				break;
			}

			children.splice(0, childrenToRemove);
		}

		wrapper.classList.remove("op0", "pen");
		document.getElementById("pdfContent").classList.remove("a4", "p3rem");

		let pdfInputs = wrapper.querySelectorAll("#pdf input");
		for (let j = 0; j < pdfInputs.length; j++) pdfInputs[j].addEventListener('click', () => setIsMarked(!isMarked));

		let pdfTextareas = wrapper.querySelectorAll("#pdf textarea");
		for (let j = 0; j < pdfTextareas.length; j++) pdfTextareas[j].addEventListener('change', (e) => {
			setAnswerId(e.target.id);
			handleTaskNotes(e);
		});
		for (let j = 0; j < pdfTextareas.length; j++) pdfTextareas[j].addEventListener('click', (e) => {
			setAnswerId(e.target.id);
			setIsSaveNote(true);
		});

		let reportBtn = document.querySelectorAll("#pdf button");
		for (let i = 0; i < reportBtn.length; i++) {
			if (reportBtn[i]['id'] == 'status-value') reportBtn[i].addEventListener('click', (e) => saveStatusTask(e));
			if (reportBtn[i]['id'] == 'status-dropdown') reportBtn[i].addEventListener('click', (e) => {
				setAnswerId(e.target.value);
				setPriorityDropdown(false);
				setStatusDropdown(!statusDropdown)
			});
			if (reportBtn[i]['id'] == 'priority-value') reportBtn[i].addEventListener('click', (e) => saveTaskPriority(e));
			if (reportBtn[i]['id'] == 'priority-dropdown') reportBtn[i].addEventListener('click', (e) => {
				setAnswerId(e.target.value);
				setStatusDropdown(false);
				setPriorityDropdown(!priorityDropdown)
			});
			if (reportBtn[i]['id'] == 'save-notes') reportBtn[i].addEventListener('click', (e) => saveTaskNotes(e));
			if (reportBtn[i]['id'] == 'plus-textarea') reportBtn[i].addEventListener('click', (e) => {
				if (answerId != e.target.value) {
					setAddTextarea(1);
					setTaskNotes([]);
				}
				setAnswerId(e.target.value);
				if (addTextarea < 10) setAddTextarea(addTextarea + 1);
				else showMessage('You can first save the previous notes!')
			}
			);
			if (reportBtn[i]['id'] == 'delete-note') reportBtn[i].addEventListener('click', (e) => {
				if (e.target.id) {
					answerId = e.target.id.split('+')[0];
					noteId = e.target.id.split('+')[1];

					setNoteId(noteId);
					setAnswerId(answerId);
					dispatch(toggleModal({ open: "deleteNotePopup" }));
				}
			});
			if (reportBtn[i]['id'] == 'edit-note') reportBtn[i].addEventListener('click', (e) => {
				if (e.target.id) {
					answerId = e.target.id.split('+')[0];
					noteId = e.target.id.split('+')[1];
					editNote = e.target.id.split('+')[2];

					setNoteId(noteId);
					setEditNote(editNote);
					setAnswerId(answerId);
					dispatch(toggleModal({ open: "editNotePopup" }));
				}
			});
		}

		document.body.onmousedown = () => {
			setTimeout(() => {
				if (statusDropdown) setStatusDropdown(false);
				if (priorityDropdown) setPriorityDropdown(false);
			}, 500);
		}
	});

	const titleHeading = (page) => {
		const title = document.createElement("span");
		title.classList.add("db", "ma", "mb1-75rem", "fw700", "fs1-25rem", "h4rem", "tac", "c6");
		if (tab.match(/two-minute-test-.*-report/)) title.innerText = '2 Minute ' + module?.heading + ' Workflow Report' + ' - ' + company['cName'];
		else if (checklistModule) title.innerText = module['type'] + ' Workflow Report' + ' - ' + company['cName'];
		else title.innerText = module?.['heading'] + ' Workflow Report' + ' - ' + company?.['cName'];

		page.appendChild(title);
		return page;
	};

	const checklistHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "gtcr-workflow-head", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Risk</h3><h3>Recommendation</h3>";
		page.appendChild(headings);
	};

	const repositoryHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Files Uploaded</h3><h3>Notes</h3>";
		page.appendChild(headings);
	};

	const riskRecommendationHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Risk</h3><h3>Recommendation</h3>";
		page.appendChild(headings);
	};

	const warrantyDisclosureHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Disclosure</h3><h3>Files Uploaded</h3>";
		page.appendChild(headings);
	};

	const directorsQuestionnaireHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Text</h3><h3>Files Uploaded</h3>";
		page.appendChild(headings);
	};

	const sortQuestions = (a, b) => {
		let valueA = a.qNo.split('.')[a.qNo.split('.').length - 1]
		let valueB = b.qNo.split('.')[a.qNo.split('.').length - 1]

		return valueA - valueB;
	};

	const sortLogs = (a, b) => new Date(b.timestamp) - new Date(a.timestamp);

	const getAuditContent = () => {
		const sectionAnswers = answers.filter(a => !Object.values(module?.sections).filter(s => s.isHidden).find(s => a.qNo.includes(s.sectionNo)));
		const logs = sectionAnswers.filter(a => a.logs.length).reduce((arr, a) => [...arr, ...a.logs], []).sort(sortLogs);
		return logs.map((log, idx) =>
			<div key={idx} className="bg1 p1rem br5px df my0-5rem">
				{log.userId.profilePicture
					&& <img className="ass ml0-5rem br100 w2rem h2rem" src={log.userId.profilePicture} />
					|| <span className="ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem bg3 c1">{log.userId.firstName[0]}{log.userId.lastName[0]}</span>
				}
				<div className="">
					<span><b>{log.message}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b></span>
					{log.files.map((file, idx) => <Link key={idx} to={file.location} target="_blank" className="db c3-hover fw600">{file.originalname}</Link>)}
					{(log.payload.answers || [log.payload]).map((answer, idx) => {
						const question = answer.questionId ? questions.find(q => q._id == answer.questionId) : "";
						return <Fragment>
							{question && <p className="mt0-5rem"><b>{question.qNo}</b> - {question.qDescription}</p>}
							{answer.file && <p className="mt0-5rem"><b>Filename</b>: {answer.file.originalname}</p>}
							{answer.status && <p className="mt0-5rem"><b>Changed Status</b>: {answer.status}</p>}
							{answer.radioOption && <p className="mt0-5rem"><b>Selected Option</b>: {answer.radioOption}</p>}
							{answer.comment && <p className="mt0-5rem"><b>Entered Comment</b>: {answer.comment}</p>}
						</Fragment>
					})}
				</div>
			</div>
		);
	};

	const getSyncContent = () => {
		const records = module?.sections.flatMap(section => section?.questions?.flatMap(question => (question?.answer?.logs || []))).sort(sortLogs);
		return <>
			<h2 className="df jcc aic mb1-3rem">
				<GoogleDrive className="w2rem mr0-5rem" />
				Google Drive Synchronization Report
			</h2>
			<div className="dg gtcr3 bg6 c1">
				<h3 className="fs1-5rem p1rem">Date Synced</h3>
				<h3 className="fs1-5rem p1rem">Synced By </h3>
				<h3 className="fs1-5rem p1rem">Files Synced</h3>
			</div>
			{records.map((record, idx) => (
				<div key={idx} className="dg tableRow gtcr3">
					<div className="df fdc jcc aic p1rem">{moment(record['timestamp']).format('lll')}</div>
					<div className="df fdc jcc aic p1rem"><span>{`${record['userId']['firstName']} ${record['userId']['lastName']}`}</span><br />{record['userId']['email']}</div>
					<div className="df fdc jcc aic p1rem" style={{ textAlign: 'left' }}>
						{record['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}
					</div>
				</div>
			))}
		</>
	};

	const getOverviewContent = () => {
		let array = [];
		if (['warranty-disclosure', 'directors-questionnaire', 'software-development', 'esg'].includes(module['type'])) {
			if (module?.sections[0]?.subFolders && Object.values(module?.sections[0]?.subFolders).length > 0) array = Object.values(module.sections[0].subFolders).filter(s => !s.isHidden);
		}
		else array = module?.sections.filter(s => !s.isHidden);

		return <div className="minh100vh 100vh">
			<div className="dg gtcr2a gg2rem bg5 pt1rem">
				<div className="px2-5rem py2rem">
					<p className="fs1-5rem fw600 lh3 c36">Workflow Completeness Report</p>
					<p className="fs1rem ttc fw300 lh2 c36 mb3rem">{module?.['type'] ? module['type'] : '-'}</p>
					<p className="fs0-75rem fw300 lh1 c36">Company Name:</p>
					<p className="fs0-75rem fw500 lh5 c36 mb0-5rem">{company?.['cName'] ? company['cName'] : '-'}</p>
					<p className="fs0-75rem fw300 lh1 c36">Test Type:</p>
					<p className="fs0-75rem ttc fw500 lh5 c36 mb0-5rem">Sell/{company['testType']}</p>
				</div>
				<div className="px2-5rem py2rem">
					<div className="df jce">
						{/* {module?.['type'].includes('Cybersecurity')
							? <img className="w100px mb3rem" src={require(`../../../../images/amicis-logo.png`)} alt="img" />
							: <img className="mb3rem" src={require(`../../../../images/thecfocenter.png`)} alt="img" />
						} */}
						{company?.['logo'] && <img className="w70px mb2rem" src={company['logo']} alt="img" />}
					</div>
					<p className="fs0-75rem fw300 lh4 c36 tae">Subscription: <span className="fw500">{((user['role'] == 'Advisor' || user['role'] == 'Investor') && company['subscription'] == 'Pro') ? 'Portfolio PRO' : <>{company['subscription']?.toUpperCase()}</>}</span></p>
					<p className="fs0-75rem ttc fw300 lh4 c36 tae">Company Reg: <span className="fw500">{company?.['ukRegNo'] ? company['ukRegNo'] : '-'} </span></p>
					<p className="fs0-75rem ttc fw300 lh4 c36 tae">Issue Date: <span className="fw500">{moment().format('ll')}</span></p>
					<p className="fs0-75rem fw300 lh4 c36 tae">Generated By:</p>
					<p className="fs0-75rem fw500 lh2 c36 tae">{user['email']}</p>
				</div>
			</div>
			<div className="dg gg1rem minh100vh-report">
				<div className="report-start">
					<div className="dg gtcr2a gg2rem px2rem py3rem">
						<div className="director-review">
							<p className="fs1rem fw600 lh1 c36 mb3-5rem">Current Workflow Status</p>
							<div className='df fdc'>
								<p className="fs1rem fw500 c36 tas w100 lh1">Director Review:</p>
								<div className="df fww aifs">
									<span className="checkobox-report pr mr0-5rem mt0-5rem">
										<input style={{ width: '25px', height: '25px' }} name='status' checked={isMarked} className="checkbox shadow df bg5 bo5 bw2px bss cp td250 br5px" type="checkbox" />
									</span>
									<span className="fs0-75rem c12 lh1 w80">
										{isMarked ? 'Marked as completed by' : 'Mark as complete'}
									</span>
									{isMarked
										? <div className="df fdc aifs ml1-5rem" style={{ marginTop: '-8px' }}>
											<div className="df fww">
												{user?.['profile']
													? <img src={user?.['profile']} className={`w2rem h2rem df jcc aic br100`} data-tooltip={user['firstName'] ? (user['firstName'] + " " + user?.['lastName']) : "Register to show your name here"} />
													: <span className={`w2rem h2rem df jcc aic br100`} data-tooltip={user?.['firstName'] ? (user['firstName'] + " " + user?.['lastName']) : "Register to show your name here"}>{user?.['firstName'] ? (user['firstName'][0] + user?.['lastName']?.[0]) : "?"}</span>
												}
												<div className="df fww">
													<p className='c36 fs0-75rem fw500 tal ml0-5rem w100'>{user?.['firstName'] ? user['firstName'] + " " + user?.['lastName'] : "?"}</p>
													<p className="c36 fs0-5rem fw400 tal ml0-5rem w100">{moment().format('ll')}</p>
												</div>
											</div>
										</div>
										: <p className="fs0-5rem c4 fw400 ml2rem" style={{ marginTop: '-8px' }}>Only authorized reviewer can mark complete</p>
									}
								</div>
							</div>
						</div>
						<div className="">
							<div className="df jce mb2-5rem">
								<p className="tae mr0-5rem fs1rem c36 fw500 lh1">Checklist <br /> Completeness</p>
								<div className={`progress-bar workflow-report-progress ${module?.stats?.workflowCompletionPercent > 75 ? 'workflow-progress-green' : module?.stats?.workflowCompletionPercent > 26 ? 'workflow-progress-yellow' : ' workflow-progress-pink'}`}
									style={{ width: 65, height: 65, backgroundColor: '#fda9a4', borderRadius: '50%', margin: 0, border: '2px solid #F1F1F1' }}>
									<CircularProgressbar
										text={(module?.stats?.workflowCompletionPercent || 0) + "%"}
										styles={buildStyles({
											strokeWidth: '0',
											textSize: '20px',
											trailColor: '#FBFBFB',
											stroke: 'transparent',
											strokeLinecap: 'butt',
											pathTransitionDuration: 0.5,
											backgroundColor: '#FBFBFB',
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
							</div>
							<p className="fs0-75rem fw500 lh4 c6 tae">Last Updated:</p>
							<p className="fs0-75rem fw300 lh4 c6 tae">
								{module && module?.['createdAt'] ? moment(module?.['createdAt']).format('lll') : 'Nothing updated'}
							</p>
						</div>
					</div>
					{array.length > 0 && array.sort(sortSections).map((section, idx) => {
						let completeness = section.stats.workflowCompletionPercent;
						let hexColour = section?.stats?.workflowCompletionPercent > 75 ? '#d8fcc7' : section?.stats?.workflowCompletionPercent > 26 ? '#fff4c9' : section?.stats?.workflowCompletionPercent > 1 ? '#ffcecc' : '#dbdbdb';

						return <div className="px2rem ">
							<button style={{ height: '40px' }} key={idx} data-tooltip={completeness && `This section has ${completeness}% workflow completeness`} data-scroll-to={section.sectionNo} className="df c3 c6 bg5 td250 c3-hover px1rem py0-5rem cp new-report-btn">
								<span className="pen usn ass mt0-5rem tal left-heading">{section['sectionNo']}</span>
								<span style={{ background: `linear-gradient(90deg, ${hexColour} 0%, ${hexColour} ${completeness}%, #f0f0f8 ${completeness}%)`, width: '70%', height: '28px', paddingTop: '10px', marginLeft: 0, paddingLeft: '25px' }} className="pen uss ass fw600 tal pt1rem br5px right-detail">{section.sectionName}</span>
								<div className={`progress-bar workflow-report-progress pa ${completeness > 75 ? 'workflow-progress-green' : completeness > 26 ? 'workflow-progress-yellow' : completeness > 1 ? ' workflow-progress-pink' : ' workflow-progress-grey'}`}
									style={{ width: 40, height: 40, backgroundColor: '#fda9a4', borderRadius: '50%', right: '3px', margin: 0, }}>
									<CircularProgressbar
										text={(completeness || 0) + "%"}
										styles={buildStyles({
											strokeLinecap: 'round',
											strokeWidth: '5px',
											textSize: '25px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
							</button>
						</div>
					})}
				</div>
			</div>
		</div>
	};

	const handleTaskNotes = async (e) => {
		let obj = { note: e.target.value };
		taskNotes = [...taskNotes, obj]
		setTaskNotes(taskNotes);
	};

	const saveTaskNotes = async () => {
		let array = taskNotes.filter(item => item['note'] != '');
		const res = await postRequest(`/question/addNote/${answerId}`, { taskNotes: array, companyId: company['_id'], moduleName: module['type'] });
		showMessage(res?.['message']);
		if (res['code'] == 200) {
			await handleUpdateWorkflow(res['body']);
			setAddTextarea(1);
			setTaskNotes([]);
		}
	};

	const saveStatusTask = async (e) => {
		await setStatusDropdown(false);
		const res = await postRequest(`/question/addNote/${answerId}`, { taskStatus: e.target.value, companyId: company['_id'], moduleName: module['type'] });
		showMessage(res?.['message']);
		if (res['code'] == 200) await handleUpdateWorkflow(res['body']);
	};

	const saveTaskPriority = async (e) => {
		await setPriorityDropdown(false);
		const res = await postRequest(`/question/addNote/${answerId}`, { taskPriority: e.target.value, companyId: company['_id'], moduleName: module['type'] });
		showMessage(res?.['message']);
		if (res['code'] == 200) await handleUpdateWorkflow(res['body']);
	};

	const handleUpdateWorkflow = async (updatedAnswer) => {
		let { sectionId } = updatedAnswer;
		if (module?.sections) for (let section of module?.sections) {
			if (section?.['_id'] == sectionId) {
				let questionIndex = await section?.['questions'].findIndex(({ _id }) => _id == updatedAnswer?.['questionId']);
				if (section?.['questions']?.[questionIndex]?.['answer']) section['questions'][questionIndex]['answer'] = updatedAnswer;
			}
		}
		if (company?.modules?.[module?.['type']]) company.modules[module['type']] = module;
		if (company?.['workflowUpdated']) company['workflowUpdated'][module['type']] = new Date();

		setAnswerId('');
		let companyClone = await calculateStats(company);
		await dispatch(setCompany(companyClone));
	};

	const handleDeleteNote = async () => {
		if (noteId != '' && answerId != '') {
			let res = await getRequest(`/question/deleteTaskNote/${answerId}/${noteId}`);
			if (res['code'] == 200) await handleUpdateWorkflow(res['body']);
			setNoteId('');
			dispatch(toggleModal({ open: '' }))
			showMessage(res?.['message']);
		}
	};

	const handleEditNote = async () => {
		if (noteId != '' && answerId != '') {
			let res = await postRequest(`/question/updateTaskNote/${answerId}/${noteId}`, { note: editNote });
			if (res['code'] == 200) await handleUpdateWorkflow(res['body']);
			setNoteId('');
			setEditNote('');
			dispatch(toggleModal({ open: '' }))
			showMessage(res?.['message']);
		}
	};

	const sortByDate = (a, b) => {
		return new Date(b.updatedAt) - new Date(a.updatedAt);
	};

	const getAuditReportContent = () => {
		let array = [];
		if (['warranty-disclosure', 'directors-questionnaire', 'software-development', 'esg'].includes(module['type'])) {
			if (module?.sections[0]?.subFolders && Object.values(module?.sections[0]?.subFolders).length > 0) array = Object.values(module.sections[0].subFolders).filter(s => !s.isHidden);
		}
		else array = module?.sections.filter(s => !s.isHidden);

		let checklistModule = false;
		if (module['type'] != "repository" && module['type'] != "software-development" && module['type'] != "esg" && module['type'] != "warranty-disclosure" && module['type'] != `business-audit-${company['testType']}` && !module['type'].includes("directors-questionnaire")) checklistModule = true

		sectionRefs.current.length = array.length
		return <>
			{array.length > 0 && array.map((section, index) => {
				return (
					<Fragment>
						<div className="mt1rem" id={section['sectionNo']} ref={(el) => { if (section['sectionNo']) { sectionRefs.current[index] = el } }}>
							<h2 className="df jcc aic tac mb1rem fs1rem">
								{section['sectionNo']} - {section['sectionName']} Report &nbsp;-&nbsp;
								{section.stats.completionPercent && section.stats.completionPercent > 0
									? <>
										<span className={section.stats.completionPercent < 34 ? "c9" : section.stats.completionPercent < 67 ? 'c8' : 'c7'}>Completed {section.stats.completionPercent || 0}%</span>
										{(section.stats.riskPercent > 0 && module['type'] === "repository") &&
											<>
												&nbsp;-&nbsp;
												<span className={section.stats.riskPercent < 38 ? "c7" : section.stats.riskPercent < 62 ? 'c8' : 'c9'}>{section.stats.riskPercent || 0}% Risk</span>
											</>
										}
									</>
									: <>
										<span>Not Started</span>
										&nbsp;-&nbsp;
										<span>No Risk Score</span>
									</>
								}
							</h2>
							{/* <div className={`dg gtcr-audit fs0-75rem bg6 c1 br5px ${checklistModule && ' gtcr-workflow-head'}`}>
								<h3 className="tac">Question</h3>
								<h3 className="tac">Answer</h3>
								{module['type'] == "repository" && <>
									<h3 className="tac">Files Uploaded</h3>
									<h3 className="tac">Notes</h3>
								</>}
								{module['type'].includes("directors-questionnaire") && <>
									<h3 className="tac">Text</h3>
									<h3 className="tac">Files Uploaded</h3>
								</>}
								{([`business-audit-${company['testType']}`, 'software-development', 'esg'].includes(module['type']) || checklistModule) && <>
									<h3 className="tac">Risk</h3>
									<h3 className="tac">Recommendation</h3>
								</>}
								{module['type'] == "warranty-disclosure" && <>
									<h3 className="tac">Disclosure</h3>
									<h3 className="tac">Files Uploaded</h3>
								</>}
							</div> */}
						</div>
						{section?.questions && section?.questions.slice().sort(sortQuestions).filter(q => q.answer).map((question, idx) => {
							if (!aiEnhanced && Object.values(question?.['answer']?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
							let isRisky = question.answer.radioOption === "No";

							let statusColor, priorityColor;
							if (question?.['answer']?.['taskStatus'] == 'N/A') statusColor = 'grey-bg'
							else if (question?.['answer']?.['taskStatus'] == 'Not Started') statusColor = 'sky-bg'
							else if (question?.['answer']?.['taskStatus'] == 'Started') statusColor = 'blue-bg'
							else if (question?.['answer']?.['taskStatus'] == 'In Progress') statusColor = 'yellow-bg'
							else if (question?.['answer']?.['taskStatus'] == 'Completed - Further Work') statusColor = 'orange-bg'
							else if (question?.['answer']?.['taskStatus'] == 'Completed - No Issues') statusColor = 'green-bg'
							else statusColor = ''

							if (question?.['answer']['taskPriority'] == 'N/A') priorityColor = 'grey-bg';
							else if (question?.['answer']['taskPriority'] == 'High Priority') priorityColor = 'red-bg';
							else if (question?.['answer']['taskPriority'] == 'Medium Priority') priorityColor = 'orange-bg';
							else if (question?.['answer']['taskPriority'] == 'Low Priority') priorityColor = 'yellow-bg';
							else priorityColor = '';

							let notes = [];
							if (question?.['answer']?.['taskNotes']) notes = question?.['answer']?.['taskNotes'].slice().sort(sortByDate);

							return (
								<>
									<div className={`dg tableRow fs0-75rem ${["repository", "directors-questionnaire"].includes(module?.['type']) ? 'gtcr-audit' : 'gtcr-audit-workflow'}`}>
										<div className={`df fdc aic jcfs pt0-25rem br5px`}>
											<p><span class="fw600">{question['qNo']}:</span> {question?.['qDescription']}</p>
										</div>
										{module?.['type'].includes("directors-questionnaire")
											? <div className="df fdc aic tac jcfs pt1rem br5px">{question?.questionType.includes("YN") ? (question.answer['radioOption'] || question.answer['status']) : "-"}</div>
											: <div className={`df fdc aic tac jcfs pt1rem br5px`}><p class={`${["Disclosure", "No Disclosure"].includes(question?.answer?.['radioOption']) ? ' px0-5rem ' : ' px1rem '} py0-5rem br10px c1 ${(question?.answer?.['radioOption'] || question?.answer?.['status'])?.includes('Yes') ? ' bg34' : (question?.answer?.['radioOption'] || question?.answer?.['status'])?.includes('No') ? ' bg4' : ' bg16'}`}>
												{question.answer['radioOption'] || question.answer['status'] || '-'}
											</p></div>
										}
										{module?.['type'] == "repository" && <>
											<div className="df fdc aic tac jcfs pt1rem br5px">
												{question?.answer?.files?.length > 0
													? <>{question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}</>
													: <p>No Files Uploaded</p>
												}
											</div>
											<div className="df fdc aic tac jcfs pt1rem br5px">
												{question?.['answer'] && <p>{question['answer']['comment']}</p>}
											</div>
										</>
										}
										{checklistModule && question?.['answer']?.['status'] == 'Completed' && <>
											<div id={question?.['answer']['_id']} className='df fdc tal jcs pt0-5rem mx0-5rem'>
												<div className="df pb0-5rem">
													<div className="w50 tac px0-5rem">
														{question?.['riskDescription'] || question?.['ifNo']}
													</div>
													<div className="w50 tac px0-5rem">
														{question?.['recommendDescription'] || question?.['toptip'] || (question.answer.radioOption == "Yes" && question?.['ifYes'])}
													</div>
												</div>
												<p class="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Files:
													{question?.['answer']?.['files'] && question?.['answer']?.['files'].length > 0 ? question['answer']['files'].map((file, idx) => <a className="ml0-5rem tdu" key={idx} href={file['location']} target="'_blank">
														{file['originalname']}</a>)
														: <span className="ml0-5rem fs0-75rem fw500">No files uploaded</span>}
												</p>
												{question?.['answer'] && question?.['answer']?.['_id'] && <>
													<div className="mb1rem py0-5rem px0-5rem df aic jcsb br5px" style={{ background: '#e1e1f155' }}>
														<p>Workflow Notes:</p>
														<div className="right-area-details df aic">
															<div className="priority-dropdown">
																<button id="priority-dropdown" value={question?.['answer']['_id']} data-tooltip="Priority"
																	className={`dropdown-btn px1rem fs0-75rem mr0-5rem c36 bg35 cp br20px py0-75rem pr bss bw1px bo36 ${question?.['answer']['taskPriority'] && priorityColor}`}>
																	{question?.['answer']['taskPriority'] || 'Priority'}
																</button>
																{answerId == question?.['answer']['_id'] && priorityDropdown &&
																	<ul className="dropdown-content cp new-shadow fww">
																		<li className="cp first-item red-bg"><button className="cp fs0-75rem" id="priority-value" value="High Priority">High Priority</button></li>
																		<li className="cp orange-bg"><button className="cp fs0-75rem" id="priority-value" value="Medium Priority">Medium Priority</button></li>
																		<li className="cp yellow-bg"><button className="cp fs0-75rem" id="priority-value" value="Low Priority">Low Priority</button></li>
																		<li className="cp last-item grey-bg "><button className="cp fs0-75rem" id="priority-value" value="N/A">N/A</button></li>
																	</ul>
																}
															</div>
															<div className="status-dropdown">
																<button id="status-dropdown" value={question?.['answer']['_id']} data-tooltip="Status"
																	className={`dropdown-btn px1rem fs0-75rem mr0-5rem c36 bg1 cp br20px py0-75rem pr bss bw1px bo36 ${question?.['answer']['taskStatus'] && statusColor}`}>
																	{question?.['answer']['taskStatus'] || 'Status'}
																</button>
																{answerId == question?.['answer']['_id'] && statusDropdown &&
																	<ul className="dropdown-content cp new-shadow fww">
																		<li className="cp first-item grey-bg"><button className="cp fs0-75rem" id="status-value" value="N/A">N/A</button></li>
																		<li className="cp sky-bg"><button className="cp fs0-75rem" id="status-value" value="Not Started">Not Started</button></li>
																		<li className="cp blue-bg"><button className="cp fs0-75rem" id="status-value" value="Started">Started</button></li>
																		<li className="cp yellow-bg"><button className="cp fs0-75rem" id="status-value" value="In Progress">In Progress</button></li>
																		<li className="cp orange-bg"><button className="cp fs0-75rem" id="status-value" value="Completed - Further Work">Completed - Further Work</button></li>
																		<li className="cp last-item green-bg"><button className="cp fs0-75rem" id="status-value" value="Completed - No Issues">Completed - No Issues</button></li>
																	</ul>
																}
															</div>
														</div>
													</div>
													<div className="br5px p1rem" style={{ background: '#e1e1f155' }}>
														{question?.['answer']?.['_id'] &&
															<div className="task-notes-scroll mt1rem">
																{[...Array(answerId == question?.['answer']['_id'] ? addTextarea : 1)].map((x, idx) => {
																	return (
																		<div className="df fdc br10px bg1 bss bw2px bo31 p1rem mb0-5rem">
																			<div className="icon-textare df">
																				<button id="plus-textarea" className="icon-plus p0 m0 cp c36" value={question?.['answer']?.['_id']}>&#43;</button>
																				<textarea className="w100 h100 br5px p0-5rem fs0-75rem" style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add workflow note" rows="1" cols="50"
																					value={(answerId == question?.['answer']['_id'] && taskNotes.length > 0) ? taskNotes?.[idx]?.['note'] : ''} id={question?.['answer']['_id']} onChange={handleTaskNotes}></textarea>
																			</div>
																			<div className="df jsfs w100 pt0-5rem">
																				<p style={{ fontSize: '10px' }} className="ml-1rem c12">{moment(Date.now()).format('lll')}</p>
																			</div>
																		</div>
																	)
																})}
															</div>}
														{answerId == question?.['answer']['_id'] && isSaveNote &&
															<div className="df jcc">
																<button id="save-notes" value={question?.['answer']['_id']}
																	className={`dropdown-btn bg4 fs0-75rem mt0-5rem c1 cp br5px px1rem py0-75rem pr bss bw1px bo4`}>
																	{addTextarea > 1 ? 'Save Notes' : 'Save Note'}
																</button>
															</div>
														}
														{/* Show All Notes */}
														{notes && notes.length > 0 &&
															<div className="task-notes-scroll">
																<p className="fs0-75rem fw500 c36">Notes:</p>
																{notes.map(item => {
																	return <>
																		<div className="notes-details mt0-5rem bssb px0-5rem">
																			<div className="df jcsb aic mb1rem">
																				<div className="w80 notes-left-area df aic">
																					<div className="notes-img mr0-5rem">
																						{item?.['user']?.['profile']
																							? <img src={item?.['user']?.['profile']} className={`w2rem h2rem df jcc aic br100`} data-tooltip={item?.['user']['firstName'] ? (item?.['user']['firstName'] + " " + item?.['user']?.['lastName']) : "Register to show your name here"} />
																							: <span className={`w2rem h2rem df jcc aic br100`} data-tooltip={item?.['user']['firstName'] ? (item?.['user']['firstName'] + " " + item?.['user']?.['lastName']) : "Register to show your name here"}>{item?.['user']['firstName'] ? (item?.['user']['firstName'][0] + item?.['user']?.['lastName']?.[0]) : "?"}</span>
																						}
																					</div>
																					<div className="notes-text">
																						<h4 className="fs0-5rem c36" style={{ fontSize: '10px' }}>{item?.['user']['firstName'] + " " + item?.['user']?.['lastName']}: <span className="fw300 c37">{item['note']}</span></h4>
																						<p className="mr1rem fs0-5rem" color="#4C6B9270">{moment(item['createdAt']).format('lll')}</p>
																					</div>
																				</div>
																				{item['user']['_id'] == user['_id'] &&
																					<div className="w20 notes-left-area df jcc aic">
																						<button className="cp c4" style={{ background: 'transparent' }} id="delete-note">
																							<i id={question?.['answer']['_id'] + '+' + item['_id']} data-tooltip="Delete Note" class="fa fa-trash" aria-hidden="true"></i>
																						</button>
																						<button className="cp c36" style={{ background: 'transparent' }} id="edit-note">
																							<i id={question?.['answer']['_id'] + '+' + item['_id'] + '+' + item['note']} data-tooltip="Edit Note" class="fa fa-pencil" aria-hidden="true"></i>
																						</button>
																					</div>
																				}
																			</div>
																		</div>
																	</>
																})}
															</div>
														}
													</div>
												</>}
											</div>
										</>
										}
										{module?.['type'].includes("directors-questionnaire") && <>
											<div className="df fdc aic tac jcfs pt1rem">
												{question?.questionType.includes("Date")
													? <>{question?.answer && <p>{moment(question?.answer?.date).format('ll')}</p>}</>
													: <>{question?.['answer'] && <p>{question['answer'] ? (question['answer']['comment'] || question['answer']['dropdown']) : '-'}</p>}</>
												}
											</div>
											<div className="df fdc aic tac jcfs pt1rem">
												{question.answer.files.length > 0
													? <>{question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}</>
													: <p>-</p>
												}
											</div>
										</>
										}
										{module?.['type'].includes(`business-audit-${company['testType']}`) &&
											<div className="df fdc tal jcfs pt0-5rem">
												<div className="df  pb0-5rem">
													<div className="w50 tac px0-5rem">
														{isRisky ? <>
															{question?.['answer']?.['aiResponse']?.['ifriskDescription'] && question?.['answer']?.['aiResponse']?.['ifriskDescription']}
															{question['riskDescription'] && question['riskDescription']}
														</> : "Well done"
														}
													</div>
													<div className="w50 tac px0-5rem">
														{isRisky ? <>
															{question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] && question?.['answer']?.['aiResponse']?.['ifrecommendDescription']}
															{question['recommendDescription'] && question['recommendDescription']}
														</> : "No recommendation at this time"
														}
													</div>
												</div>
											</div>
										}
										{["warranty-disclosure", "software-development", "esg"].includes(module?.['type']) &&
											<div className='df fdc tal jcfs pt0-5rem'>
												<div className="df pb0-5rem">
													<div className="w50 tac px0-5rem">
														{module?.['type'] == 'warranty-disclosure' && question['answer']['radioOption'] == 'Disclosure'
															? question?.['answer']?.['comment'] && question?.['answer']?.['comment']
															: module?.['type'] == 'warranty-disclosure' ? '-' : (question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] || question['recommendDescription'])
														}
													</div>
													<div className="w50 tac px0-5rem">
														{module?.['type'] == 'warranty-disclosure'
															? question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)
															: (question?.['answer']?.['aiResponse']?.['ifriskDescription'] || question['riskDescription'])
														}
													</div>
												</div>
												{(["software-development", "esg"].includes(module?.['type'])) && question?.['answer']?.['files'] && question?.['answer']?.['files'].length > 0 &&
													<p class="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Files: {question['answer']['files'].map(file => <a class="ml0-5rem tdu" href={file['location']} target="'_blank">{file['originalname']}</a>)}</p>}
											</div>
										}
									</div >
								</>
							)
						})}
					</Fragment >
				)
			}
			)}
		</>
	};

	const getTwoMinuteTestContent = () => {

		const writeSections = sections => Object.values(sections).filter(s => !s.isHidden).map((section, index) => {
			const questionsAndAnswers = section?.questions?.slice().sort(sortQuestions).filter(q => q?.answer?.radioOption);
			if (questionsAndAnswers && questionsAndAnswers.length)
				sectionRefs.current.length = questionsAndAnswers.length
			return <>
				{questionsAndAnswers.map((question, idx) => {
					const { answer } = question;
					if (!aiEnhanced && Object.values(answer?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
					return <Fragment key={idx}>
						<h3 id={section.sectionNo} ref={(el) => { if (section['sectionNo']) { sectionRefs.current[index] = el } }} className="usn pen fs1rem fw600 mt-3 tac">
							{section.sectionNo} - {section.sectionName}
						</h3>
						<div className="question-box  c1">
							<div className=""><h4>Question: {question['qNo']}</h4></div>
							<p>{question['qDescription']}</p>
							<p className="mt1rem"><b>Answer:</b> <span className={`${(question['answer']['radioOption'] == 'Yes' || question['answer']['radioOption'] < 3) ? 'green' : 'red'} c4`}>
								{question['answer']['radioOption']}
							</span></p>
						</div>
						<div className="recomended-box">
							{(() => {
								const recommendationKey = ["ifYes", "ifNo", "", "if1", "if2", "if3", "if4", "if5"].find(key => key.includes("if" + answer.radioOption));
								return recommendationKey && question[recommendationKey] && <>
									<p><b>Recommendation:</b></p>
									<p className="fs1rem">{answer?.['aiResponse']?.[recommendationKey] || question[recommendationKey]}</p>
								</>
							})()}
						</div>
					</Fragment>
				})}
			</>
		});
		return writeSections(module?.sections);
	};

	const getKPIContent = () => {
		if (!aiEnhanced) setAiEnchaned(true);
		return (
			<>
				<tr>
					<th className="miw8rem">KPI Name</th>
					<th className="miw8rem">KPI Description</th>
					<th className="miw8rem">KPI Calculation</th>
					<th className="miw8rem">KPI Metric</th>
					{/* <th className="miw8rem">Benchmarking</th> */}
				</tr>
				{company.keyPerformanceIndicators.map((kpi, idx) => (
					<tr key={idx}>
						<td className="miw8rem"><b>{kpi.title || '-'}</b></td>
						<td className="miw8rem">{kpi.name}</td>
						<td className="miw8rem">{kpi.calculation}</td>
						<td className="miw8rem">{kpi.metric}</td>
						{/* <td className="miw8rem">Coming Soon</td> */}
					</tr>
				))}
			</>
		)
	};

	return <>
		{company && <>
			<table id="pdf" className="op0 pen ma td250 dg gg2rem"></table>

			{/* Call To Action Buttons */}
			<div className="hideOnDownload mt2rem aic df">
				{(!user.firstName && tab == 'kpi-report') &&
					<div className="w100 df jcc aic fdc ">
						<p className="fs1-25rem mb1rem tac">Now let's view your 2-minute test report...</p>
						{user['role'] == 'Guest' && <>
							<button onClick={() => twoMinuteTestAIReport(module)}
								className="shadow bsbb jcc w200px w9rem df fdc aic fw500  px1rem c1 bg4 br19px cp registerButtonAnimation" style={{ width: '25%', paddingTop: '7px', paddingBottom: '7px' }}>
								View 2-Minute Test Report
							</button>
						</>}
					</div>
				}
				{tab.match(/two-minute-test-.*-report/) && (user?.['role'] == 'Guest') &&
					<div className="w100 df jcc aic">
						<a href={`/company/${company['_id']}`} style={{ width: '15%', paddingTop: '7px', paddingBottom: '7px' }}
							className={`shadow bsbb jcc df fdc aic fw500 my1rem px1rem c1 bg4 br19px cp registerButtonAnimation`}>
							Next
						</a>
					</div>
				}
				{/* Repository */}
				{module && module?.['type'] == "repository" &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab("")} className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
				{/* Business-Audit */}
				{module && module?.['type'].includes(`business-audit-${company?.['testType']}`) &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab(module['type'])} className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Business Audit
						</button>
						<button onClick={() => setTab("")} className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
				{/* Warranty-Disclosure */}
				{module && module?.['type'] == "warranty-disclosure" &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab("")} className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
			</div>

			<table id="pdfContent" className="bsbb w0 h0 oh op0 pen db">
				{tab === "kpi-report"
					? <>
						<h2 className="w200 ttc tac c6 lh2 fs1-75rem mb2rem">{module?.heading + ' Report' + ' - ' + company['cName']}</h2>
						{(!user.firstName && tab == 'kpi-report') &&
							<p className="tac fs1-25rem fw600 mb1rem">Well done for completing your 2-minute {company.testType} readiness test.  As a reward we have created a bespoke KPI report for your company.  Take a look.</p>
						}
						{getKPIContent()}
					</>
					: <>
						{getOverviewContent()}
						{['repository-report', `business-audit-${company?.['testType']}-report`, 'warranty-disclosure-report', 'software-development-report', 'esg-report', `directors-questionnaire_${module?.['director']?.['_id']}`].includes(tab)
							? getAuditReportContent() :
							tab.includes("repository-sync-report") ? getSyncContent() :
								tab.includes("repository-audit-report") ? getAuditContent() :
									tab.match(/two-minute-test-.*-report/) ? getTwoMinuteTestContent() : getAuditReportContent()
						}
					</>
				}
			</table>

			{/* Delete Note Popup */}
			<Modal id='deleteNotePopup' open={modal['open'] === 'deleteNotePopup'} className='backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic'>
				<div className='mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr'>
					<h1 className='text-gradient fs2rem tac lh1 fw600 mb2rem'>
						Confirmation
					</h1>
					<p className='fs1rem c15 fw400 my1rem'>Are you sure you want to delete note ?</p>
					<div className='df jcsb gg1rem mt1rem'>
						<button onClick={() => { setNoteId(''), dispatch(toggleModal({ open: '' })) }} className='shadow bo4 bss bg1 bg4-hover c4 c1-hover td250 br5px p1rem fw600 cp w30 ttu  bsbb tac mb1rem df jcc aic'>
							Cancel
						</button>
						<button data-scroll-to={answerId} onClick={() => handleDeleteNote()} className='shadow bg3 bo3 bss bo4-hover bg4-hover c1 td250 br5px p1rem fw600 w50 cp ttu  bsbb tac mb1rem df jcc aic'>
							Confirm
						</button>
					</div>
				</div>
			</Modal>

			{/* Edit Note Popup */}
			<Modal id='editNotePopup' open={modal['open'] === 'editNotePopup'} className='backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic'>
				<div className='mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr'>
					<h1 className='text-gradient fs2rem tac lh1 fw600 mb2rem'>Edit Note</h1>
					<textarea className="h100 bss bw2px bo16 w100 br5px px1-5rem py1rem fs1rem mb1rem" style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add workflow note" rows="2" cols="50"
						defaultValue={editNote || ''} onChange={(e) => setEditNote(e.target.value)}></textarea>
					<div className='df jcsb gg1rem mt1rem'>
						<button onClick={() => { setNoteId(''), setEditNote(''), dispatch(toggleModal({ open: '' })) }} className='shadow bo4 bss bg1 bg4-hover c4 c1-hover td250 br5px p1rem fw600 cp w30 ttu  bsbb tac mb1rem df jcc aic'>Cancel</button>
						<button onClick={() => handleEditNote()} className='shadow bg3 bo3 bss bo4-hover bg4-hover c1 td250 br5px p1rem fw600 w50 cp ttu  bsbb tac mb1rem df jcc aic'>Submit</button>
					</div>
				</div>
			</Modal>
		</>}
	</>;
} 