
import Modal from "@mui/material/Modal";
import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import "cropperjs/dist/cropper.css";
import { UserIcon } from "./Advisor/Team.js";
import { updateUser } from "../../reducers/userReducer";
import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import { hasValidAdvisor } from "./Advisor/BusinessCards.js";
import { getRequest, showMessage, postRequest } from "../../config";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";
import Button from "../../components/Form/Button/index.js";
import ChangePassword from "../../components/Form/ChangePassword/index.js";
import ProfileImageUploader from "../../components/Form/ProfileImageUploader/index.js";
import ProfileForm from "../../components/Form/ProfileForm/index.js";


export default function Profile() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const userData = useSelector((state) => state.user);

  const [user, setUser] = useState({});
  const [verify, setVerify] = useState();
  const [profileClass, setProfileClass] = useState("client-profile");

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setUser({ ...userData });
    if (userData)
      setProfileClass(
        userData["role"] == "Advisor"
          ? "advisor-profile"
          : userData["role"] == "Investor"
            ? "investor-profile"
            : "client-profile"
      );
  }, [userData]);

  const getUser = async () => {
    const res = await getRequest("/user");
    if (!res || res["code"] != 200) return;
    dispatch(updateUser(res["body"]["user"]));
  };

  const inputChanged = (e) => {
    const { name, type } = e.target;
    if (name === "twoFaEnabled" && !user["twoFaEnabled"]) enableAuthenticator();
    else if (name === "smsEnabled" && !user["smsEnabled"]) sendCode();
  };

  const enableAuthenticator = async () => {
    const res = await getRequest("/user/enable2Factor");
    if (!res || res["code"] != 200) {
      return showMessage(res["message"]);
    }

    const { qrData, secret, backupCodes } = res.body;
    dispatch(toggleModal({ open: "authenticator" }));
    document.getElementById("qrCode").src = qrData;
    // document.getElementById("secret").innerText = secret;
    // document.getElementById("backupCodes").innerText = backupCodes;
  };

  const sendCode = async () => {
    const phone = userData["phone"];
    if (!phone) {
      showMessage("Please provide phone number");
      return;
    }
    if (!isValidPhoneNumber(phone)) {
      showMessage("Please provide valid phone number");
      return;
    }

    const response = await postRequest("/user/sendSMS", { phone });
    showMessage(response["message"]);
    if (!response || response['code'] !== 200) return;

    setVerify("Phone");
    dispatch(toggleModal({ open: "verify" }));
  };

  const verifySMSCode = async (e) => {
    const authKey = e.target.value;
    if (authKey.length < 6) return;

    const response = await postRequest("/user/enableSMS", { authKey });
    if (!response) return;
    showMessage(response["message"]);
    getUser();
    dispatch(toggleModal({ open: "" }));
  };

  const verifyAuthenticatorCode = async (e) => {
    const tokenCode = e.target.value;
    if (tokenCode.length < 6) return;

    const response = await postRequest("/user/verify2Factor", { tokenCode });
    if (!response) return;
    showMessage(response["message"]);

    getUser();
    dispatch(toggleModal({ open: "" }));
  };


  const handleClose = () => {
    dispatch(toggleModal({ open: "" }))
    dispatch(updateUser(user))
  }

  return (
    <AnimatedPage>
      <div className="container py5rem df fdc">
        <HeadingSubheadingContent
          wrapperClass="mb2rem tac"
          heading="Profile"
          subheading="Edit Your Diligentsia User Profile"
        />
        <div className="maw600px mxa w100 df fdc gg1rem">
          <div className="maw600px mxa w100 df fdc gg1rem">
            {user["role"] == "Advisor" && <div className="df jce"><UserIcon color={hasValidAdvisor(user) ? '#008000' : '#F44336'} /></div>}

            {/* Image Uploader */}
            <ProfileImageUploader profileClass={profileClass} />

            {/* User detail form */}
            <ProfileForm userData={userData} setVerify={setVerify} sendCode={() => sendCode()}>
              <div className="mt1rem mb1rem">
                <div className="fs1-5rem fw600 m1rem w100 tac">
                  Choose how to login securely
                </div>
                <div className="dg gtcr3-md gg1rem tac">
                  <label
                    className={(user.phone ? "" : "op40 pen") + " shadow br5px p1rem bg1 df fdc jcc aic cp bss td250 bw5px " + (user.smsEnabled ? "bo3" : "bo1")}
                  >
                    <input
                      name="smsEnabled"
                      className="dn"
                      type="checkbox"
                      onChange={inputChanged}
                    />
                    <svg
                      className="f3 h3rem"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        className="fa-primary"
                        d="M352 48V384H32V48C32 21.5 53.5 0 80 0h224C330.5 0 352 21.5 352 48z"
                      />
                      <path
                        className="fa-secondary"
                        d="M32 384v80C32 490.5 53.5 512 80 512h224c26.5 0 48-21.5 48-48V384H32zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1z"
                      />
                    </svg>
                    <span className="mt0-5rem w100 fs1rem lh3 usn">
                      Use SMS Authentication
                    </span>
                  </label>
                  <label
                    className={
                      "shadow br5px p1rem bg1 df fdc jcc aic cp bss td250 bw5px " +
                      (user.twoFaEnabled ? "bo3" : "bo1")
                    }
                  >
                    <input
                      name="twoFaEnabled"
                      className="dn"
                      type="checkbox"
                      onChange={inputChanged}
                    />
                    <img
                      className="h3rem"
                      src={require("../../../images/google_authenticator.svg")}
                    />
                    <span className="mt0-5rem w100 fs1rem lh3 usn">
                      Use App e.g. Google Authenticator
                    </span>
                  </label>
                  <label
                    className={
                      "shadow br5px p1rem bg1 df fdc jcc aic cp bss td250 bw5px " +
                      (user.emailEnabled ? "bo3" : "bo1")
                    }
                  >
                    <input
                      id="email"
                      name="emailEnabled"
                      className="dn"
                      type="checkbox"
                      onChange={inputChanged}
                    />
                    <svg className="f3 h3rem" viewBox="0 0 512 512">
                      <path
                        d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z"
                        className="fa-secondary"
                      ></path>
                      <path
                        d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z"
                        className="fa-primary"
                      ></path>
                    </svg>
                    <span className="mt0-5rem w100 fs1rem lh3 usn">
                      Use Email Authentication
                    </span>
                  </label>
                </div>
              </div>

            </ProfileForm>

          </div>
          <ChangePassword />

        </div>
      </div>

      {/* Authenticator */}
      <Modal
        open={modal["open"] == "authenticator"}
        onClose={handleClose}
        className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic"
      >
        <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
          <button
            className="close-popup"
            onClick={handleClose}
          >
            <svg
              style={{ position: "relative", top: "4px" }}
              className="pen fds h1rem f1 mr0-5rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                className="pen fa-primary"
                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
              />
            </svg>
          </button>
          <h1 className="text-gradient fs2rem tac lh1 fw600 mb1rem">
            Authenticator Setup
          </h1>
          <h2 className="c4 fs1rem ttu w100 tac mb2rem">
            Scan the QR Code in your Authenticator App
          </h2>
          <img className="maw400px mxa db" id="qrCode" />
          <p>
            <span id="secret"></span>
          </p>
          <p>
            <span id="backupCodes"></span>
          </p>
          <div className="df fdc gg1rem maw400px mxa">
            <p>Once scanned, enter the 6 digit code generated by the app</p>
            <div className="pr">
              <input
                type="text"
                placeholder="Enter 6 Digit Code"
                className="bg1 pl4rem shadow w100 br5px p1rem"
                onChange={verifyAuthenticatorCode}
              />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  className="fa-primary"
                  d="M416 127.1h-58.23l-10.67 64H416c17.67 0 32-14.32 32-31.1C448 142.3 433.7 127.1 416 127.1zM0 352c0 17.67 14.33 31.1 32 31.1h58.23l10.67-64H32C14.33 319.1 0 334.3 0 352zM384 319.1H165.8l-10.67 64H384c17.67 0 32-14.33 32-31.1C416 334.3 401.7 319.1 384 319.1zM64 191.1h218.2l10.67-64H64c-17.67 0-32 14.33-32 32C32 177.7 46.33 191.1 64 191.1z"
                />
                <path
                  className="fa-secondary"
                  d="M341.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3l-43.54 261.3h64.88l41.79-250.7C370.5 51.83 358.7 35.34 341.3 32.43zM240.4 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C268.5 479.9 270.3 480 272 480c15.36 0 28.92-11.09 31.53-26.73l11.54-69.27h-64.88L240.4 442.7zM80.44 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C108.5 479.9 110.3 480 112 480c15.36 0 28.92-11.09 31.53-26.73L187.1 191.1H122.2L80.44 442.7zM181.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3L132.9 127.1H197.8l9.789-58.74C210.5 51.83 198.7 35.34 181.3 32.43z"
                />
              </svg>
            </div>
          </div>
        </div>
      </Modal>

      {/* Verify */}
      <Modal
        open={modal["open"] == "verify"}
        onClose={handleClose}
        className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic"
      >
        <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
          <button
            className="close-popup"
            onClick={handleClose}
          >
            <svg
              style={{ position: "relative", top: "4px" }}
              className="pen fds h1rem f1 mr0-5rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                className="pen fa-primary"
                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
              />
            </svg>
          </button>
          <h1 className="text-gradient fs3rem tac lh1 fw600 mb2rem">
            {verify} Verification
          </h1>
          <h2 className="c4 fs1-5rem fs2rem-sm ttu w100 tac mb2rem">
            Check your {verify} for a code
          </h2>
          <div className="df fdc gg1rem maw400px mxa">
            <div className="pr">
              <input
                type="text"
                placeholder="Enter 6 Digit Code"
                className="bg1 pl4rem shadow w100 br5px p1rem"
                onChange={verifySMSCode}
              />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  className="fa-primary"
                  d="M416 127.1h-58.23l-10.67 64H416c17.67 0 32-14.32 32-31.1C448 142.3 433.7 127.1 416 127.1zM0 352c0 17.67 14.33 31.1 32 31.1h58.23l10.67-64H32C14.33 319.1 0 334.3 0 352zM384 319.1H165.8l-10.67 64H384c17.67 0 32-14.33 32-31.1C416 334.3 401.7 319.1 384 319.1zM64 191.1h218.2l10.67-64H64c-17.67 0-32 14.33-32 32C32 177.7 46.33 191.1 64 191.1z"
                />
                <path
                  className="fa-secondary"
                  d="M341.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3l-43.54 261.3h64.88l41.79-250.7C370.5 51.83 358.7 35.34 341.3 32.43zM240.4 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C268.5 479.9 270.3 480 272 480c15.36 0 28.92-11.09 31.53-26.73l11.54-69.27h-64.88L240.4 442.7zM80.44 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C108.5 479.9 110.3 480 112 480c15.36 0 28.92-11.09 31.53-26.73L187.1 191.1H122.2L80.44 442.7zM181.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3L132.9 127.1H197.8l9.789-58.74C210.5 51.83 198.7 35.34 181.3 32.43z"
                />
              </svg>
            </div>
          </div>
        </div>
      </Modal>


    </AnimatedPage>
  );
}