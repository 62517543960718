import React, { useState, useEffect } from 'react'


const ButtonCss = { right: '26px', bottom: '99px', width: '40px', zIndex: '999' }


const SmoothScrollingButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isHover, setIsHover] = useState(false)

    useEffect(() => {
        const toggleVisibility = () => {

            if (window.scrollY > 400) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () =>  window.removeEventListener('scroll', toggleVisibility);
    }, []);



    // Scroll to top when button is clicked
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });


    return (
        <div
            className={`pf cp zi10 ${isVisible ? 'db' : 'dn'}`}
            onClick={scrollToTop}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {
                isHover ?
                    <svg className="pf top-arrow-btn" width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...ButtonCss }}>
                        <rect opacity="0.8" width="69" height="69" rx="34.5" fill="#4A67FF" />
                        <path d="M45.2266 33.1712C45.4061 33.3446 45.5493 33.552 45.6478 33.7814C45.7463 34.0107 45.7982 34.2574 45.8004 34.507C45.8025 34.7566 45.755 35.0041 45.6605 35.2351C45.5659 35.4662 45.4264 35.6761 45.2499 35.8526C45.0734 36.0291 44.8635 36.1686 44.6325 36.2632C44.4014 36.3577 44.1539 36.4052 43.9043 36.4031C43.6547 36.4009 43.408 36.349 43.1787 36.2505C42.9493 36.152 42.7419 36.0088 42.5685 35.8293L36.3783 29.639V51.4185C36.3783 51.9171 36.1803 52.3952 35.8277 52.7477C35.4752 53.1003 34.9971 53.2983 34.4985 53.2983C33.9999 53.2983 33.5218 53.1003 33.1693 52.7477C32.8167 52.3952 32.6187 51.9171 32.6187 51.4185V29.639L26.4285 35.8293C26.0739 36.1717 25.5991 36.3611 25.1062 36.3569C24.6133 36.3526 24.1419 36.1549 23.7933 35.8064C23.4448 35.4578 23.2471 34.9863 23.2428 34.4935C23.2385 34.0006 23.428 33.5257 23.7704 33.1712L33.1695 23.7722C33.3441 23.5971 33.5515 23.4582 33.7799 23.3634C34.0083 23.2687 34.2531 23.2199 34.5004 23.2199C34.7476 23.2199 34.9925 23.2687 35.2208 23.3634C35.4492 23.4582 35.6567 23.5971 35.8313 23.7722L45.2266 33.1712ZM45.7773 15.7021H23.2197C22.7211 15.7021 22.243 15.9002 21.8904 16.2527C21.5379 16.6053 21.3398 17.0834 21.3398 17.582C21.3398 18.0805 21.5379 18.5586 21.8904 18.9112C22.243 19.2637 22.7211 19.4618 23.2197 19.4618H45.7773C46.2759 19.4618 46.754 19.2637 47.1066 18.9112C47.4591 18.5586 47.6572 18.0805 47.6572 17.582C47.6572 17.0834 47.4591 16.6053 47.1066 16.2527C46.754 15.9002 46.2759 15.7021 45.7773 15.7021Z" fill="#F9F9F9" />
                    </svg>
                    :
                    <svg className="pf msg-btn" width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...ButtonCss }}>
                        <rect opacity="0.8" width="69" height="69" rx="34.5" fill="#060606" />
                        <path d="M45.2266 33.1712C45.4061 33.3446 45.5493 33.552 45.6478 33.7814C45.7463 34.0107 45.7982 34.2574 45.8004 34.507C45.8025 34.7566 45.755 35.0041 45.6605 35.2351C45.5659 35.4662 45.4264 35.6761 45.2499 35.8526C45.0734 36.0291 44.8635 36.1686 44.6325 36.2632C44.4014 36.3577 44.1539 36.4052 43.9043 36.4031C43.6547 36.4009 43.408 36.349 43.1787 36.2505C42.9493 36.152 42.7419 36.0088 42.5685 35.8293L36.3783 29.639V51.4185C36.3783 51.9171 36.1803 52.3952 35.8277 52.7477C35.4752 53.1003 34.9971 53.2983 34.4985 53.2983C33.9999 53.2983 33.5218 53.1003 33.1693 52.7477C32.8167 52.3952 32.6187 51.9171 32.6187 51.4185V29.639L26.4285 35.8293C26.0739 36.1717 25.5991 36.3611 25.1062 36.3569C24.6133 36.3526 24.1419 36.1549 23.7933 35.8064C23.4448 35.4578 23.2471 34.9863 23.2428 34.4935C23.2385 34.0006 23.428 33.5257 23.7704 33.1712L33.1695 23.7722C33.3441 23.5971 33.5515 23.4582 33.7799 23.3634C34.0083 23.2687 34.2531 23.2199 34.5004 23.2199C34.7476 23.2199 34.9925 23.2687 35.2208 23.3634C35.4492 23.4582 35.6567 23.5971 35.8313 23.7722L45.2266 33.1712ZM45.7773 15.7021H23.2197C22.7211 15.7021 22.243 15.9002 21.8904 16.2527C21.5379 16.6053 21.3398 17.0834 21.3398 17.582C21.3398 18.0805 21.5379 18.5586 21.8904 18.9112C22.243 19.2637 22.7211 19.4618 23.2197 19.4618H45.7773C46.2759 19.4618 46.754 19.2637 47.1066 18.9112C47.4591 18.5586 47.6572 18.0805 47.6572 17.582C47.6572 17.0834 47.4591 16.6053 47.1066 16.2527C46.754 15.9002 46.2759 15.7021 45.7773 15.7021Z" fill="#F9F9F9" />
                    </svg>
            }
        </div>
    )
}
export default SmoothScrollingButton


