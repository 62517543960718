import React from 'react'
import Input from "react-phone-number-input/input";
import { useDispatch } from 'react-redux';
import { postRequest, showMessage } from '../../../config';
import { toggleModal } from '../../../reducers/modalReducer';
import Button from '../Button';
import { updateUser } from '../../../reducers/userReducer';

const VALID_EMAIL_REGX = /^\S+@\S+\.\S+$/
const ProfileForm = ({ children, userData, setVerify, sendCode }) => {

    const dispatch = useDispatch();
    const [firstNameError, setFirstNameError] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState('');
    const [isValid, setIsValid] = React.useState(true);
    const [loading, setLoading] = React.useState(false)

    const saveProfile = async () => {
        //Validate fisrtname and email not to be empty
        if (userData?.firstName == '') { return showMessage("First name can not be empty") }
        else if (userData?.lastName === '') { return showMessage("Last name can not be empty") }
        else if (userData?.email === '') { return showMessage('Email can not be empty') }
        else if (userData?.title === '') { return showMessage('Title can not be empty') }
        else if (!VALID_EMAIL_REGX.test(userData?.email)) { return showMessage('Enter valid email') }
        else if (!isValid) { return showMessage('Enter valid linkedin profile url') }
        setLoading(true)
        const userChanges = {
            firstName: userData['firstName'],
            lastName: userData['lastName'],
            email: userData['email'],
            title: userData['title'],
            phone: userData['phone'],
            linkedIn: userData['linkedIn'],
        };
        const response = await postRequest(`/user/updateProfile`, userChanges);
        dispatch(toggleModal({ open: "" }));
        setLoading(false)
        showMessage(response.message);
    };


    const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/;
    const inputChanged = (e) => {
        const { name, type } = e.target;
        if (name && name.toLowerCase() === "linkedin") {
            setIsValid(linkedInRegex.test(e.target.value));
        }
        const value = type === "checkbox" ? e.target.checked : e.target.value;

        if (name === "firstName") setFirstNameError('');
        if (name === "lastName") setLastNameError('');
        if ((name === "firstName" || name === "lastName") && !/^[a-zA-Z\s]+$/.test(value)) {
            const errorMessage = `${name === "firstName" ? "First name" : "Last name"} should not contain numbers or special characters`;
            if (name === "firstName") setFirstNameError(errorMessage);
            if (name === "lastName") setLastNameError(errorMessage);
        }

        dispatch(updateUser({ ...userData, [name]: value }))
    };

    return (
        <>
            <form autoComplete="off">
                <div className="dg gtcr2-md mb1rem gg1rem">
                    <div className="pr">
                        <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            className="bg1 pl4rem shadow w100 br5px p1rem"
                            value={userData.firstName}
                            onChange={inputChanged}
                        />
                        {firstNameError && <div style={{ color: 'red', fontSize: '0.6rem', marginTop: '4px' }} className="error-message">{firstNameError}</div>}
                        <svg
                            className="center-vertically f3 pa l0 ml1rem w2rem"
                            viewBox="0 0 640 512"
                        >
                            <path
                                d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z"
                                className="fa-secondary"
                            ></path>
                        </svg>
                    </div>
                    <div className="pr">
                        <input
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            className="bg1 pl4rem shadow w100 br5px p1rem"
                            value={userData.lastName}
                            onChange={inputChanged}
                        />
                        {lastNameError && <div style={{ color: 'red', fontSize: '0.6rem', marginTop: '4px' }} className="error-message">
                            {lastNameError}
                        </div>}
                        <svg
                            className="center-vertically f3 pa l0 ml1rem w2rem"
                            viewBox="0 0 640 512"
                        >
                            <path
                                d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z"
                                className="fa-secondary"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div className="pr mb1rem">
                    <input
                        disabled
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="bg1 pl4rem shadow w100 br5px p1rem"
                        value={userData.email}
                        onChange={inputChanged}
                    />
                    <svg
                        className="center-vertically f3 pa l0 ml1rem w2rem"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z"
                            className="fa-secondary"
                        ></path>
                        <path
                            d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z"
                            className="fa-primary"
                        ></path>
                    </svg>
                    {(userData.emailVerified && (
                        <span className="pa r0 center-vertically bg3 c1 h100 px1rem bbrr5px btrr5px df aic">
                            Verified
                        </span>
                    )) || (
                            <span
                                onClick={() => {
                                    setVerify("Email"),
                                        dispatch(toggleModal({ open: "verify" }));
                                }}
                                className="pa r0 center-vertically bg4 c1 h100 px1rem bbrr5px btrr5px df cp aic"
                            >
                                Click to Verify
                            </span>
                        )}
                </div>
                <div className="pr mb1rem">
                    <Input
                        className="bg1 w100 pl4rem shadow br5px p1rem"
                        name="phone"
                        country="GB"
                        international
                        withCountryCallingCode
                        value={userData.phone}
                        onChange={(value) => dispatch(updateUser({ ...userData, phone: value }))}
                        maxLength={16}
                    />
                    <svg
                        className="center-vertically f3 pa l0 ml1rem w2rem"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                    >
                        <path
                            className="fa-primary"
                            d="M352 48V384H32V48C32 21.5 53.5 0 80 0h224C330.5 0 352 21.5 352 48z"
                        />
                        <path
                            className="fa-secondary"
                            d="M32 384v80C32 490.5 53.5 512 80 512h224c26.5 0 48-21.5 48-48V384H32zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1z"
                        />
                    </svg>
                    {(userData.smsEnabled && (
                        <span className="pa r0 center-vertically bg3 c1 h100 px1rem bbrr5px btrr5px df aic">
                            Verified
                        </span>
                    )) || (
                            <span onClick={sendCode} className="pa r0 center-vertically bg4 c1 h100 px1rem bbrr5px btrr5px df cp aic">
                                Click to Verify
                            </span>
                        )}
                </div>
                <div className="pr mb1rem">
                    <input
                        name="linkedIn"
                        type="text"
                        placeholder="LinkedIn"
                        className="bg1 pl4rem shadow w100 br5px p1rem"
                        value={userData.linkedIn}
                        onChange={inputChanged}
                    />
                    <svg
                        className="center-vertically f3 pa l0 ml1rem w2rem"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 310 310"
                    >
                        <g id="XMLID_801_">
                            <path
                                className="fa-primary"
                                id="XMLID_802_"
                                d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
									C77.16,101.969,74.922,99.73,72.16,99.73z"
                            />
                            <path
                                className="fa-secondary"
                                id="XMLID_803_"
                                d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
									c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                            />
                            <path
                                className="fa-primary"
                                id="XMLID_804_"
                                d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
									c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
									c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
									C310,145.43,300.549,94.761,230.454,94.761z"
                            />
                        </g>
                    </svg>
                </div>
                <div className="pr mb1rem">
                    <input
                        id="title"
                        name="title"
                        type="text"
                        placeholder="Job Title"
                        className="bg1 pl4rem shadow w100 br5px p1rem"
                        value={userData.title}
                        onChange={inputChanged}
                    />
                    <svg
                        className="center-vertically f3 pa l0 ml1rem w2rem"
                        viewBox="0 0 640 512"
                    >
                        <path
                            d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z"
                            className="fa-secondary"
                        ></path>
                    </svg>
                </div>

                {children}

                <Button
                    onClick={saveProfile}
                    loading={loading}
                    text="Save"
                    icon={<><svg
                        className="pen ml0-5rem h1rem f1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                    >
                        <path
                            className="fa-primary"
                            d="M215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
                        />
                        <path
                            className="fa-secondary"
                            d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
                        />
                    </svg></>}
                />
            </form>
        </>
    )
}

export default ProfileForm