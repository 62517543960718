import moment from 'moment';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import 'react-circular-progressbar/dist/styles.css';
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { updateUser } from '../../../reducers/userReducer';
import AnimatedPage from "../../../components/AnimatedPage";
import { toggleModal } from "../../../reducers/modalReducer";
import Certification from "../../../components/Certification";
import { setCompany } from "../../../reducers/companyReducer.js";
import { refreshCompanies } from '../../../reducers/companiesReducer';
import { getRequest, postRequest, showMessage } from '../../../config';
import { DASHBOARD, BILLING, COMPANY } from "../../../constants/routes";
import PassFailCertification from "../../../components/PassFailCertification";

export default function CompanySettings({ profileClass, company, getCompany, handleMemberEdit }) {

	const dispatch = useDispatch();

	let user = useSelector(state => state.user);
	let modal = useSelector(state => state.modal);
	let companies = useSelector(state => state.companies);
	let companyId = location.pathname.split("/").slice(-1)[0];

	let [cvData, setCvData] = useState({});
	let [director, setDirector] = useState('');
	let [editInput, setEditInput] = useState('');
	let [updateData, setUpdateData] = useState([]);
	let [updateType, setUpdateType] = useState('');
	let [attachment, setAttachment] = useState({});
	let [companiesGroup, setCompaniesGroup] = useState({}); // { companyId: '', group: '', percentage: '' }
	let [filingReadMore, setFilingReadMore] = useState({});
	let [chargeReadMore, setChargeReadMore] = useState({});
	let [controlReadMore, setControlReadMore] = useState({});
	let [officersReadMore, setOfficersReadMore] = useState({});
	let [auditLogList, setAuditLogList] = useState([]);
	let [permissionLogList, setPermissionLogList] = useState([]);
	let [tabIndex, setTabIndex] = useState('Companies House Core Info');
	let [tabCompanySnapshot, setTabCompanySnapshot] = useState('Filing History');
	let [formData, setFormData] = useState({ linkedIn: '', companyId: '', directorId: '' });
	let permissionNames = {
		D002: "Commercial Activities", D003: "Legal", D004: "Property", D005: "Intellectual Property", D006: "Insurance", D007: "Corporate Structure",
		D008: "State Aid", D009: "Finance", D010: "GDPR", D011: "Shares & Share Options", D012: "Taxation", D013: "Social Media", D014: "Key People", D015: "Human Resources",
		D016: "ESG", D017: "Key Advisors", D018: "It Systems", D019: "Regulatory", D020: "Standards", DEPF005: "Environmental, Social, Governmental", DEPF006: "Warranty Disclosure",
		DEPF007: "Directors  Questionnaire", DEPF004: "Software Development",
	};
	let companyKeys = {
		fiveWords: 'Description', companyDescription: 'Detail Box', vatRegCode: 'VAT Code', ukUTRCode: 'UTR Code', cAddress: 'Address Line', cPostalCod: 'Postal Code', cCountry: 'Country',
		cWebsite: 'Website Url', cLinkedin: 'LinkedIn Url', cFacebook: 'Facebook Url', cInstagram: 'Instgram Url'
	};

	useEffect(() => {
		// getCompanies();
		let isCompanySettings = localStorage.getItem("isCompanySettings");
		if (isCompanySettings == null) {
			dispatch(toggleModal({ open: 'companySettings' }));
			localStorage.setItem('isCompanySettings', true);
		}
	}, []);

	useEffect(() => {
		setFormData((prevFormData) => ({ ...prevFormData, companyId: companyId }));
	}, [companyId]);

	useEffect(() => {
		setFormData((prevFormData) => ({ ...prevFormData, directorId: director }));
	}, [director]);

	const getCompanies = async () => {
		let response = await getRequest(`/user/myProfile`);
		if (response['body']) {
			let { user, companies } = response['body'];
			let groups = companies.filter(({ group }) => group == 'Parent');
			let standard = companies.filter(({ group }) => group != 'Parent');
			companies = groups.concat(standard);

			dispatch(updateUser(user));
			dispatch(refreshCompanies(companies));
		};
	};

	const saveAnswer = async (e) => {
		let update = { ...company, [e.target.name]: e.target.value };
		updateData = { ...updateData, [e.target.name]: e.target.value };
		await setUpdateData(updateData);

		dispatch(setCompany(update));
		const res = await postRequest(`/company/update/${companyId}`, { update: updateData, companyId });
		if (res || res['code'] == 200) setEditInput('');
	};

	const submitDeleteCompany = async (e) => {
		let requestBody = {
			companyId,
			companyName: company?.['cName'],
			creatorId: company?.['creatorId']?.['_id'],
			creatorEmail: company?.['creatorId']?.['email'],
			creatorName: `${company?.['creatorId']?.['firstName']} ${company?.['creatorId']?.['lastName']}`,
		};
		const res = await postRequest(`/company/delete`, requestBody);
		if (res['code'] == 200) {
			window.location.href = DASHBOARD;
			showMessage(res['message']);
		}
	};

	const btnPrev = async (id) => {
		let box = await document.querySelector(id);
		let width = await box.clientWidth;
		box.scrollLeft = box.scrollLeft - width;
	};

	const btnNext = async (id) => {
		let box = await document.querySelector(id);
		let width = await box.clientWidth;
		box.scrollLeft = box.scrollLeft + width;
	};

	const getAuditLogs = async (endpoint) => {
		const res = await postRequest('/log', { endpoint, companyId });
		if (res || res['code'] == 200) {
			await setAuditLogList(res['body']);
			dispatch(toggleModal({ open: 'auditLogs' }));
		}
	};

	// Delete Company Handler
	const handleDeleteCompany = () => {
		if (company?.childrenCompanies) {
			dispatch(toggleModal({ open: 'warningDeleteCompany' }))
		} else if (company?.isParent && companies?.length > 1) {
			dispatch(toggleModal({ open: 'warningDeleteCompany' }))
		} else {
			dispatch(toggleModal({ open: 'companyDelete' }))
		}
	};

	const getSidebarHTML = () => {
		const tabs = ["Companies House Core Info", "Group Info", "Location & Web Site & Socials", "Director's Info", "Direct/Indirect Channel", "Company Stage Of Development", "Company Objectives", "Subscription Model Info", "User Permissions", "Advisor Permissions", "Delete Company"];
		return (
			<div id="sidebar-company-settings" style={{ width: "calc((100% - 4rem) / 5)" }} className="h100vh null w100 bsbb dg gg1rem br5px bg5 ps t0 sidebar-report">
				<div className='df jcc aic fdc p0-5rem'>
					<h3 className="c4 ttu lh1 fs1-25rem mt0-5rem tac">Company Settings</h3>
					<h2 className="c4 ttu lh1 fs1rem mt0-5rem tac">Index</h2>
				</div>
				<hr style={{ border: "none", height: "1px", display: "block", width: "100%", marginTop: "18px", backgroundColor: "#002d63" }} />
				<div className='mah100 mya oya df fdc jcsb'>
					{tabs.map((tab, idx) => {
						return (
							<>{tab == "Delete Company"
								? <button key={idx} onClick={() => handleDeleteCompany()} className={`delete-active w100 df c2 td250 c3-hover px1rem py0-5rem cp bg24`}>
									<span className='fs0-75rem fw500 asc pen uss ass tal'>{tab}</span>
								</button>
								: <button key={idx} onClick={() => { setTabIndex(tab); setUpdateData([]) }} className={`${tab == tabIndex && 'active '} w100 df c2 td250 c3-hover px1rem py0-5rem cp bg24`}>
									<span className='fs0-75rem fw500 asc pen uss ass tal'>{tab}</span>
								</button>
							}
							</>
						)
					})}
				</div>
				{/* <div className="mta cp df jcc py1rem px0-5rem aic bss bw0px btw2px bo6">
					<img className="br100 mr0-5rem w3rem h3rem" src="https://this-person-does-not-exist.com/img/avatar-gen11548c471947e6a4777a2f945b3e6d79.jpg" />
					<span className="fw500 fs1-2rem">Contact Lawyer</span>
				</div> */}
			</div>
		)
	};

	//********** Companies House Core **********//
	const companiesHouseCore = () => {
		const tabs = ["Filing History", "Officers", "Persons with significant control", "Charges", "Registers"];
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getAuditLogs(`/company/update/${companyId}`)} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="br5px p2rem dg gg0-5rem my1rem mb1rem br8px shadow bg5">
					<span className="ttc "><b>Company Status:</b> {company['cStatus'] ? company['cStatus'] : "-"} </span>
					<span className="ttc "><b>Company Number:</b> {company['ukRegNo'] ? company['ukRegNo'] : "-"} </span>
					<span className="ttc "><b>Company Title:</b> {company['cName'] ? company['cName'] : "-"} </span>
					<span className="ttc "><b>Company Date of Creation:</b> {company['doIncorporation'] ? moment(company['doIncorporation']).format('ll') : "-"} </span>
					{/* <span className="ttc "><b>Company Description:</b> {company['fiveWords'] ? company['fiveWords'] : "-"} </span> */}
					<span className="ttc "><b>Company Address:</b> {company['cAddress'] ? company['cAddress'] : "-"} </span>
					<span className="ttc "><b>Accounts:</b> {company?.['cAccounts'] ? <>Next accounts made up to <b>{company?.['cAccounts']?.['next_made_up_to'] && company?.['cAccounts']?.['next_made_up_to']}</b> due by <b>{company?.['cAccounts']?.['next_due'] && company?.['cAccounts']?.['next_due']}</b>, Last accounts made up to <b>{company?.['cAccounts']?.['last_accounts']?.['made_up_to'] && company?.['cAccounts']?.['last_accounts']?.['made_up_to']}</b></> : "-"} </span>
					<span className="ttc "><b>Confirmation statement:</b> {company?.['cStatements'] ? <>Next statement made up to <b>{company['cStatements']?.['next_made_up_to'] && company['cStatements']?.['next_made_up_to']}</b> due by <b>{company['cStatements']?.['next_due'] && company['cStatements']?.['next_due']}</b>, Last statement made up to <b>{company['cStatements']?.['last_made_up_to'] && company['cStatements']?.['last_made_up_to']}</b></> : "-"} </span>
					{company['ukRegNo'] && <div className='df'>
						<Link to={`https://find-and-update.company-information.service.gov.uk/company/${company['ukRegNo']}`} target='_blank' className='w20 cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500 mt1rem mr1rem'>Companies House - Direct</Link>
						<Link to={`https://find-and-update.company-information.service.gov.uk/company/${company['ukRegNo']}/more`} target='_blank' className='w30 cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500 mt1rem'>Companies House - Company Snapshot</Link>
					</div>}
				</div>
				<div className='list-snapshot mb1rem'>
					<h3 className='text-gradient'>Company snapshot</h3>
					<div className='df jcsb aic my1rem mb1rem p1rem br8px shadow bg5'>
						{tabs.map((tab, idx) => {
							return (
								<button key={idx} className={`${tab == tabCompanySnapshot && 'active '} company-snapshot-btn cp`} onClick={() => setTabCompanySnapshot(tab)}>
									<span className='c2 c3-hover fs1rem tac cp'>{tab}</span>
								</button>
							)
						})}
					</div>
					{tabCompanySnapshot == "Filing History"
						? <>{filingHistoryTab()}</>
						: tabCompanySnapshot == "Officers"
							? <>{officersTab()}</>
							: tabCompanySnapshot == "Persons with significant control"
								? <>{personsSignificantTab()}</>
								: tabCompanySnapshot == "Charges"
									? <>{chargesTab()}</>
									: <>{registersTab()}</>
					}
				</div>
				<div className="pr w100 df">
					<div className='df fdc w50'>
						<label className='fs1rem fww fw600'>VAT:</label>
						<input defaultValue={company?.['vatRegCode']} onBlur={saveAnswer} name="vatRegCode" id="vatRegCode" type="text" placeholder="Please add VAT reg number"
							className="shadow bg5 w100 br5px p1rem mt0-5rem mr1rem" />
					</div>
					<div className='df fdc w50'>
						<label className='fs1rem fww fw600'>UTR:</label>
						<input defaultValue={company?.['ukUTRCode']} onBlur={saveAnswer} name="ukUTRCode" id="ukUTRCode" type="text" placeholder="Please add UTR number"
							className="shadow bg5 w100 br5px p1rem mt0-5rem" />
					</div>
				</div>
			</div>
		)
	};

	const filingHistoryTab = () => {
		return (
			<div className='slider-area mt2rem p1rem px1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Filing History</h3>
				<div className="slider-items">
					<div id="filing-history" className="slider-container">
						{company['cFiling'] && company['cFiling'].length > 0
							? <>{company['cFiling'].map((history, idx) => {
								return (<>
									<div key={idx} className="item cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem">
										<div className='avtar-area df jcc'>
											<img className="h2rem" src={require(`../../../../images/filinghistory.png`)} alt="" />
										</div>
										<div className='text-box'>
											<p className='my0-5rem ttc fs0-75rem'><b>Category:</b> {history['category'] ? history['category'] : '-'}</p>
											<p className='my0-5rem ttc fs0-75rem'><b>Description:</b> {history['description'] ? history['description'] : '-'}</p>
											<p className='my0-5rem ttc fs0-75rem'><b>Document:</b> {history['Location'] ? <a href={history['Location']} target='_blank' className='tdu ttu'>View Document</a> : '-'}</p>
										</div>
										<button onClick={() => { setFilingReadMore(history); dispatch(toggleModal({ open: 'filingReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								</>)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cFiling'] && company['cFiling'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#filing-history')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#filing-history')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const officersTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Officers</h3>
				<div className="slider-items">
					<div id="officers-data" className="slider-container">
						{company['cOfficers'] && company['cOfficers'].length > 0
							? <>{company['cOfficers'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Name:</b>  {data['name'] ? data['name'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Role:</b> {data['officer_role'] ? data['officer_role'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Appointed On:</b> {data['appointed_on'] ? data['appointed_on'] : '-'}</p>
											{data['appointment'] && <p className='my0-5rem ttc'><b>{data['appointment']['type']} Filing:</b> <a href={data['appointment']['Location']} target='_blank' className='c3'>View Document</a></p>}
											<p className='my0-5rem ttc'><b>Address:</b> {data['address']
												? <>{data['address']['premises'] && data['address']['premises']}
													{data['address']['address_line_1'] && ', ' + data['address']['address_line_1']}
													{data['address']['address_line_2'] && ', ' + data['address']['address_line_2']}
													{data['address']['locality'] && ' - ' + data['address']['locality']}
													{data['address']['region'] && ', ' + data['address']['region']}
													{data['address']['country'] && ', ' + data['address']['country']}
												</>
												: '-'
											}</p>
										</div>
										<button onClick={() => { setOfficersReadMore(data); dispatch(toggleModal({ open: 'officersReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cOfficers'] && company['cOfficers'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#officers-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#officers-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const personsSignificantTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Persons with Significant Control</h3>
				<div className="slider-items">
					<div id="persons-significant-data" className="slider-container">
						{company['cSignificantControl'] && company['cSignificantControl'].length > 0
							? <>{company['cSignificantControl'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Name:</b>  {data['name'] ? data['name'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Controls:</b>
												{data['natures_of_control'] && data['natures_of_control'].length > 0
													? <>{data['natures_of_control'].map((control, idx) => (
														<ul key={idx}><li>{control}</li></ul>
													))}</>
													: '-'}
											</p>
										</div>
										<button onClick={() => { setControlReadMore(data); dispatch(toggleModal({ open: 'controlReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})
							}
							</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cSignificantControl'] && company['cSignificantControl'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#persons-significant-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#persons-significant-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const chargesTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Charges</h3>
				<div className="slider-items">
					<div id="charges-data" className="slider-container">
						{company['cCharges'] && company['cCharges'].length > 0
							? <>{company['cCharges'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Persons Entitled:</b> {data['persons_entitled'] ? data['persons_entitled'][0]['name'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Charge Description:</b> {data['classification']['description'] ? data['classification']['description'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Status:</b> {data['status'] ? data['status'] : '-'}</p>
										</div>
										<button onClick={() => { setChargeReadMore(data); dispatch(toggleModal({ open: 'chargeReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cCharges'] && company['cCharges'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#charges-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#charges-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const registersTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Registers</h3>
				<div className="slider-items">
					<div id="registers-data" className="slider-container">
						{company['cRegisters'] && company['cRegisters'].length > 0
							? <>{company['cRegisters'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Name:</b>  DE ROOS, Peter Wouter</p>
											<p className='my0-5rem ttc'><b>Occupation:</b> Occupation:</p>
											<p className='my0-5rem ttc'><b>Address:</b> Lidl House, 14 , Kingston Road - Surbiton</p>
										</div>
										<button onClick={() => dispatch(toggleModal({ open: 'officersData' }))} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cRegisters'] && company['cRegisters'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#registers-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#registers-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	//********** Group Info **********// 
	const groupInfo = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr1rem ml1rem">
				<div className='df jcsb mb2rem'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getAuditLogs(`/company/updateChild`)} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div class="df jcfe mb1rem">
					{company['group'] == 'Parent' &&
						<div className='df mr2rem'>
							<span onClick={() => { setUpdateType('Add'); dispatch(toggleModal({ open: 'addGroupCompany' })) }} className={`${companies.length == 0 && 'op20 pen '} shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu w100 bsbb tac mr1rem df jcc aic`}>Add</span>
							<span onClick={() => { setUpdateType('Edit'); dispatch(toggleModal({ open: 'updateGroupCompany' })) }} className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu w100 bsbb tac mr1rem df jcc aic">Edit</span>
							<span onClick={() => { setUpdateType('Delete'); dispatch(toggleModal({ open: 'updateGroupCompany' })) }} className="shadow bg4 bg3-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu w100 bsbb tac mr1rem df jcc aic">Delete</span>
						</div>
					}
				</div>
				{company['parentId'] && company['parentCompany']
					// CHILD WITH PARENT COMPANY
					? <div className='dropdown-company-dahboard'>
						{showCompanyDetails(company['parentCompany'])}
						<div className="dropdown-content" style={{ display: "block", top: '0px' }}>
							{showCompanyDetails(company)}
						</div>
					</div>
					// PARENT WITH CHILD COMPANY
					: <div className='dropdown-company-dahboard'>
						{showCompanyDetails(company)}
						{company['childrenCompanies'] && company['childrenCompanies'].length > 0 && <>
							<div className="dropdown-content" style={{ display: "block", top: '0px' }}>
								{company['childrenCompanies'] && company['childrenCompanies'].map((childrenCompany, idx) => {
									return <>
										{showCompanyDetails(childrenCompany)}
									</>
								})}
							</div>
						</>}
					</div>
				}
			</div>
		)
	};

	const showCompanyDetails = (company) => {
		return (
			<div className="dg gtcr6-md gg1rem p1rem br20px shadow bg1 fs1rem mb1rem ">
				<div className='df jcse'>
					<h4 className='fs1rem c6 w10rem'>{company['cName']}</h4>
					<span className='ml1rem'>
						{company['subscription']
							? <h2 className="fs1rem c7 mra tal">{company['subscription']?.toUpperCase()}</h2>
							: <h2 className="fs1rem c8 mra tal">INACTIVE</h2>
						}
					</span>
				</div>
				<div className='df jcse'>
					<span className='df username-area ml1rem'>
						<div className="df fdc mr1rem">
							<span className="ass mb0-5rem fs0-75rem">Type</span>
							<span onClick={() => dispatch(toggleModal({ open: 'userDetail' }))} data-tooltip={(company['group'] && company['group'] !== "Standard") ? company['group'] : 'Not Connected'} className="w2rem h2rem df jcc aic br100 bg4 c1">
								{(company['group'] && company['group'] !== "Standard") ? company['group'][0] : 'N/A'}
							</span>
						</div>
					</span>
					<span className='df username-area'>
						<div className="df fdc">
							<span className="ass mb0-5rem fs0-75rem">Owner</span>
							<span onClick={() => dispatch(toggleModal({ open: 'userDetail' }))} data-tooltip={user.firstName ? (user.firstName + " " + user.lastName) : "Register to show your name here"} className="w2rem h2rem df jcc aic br100 bg3 c1 mr0-5rem">{user.firstName ? (user.firstName[0] + user.lastName[0]) : "?"}</span>
						</div>
					</span>
				</div>
				<Link to={COMPANY.replace(":id", company['_id'])} className={`${!company.subscription && 'op20 pen '} tac c1 bg3 br5px shadow px0-25rem py0-5rem fs0-75rem fw500`}>Dashboard</Link>
				{company['subscription']
					? <Link to={BILLING.replace(":id", company['_id'])} className={`${user['email'] == 'temp' && 'op20 pen '} tac c1 bg3 br5px shadow px1rem py0-5rem fw500 fs0-75rem`}>Upgrade</Link>
					: <Link data-testid="subscribeNow" to={BILLING.replace(":id", company['_id'])} className="tac c1 bg3 br5px shadow px1rem py0-5rem fw500 fs0-75rem">Subscribe Now</Link>
				}
				<div className='progress-area progress-dashboard' style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
					<div className='progress-box'>
						<div className="progress-bar" style={{ width: 50, height: 50 }}>
							<CircularProgressbar
								className={`${company.stats.completionPercent > 67 ? 'green'
									: company.stats.completionPercent > 33 ? 'orange'
										: 'red'}`}
								value={company.stats.completionPercent || 0}
								text={(company.stats.completionPercent || 0) + "%"}
								styles={buildStyles({
									strokeLinecap: 'round',
									strokeWidth: '5px',
									textSize: '20px',
									pathTransitionDuration: 0.5,
									trailColor: '#d6d6d6',
									backgroundColor: '#fc7785',
									dominantBaseline: 'ideographic',
								})}
							/>
						</div>
						<span style={{ marginBottom: '0', fontSize: '11px' }} className="text-center-area">Completeness</span>
					</div>
					<div className='progress-box'>
						<div className="progress-bar" style={{ width: 50, height: 50 }}>
							<CircularProgressbar
								className={`${company.stats.riskPercent > 67 ? 'red'
									: company.stats.riskPercent > 33 ? 'orange'
										: 'green'}`}
								value={company.stats.riskPercent || 0}
								text={company.stats.completionPercent ? `${(company.stats.riskPercent || 0)}%` : "0%"}
								styles={buildStyles({
									strokeLinecap: 'butt',
									strokeWidth: '5px',
									textSize: '20px',
									pathTransitionDuration: 0.5,
									trailColor: '#d6d6d6',
									backgroundColor: '#fc7785',
									dominantBaseline: 'ideographic',
								})}
							/>
						</div>
						<span style={{ marginBottom: '0', fontSize: '11px' }} className="text-center-area">Risk</span>
					</div>
				</div>
				<div className='certificate-area-dashboard df jcc'>
					{(company['cLevel']) && (company['subscription'] == "Pro" || company['subscription'] == "Pro Plus") &&
						<>{company['subscription'] == "Free" || user['role'] == 'Guest'
							? <PassFailCertification className={'op70'} testType={company['testType']} level={company?.moduleCertification?.['two-minute-test-' + company['testType']]} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
							: <Certification className={'op70'} component='CompanySettings' level={company['subscription'] == "Lite" ? 'None' : company['cLevel']} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
						}</>
					}
				</div>
			</div>
		)
	}

	const handleChildCompany = async (el) => {
		if (!companiesGroup[el['companyId']]) companiesGroup[el['companyId']] = { companyId: el['companyId'] };
		if (el['selected']) companiesGroup[el['companyId']]['selected'] = el['selected'];
		if (el['group']) {
			document.getElementById(`group-${el['companyId']}`).value = el['group'];
			companiesGroup[el['companyId']]['group'] = el['group'];
		}
		if (el['percentage']) {
			document.getElementById(`percentage-${el['companyId']}`).value = el['percentage'];
			companiesGroup[el['companyId']]['percentage'] = el['percentage'];
		}
		if (el['event']?.['target']?.['checked'] == false) delete companiesGroup[el['companyId']];
		setCompaniesGroup(companiesGroup);
	};

	const updateGroupCompany = async () => {
		let childUpdates = await Object.values(companiesGroup).filter(company => {
			if (updateType == "Delete") company['group'] = 'Standard';
			if (!company['group'] && updateType == "Add") company['group'] = 'Subsidiary';
			if (!company['percentage'] && updateType == "Add") company['percentage'] = '0';
			if (company['selected'] == 'true') return company;
		});

		let data = { parentId: company['_id'], childUpdates, type: updateType }
		if (data) {
			const res = await postRequest(`/company/updateChild`, { data, companyId });
			if (res || res['code'] == 200) {
				getCompany();
				getCompanies();
				setCompaniesGroup({});
				dispatch(toggleModal({ open: '' }));
				showMessage(res['message']);
			}
		}
		else showMessage("Company is not identified");
	};

	//********** Location Website **********// 
	const locationWebsite = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getAuditLogs(`/company/update/${companyId}`)} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr my2rem px2rem py2rem br8px shadow bg5">
					<div className='group-inputs'>
						{editInput == 'cAddress'
							? <p className='df tac p0rem'>
								<input onBlur={saveAnswer} defaultValue={company?.['cAddress']} name="cAddress" type="text" placeholder="Enter Address Line" className="c2" />
							</p>
							: <p><b>Address Line:</b> {company?.['cAddress'] ? company['cAddress'] : '-'}
								<span className='edit-btn'>
									<button onClick={() => setEditInput('cAddress')} className='edit-pencil'>
										<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
											<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
										</svg>
									</button>
								</span>
							</p>
						}
					</div>
					<div className='df'>
						<div className='group-inputs mr3rem'>
							{editInput == 'cPostalCode'
								? <p className='df tac p0rem'>
									<input onBlur={saveAnswer} defaultValue={company['cPostalCode']} name="cPostalCode" type="text" placeholder="Enter Post Code" className="c2" />								</p>
								: <p><b>Post Code:</b> {company['cPostalCode'] ? company['cPostalCode'] : '-'}
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cPostalCode')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</p>
							}
						</div>
						<div className='group-inputs '>
							{editInput == 'cCountry'
								? <p className='df tac p0rem'>
									<input onBlur={saveAnswer} defaultValue={company['cCountry']} name="cCountry" type="text" placeholder="Enter Country" className="c2" />
								</p>
								: <p><b>Country:</b> {company['cCountry'] ? company['cCountry'] : '-'}
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cCountry')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</p>
							}
						</div>
					</div>
					<div className='group-inputs fs0-75rem'>
						{editInput == 'companyDescription'
							? <p className='df tac p0rem'>
								<textarea onBlur={saveAnswer} defaultValue={company['companyDescription']} name="companyDescription" style={{ height: '250px' }} className="c2 w100" placeholder="Detail Box...."></textarea>
							</p>
							: <p className="fs0-75rem"><b>Detail Box:</b> {company['companyDescription'] ? company['companyDescription'] : '-'}
								<span className='edit-btn'>
									<button onClick={() => setEditInput('companyDescription')} className='edit-pencil'>
										<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
											<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
										</svg>
									</button>
								</span>
							</p>
						}
					</div>
					<div className='df mt0-5rem links-area-social'>
						<div className='group-inputs link-group mr3rem'>
							{editInput == 'cWebsite'
								? <p className='df tac p0rem'>
									<input onBlur={saveAnswer} defaultValue={company['cWebsite']} name="cWebsite" type="url" placeholder="Enter Website URL" className="c2" />
								</p>
								: <div className='edit-text'>
									<p><b>Website Url :</b> {company['cWebsite'] ? <a href={company['cWebsite']} target='_blank'>{company['cWebsite']}</a> : '-'}</p>
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cWebsite')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</div>
							}
						</div>
						<div className='group-inputs link-group mr3rem'>
							{editInput == "cLinkedin"
								? <p className='df tac p0rem'>
									<input onBlur={saveAnswer} defaultValue={company['cLinkedin']} name="cLinkedin" type="url" placeholder="Enter LinkedIn URL" className="c2" />
								</p>
								: <div className='edit-text'>
									<p><b>LinkedIn Url :</b> {company['cLinkedin'] ? <a href={company['cLinkedin']} target='_blank'>{company['cLinkedin']}</a> : '-'}</p>
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cLinkedin')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</div>
							}
						</div>
						<div className='group-inputs link-group'>
							{editInput == "cFacebook"
								? <p className='df tac p0rem'>
									<input onBlur={saveAnswer} defaultValue={company['cFacebook']} name="cFacebook" type="url" placeholder="Enter Facebook URL" className="c2" />
								</p>
								: <div className='edit-text'>
									<p><b>Facebook Url :</b> {company['cFacebook'] ? <a href={company['cFacebook']} target='_blank'>{company['cFacebook']}</a> : '-'}</p>
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cFacebook')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</div>
							}
						</div>
					</div>
					<div className='group-inputs link-group'>
						{editInput == "cInstagram"
							? <p className='df tac p0rem'>
								<input onBlur={saveAnswer} defaultValue={company['cInstagram']} name="cInstagram" type="url" placeholder="Enter Instagram URL" className="c2" />
							</p>
							: <div className='edit-text'>
								<p><b>Insta Url :</b> {company['cInstagram'] ? <a href={company['cInstagram']} target='_blank'>{company['cInstagram']}</a> : '-'}</p>
								<span className='edit-btn'>
									<button onClick={() => setEditInput('cInstagram')} className='edit-pencil'>
										<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
											<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
										</svg>
									</button>
								</span>
							</div>
						}
					</div>
				</div>
			</div>
		)
	};

	//********** Direct Info **********// 
	const directorsInfo = () => {
		console.log("*********company::", company);
		return (
			<div className="sectionWrapper p1rem fs1rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getAuditLogs('/question/saveCV')} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr mt2rem df fdr fww">
					{company['directors'] && company['directors'].length > 0
						? <>{company['directors'].map((director, idx) => {
							return (
								<div key={idx} className='directors-info-box df fdc jcc w40 p1rem br8px shadow bg5 mb1rem mt1rem mr2rem'>
									<div className='text-box'>
										<div className='group-inputs df jcc fs1rem'>
											{director['headShot'] && <img src={director['headShot']['location']} className="w4rem h4rem df jcc aic br100 bg3 c1 mr0-5rem mb1rem" />}
										</div>
										<div className='group-inputs fs1rem'>
											<p><b>Name:</b> {director['name'] ? director['name'] : '-'}</p>
										</div>
										<div className='group-inputs fs1rem'>
											<p><b>Role:</b> {director['role'] ? director['role'] : '-'}</p>
										</div>
										<div className='group-inputs fs1rem'>
											<p className='fs1rem'><b>Designation:</b> {director['designation'] ? director['designation'] : '-'}</p>
										</div>
										<div className='group-inputs fs1rem'>
											{/* {Object.keys(company.modules).map(module => {
												if (module === `directors-questionnaire_${director['_id']}`) {
													return company?.modules[module]?.sections[0]?.subFolders[2]?.questions[0]?.answer?.files?.map(file => (
														<><span className="df"><b>CV:</b>
															<div className='df jcc br25px lh1 bg3 c1 px0-5rem ml0-5rem'>
																<a target="_blank" href={file?.location}><span>{file?.originalname}</span></a>
																<svg onClick={(e) => { setAttachment(file); setDirector(director); dispatch(toggleModal({ open: 'deleteFiles' })) }} className="p0-5rem f9 w1rem h1rem cp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
																	<path class="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
																</svg>
															</div></span>
															<p class="fs0-75rem">Stored at (DEPF007.2 - OCCUPATIONAL)</p>
														</>
													))
												}
											})} */}
											{director['cv'] && <><span className="df"><b>CV:</b>
												<div className='df jcc br25px lh1 bg3 c1 px0-5rem ml0-5rem'>
													<a target="_blank" href={director['cv']['location']}><span>{director['cv']['originalname']}</span></a>
													<svg onClick={(e) => { setAttachment(director['cv']); setDirector(director); dispatch(toggleModal({ open: 'deleteFiles' })) }} className="p0-5rem f9 w1rem h1rem cp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
														<path class="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
													</svg>
												</div></span>
												<p class="fs0-75rem">Stored at (DEPF007.2 - OCCUPATIONAL)</p>
											</>
											}
										</div>
										<div className='group-inputs fs1rem'>
											{director['linkedIn'] && director['linkedIn'] !== 'undefined' &&
												<p className='df fw500'><b>LinkedIn:</b><a className="fw400 ml0-5rem fs1rem tdu" target="_blank" href={director['linkedIn']}>{director['linkedIn']}</a></p>
											}
										</div>
									</div>
									<button onClick={() => { showUploadPopup(director); dispatch(toggleModal({ open: 'uploadCvData' })) }} className='bsbb jcc w60 df aic shadow fw500 py0-5rem px1rem c1 bg3 br5px mr1rem-xl mt1rem cp'>
										<i className='icon'><img className="" src={require(`../../../../images/upload-icon.png`)} alt="" /></i>Upload
									</button>
								</div>
							)
						})
						}</>
						: <h2 className="w100 mt2rem tac fs1-25rem">This Company Has No Director!</h2>
					}
				</div>
			</div >
		)
	};

	const handleCvChange = async (event) => {
		if (event.target.files) {
			const newAttachment = [];
			for (const file of event.target.files) {
				newAttachment.push(file);
			}
			setAttachment(newAttachment);

			const updatedCvData = { ...cvData };
			updatedCvData[director['_id']] = newAttachment[0];
			setCvData(updatedCvData);
		}
	};

	const showUploadPopup = (director) => {
		setDirector(director['_id']);
		const matchingDirector = company?.['directors'].find(d => d['_id'] === director['_id']);
		const linkedInValue = (matchingDirector && matchingDirector['linkedIn'] !== 'undefined') ? matchingDirector['linkedIn'] : null;

		setFormData({ ...formData, linkedIn: linkedInValue });
		setDirector(director)
	};

	const handleSubmit = async () => {
		let data;
		if (attachment) data = { attachment, ...formData };
		else data = { ...formData };
		let res = await postRequest('/question/saveCV', data);
		if (res && res['code'] == 200) {
			const { updatedQuestion } = res['body'];
			const updatedCompanyDirectors = company.directors.map(director => {
				if (director['_id'] == updatedQuestion['directorId']) return { ...director, cv: updatedQuestion['files'][0] };
				return director;
			});
			const updatedCompany = { ...company, directors: updatedCompanyDirectors };
			dispatch(setCompany({ ...company, updatedCompany }));

			await getCompany();
			closeDirectorPopup();
			showMessage(res['message']);
		}
	};

	const closeDirectorPopup = () => {
		dispatch(toggleModal({ open: '' }));
		setFormData({ ...formData, linkedIn: '' });
	};

	const confirmDeleteFiles = async () => {
		let questionId = "64bf7e6bacdfbb8c415c2cb4";
		let index = company?.directors.findIndex(el => el['_id'] == director['_id']);
		const directorId = director['_id'];
		const res = await postRequest('/repository/deleteFile', { file: attachment, questionId, companyId, directorId });
		if (res && res['code'] == 200) {
			Object.keys(company.modules).map(module => {
				if (module === `directors-questionnaire_${director['_id']}`) {
					dispatch(setCompany(company => {
						const updatedCompany = { ...company };
						const moduleObj = updatedCompany.modules[module];
						if (moduleObj && moduleObj.sections[0]?.subFolders[2]?.questions[0]?.answer?.files) {
							moduleObj.sections[0].subFolders[2].questions[0].answer.files = moduleObj.sections[0].subFolders[2].questions[0].answer.files?.filter(file => {
								return file !== attachment;
							});
						}
						return updatedCompany;
					}));
				}
			})

			const updatedCvData = { ...cvData };
			if (updatedCvData.hasOwnProperty(directorId)) {
				delete updatedCvData[directorId];
			}
			setCvData(updatedCvData);
			await getCompany();
			dispatch(toggleModal({ open: '' }));
			showMessage(res.message);
		}
	};

	//********** Direct Indirect Channel **********// 
	const directIndirectChannel = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => dispatch(toggleModal({ open: 'companyAuditLog' }))} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr mt2rem">
					<div className='type-customer'>
						<p><b>Type of customer:</b></p>
						<div className='pr pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'>

							<label for="directWeb" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox active w2rem h2rem pr">
									<input defaultChecked={company['customerType'] && company['customerType'] == 'Direct' ? true : false} name="customerTypeDirect" id="customerTypeDirect" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Direct (from the web)?</span>
							</label>

							<label for="advisorPartner" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['customerType'] && company['customerType'] == 'Indirect' ? true : false} name="customerTypeAdvisor" id="customerTypeAdvisor" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Introduced via a advisor partner?</span>
							</label>

							<label for="Hybrid" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['customerType'] && company['customerType'] == 'Hybrid' ? true : false} name="customerTypeHybrid" id="customerTypeHybrid" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Hybrid</span>
							</label>
							<span className="tooltip-area-company-settings pa cp" data-tooltip={"This is the type of customer you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"}>
								<i className="icon" data-tooltip={"This is the type of customer you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"}>
									<img data-tooltip={"This is the type of customer you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"} src={require(`../../../../images/info-icon.svg`)} alt="" />
								</i>
							</span>
						</div>
					</div>
					<div className='type-customer mt2rem'>
						<p><b>Payment Source:</b></p>
						<div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'>
							<label for="directWebpayment" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['paymentSource'] && company['paymentSource'] == 'Direct' ? true : false} name="paymentSourceDirect" id="paymentSourceDirect" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Direct (from the web)?</span>
							</label>

							<label for="advisorPartnerpayment" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['paymentSource'] && company['paymentSource'] == 'Paid' ? true : false} name="paymentSourcePaid" id="paymentSourcePaid" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Paid via a advisor partner?</span>
							</label>

							<label for="Other" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['paymentSource'] && company['paymentSource'] == 'Other' ? true : false} name="paymentSourceOther" id="paymentSourceOther" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Other</span>
							</label>
							<span className="tooltip-area-company-settings pa cp" data-tooltip={"This is the payment source you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"}>
								<i className="icon" data-tooltip={"This is the payment Source you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"}>
									<img data-tooltip={"This is the payment Source you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"} src={require(`../../../../images/info-icon.svg`)} alt="" />
								</i>
							</span>
						</div>
					</div>
				</div>
			</div>
		)
	};

	//********** Company Stage of Development **********// 
	const companyStageofDevelopment = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => dispatch(toggleModal({ open: 'companyAuditLog' }))} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr mt2rem">
					<div className='type-customer'>
						<p><b>Stage of development:</b></p>
						<div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'>
							<label for="Pre-Revenue" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['cStage'] && company['cStage'] == 'Pre-Revenue' ? true : false} name="preRevenue" id="preRevenue" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100 fs0-75rem lh3 ml0-5rem usn ml1rem">Pre-Revenue</span>
							</label>
							<label for="Post-Revenue Early Stage" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['cStage'] && company['cStage'] == 'Post-Revenue Early Stage' ? true : false} name="postRevenueEarlyStage" id="postRevenueEarlyStage" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100 fs0-75rem lh3 ml0-5rem usn ml1rem">Post-Revenue Early Stage</span>
							</label>
							<label for="Mature" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['cStage'] && company['cStage'] == 'Mature' ? true : false} name="mature" id="mature" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Mature</span>
							</label>
							<span className="tooltip-area-company-settings pa cp" data-tooltip={"This is the stage of development you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"}>
								<i className="icon" data-tooltip={"This is the stage of development you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"}>
									<img data-tooltip={"This is the stage of development you selected when<br /> you registered this company. It is not capable<br /> of being edited on this MVP version of the platform"} src={require(`../../../../images/info-icon.svg`)} alt="" />
								</i>
							</span>
						</div>
						{company['cStage'] && company['cStage'] == 'Pre-Revenue' && <div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'><span className='c6'>Company stage {company['cStage']} means that we tweak our AI algoirthms to focus on prerevenue issues your business is likely to face.</span></div>}
						{company['cStage'] && company['cStage'] == 'Post-Revenue Early Stage' && <div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'><span className='c6'>Company stage {company['cStage']} means that we tweak our AI algoirthms to focus on issues that a company at your stage of development is likely to face.</span></div>}
						{company['cStage'] && company['cStage'] == 'Mature' && <div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'><span className='c6'>Company stage {company['cStage']} means that we tweak our AI algoirthms to focus on the issues faced by a more mature business.</span></div>}
					</div>
				</div>
			</div>
		)
	};

	//********** Company Objectives **********// 
	const companyObjectives = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => dispatch(toggleModal({ open: 'companyAuditLog' }))} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr mt2rem">
					<div className='type-customer'>
						<p><b>Company Objectives:</b></p>
						<div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'>
							<label for="Lookingforinvestment" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['testType'] && company['testType'] == 'investment' ? true : false} name="investment" id="investment" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100 lh3 ml0-5rem usn ml1rem">Looking for investment</span>
							</label>

							<label for="Accelerategrowth" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['testType'] && company['testType'] == 'growth' ? true : false} name="growth" id="growth" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100 lh3 ml0-5rem usn ml1rem">Accelerate growth</span>
							</label>

							<label for="Lookingtoexit" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input defaultChecked={company['testType'] && company['testType'] == 'exit' ? true : false} name="exit" id="exit" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
								</span>
								<span className="w100 lh3 ml0-5rem usn ml1rem">Looking to exit</span>
							</label>
							<span className="tooltip-area-company-settings pa cp" data-tooltip={"This is the objective you have selected for this<br /> company on the Diligentsia platform – questions and<br /> answers will be tailored to this objective"}>
								<i className="icon" data-tooltip={"This is the objective you have selected for this<br /> company on the Diligentsia platform – questions and<br /> answers will be tailored to this objective"}>
									<img data-tooltip={"This is the objective you have selected for this<br /> company on the Diligentsia platform – questions and<br /> answers will be tailored to this objective"} src={require(`../../../../images/info-icon.svg`)} alt="" />
								</i>
							</span>
						</div>
						{company['testType'] && company['testType'] == 'investment' && <div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'><span className='c6'>Company type {company['testType']} means that we tweak our AI algoirthms to focus on preparing your business to make it more investment ready.</span></div>}
						{company['testType'] && company['testType'] == 'growth' && <div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'><span className='c6'>Company type {company['testType']} means that we tweak our AI algoirthms to  focus on preparing your business to turbocharge your growth.</span></div>}
						{company['testType'] && company['testType'] == 'exit' && <div className='pr df jcsb w60 p1rem br8px shadow bg1 mb1rem mt1rem'><span className='c6'>Company type {company['testType']} means that we tweak our AI algoirthms to focus on preparing your business to make it more exit ready.</span></div>}
					</div>
				</div>
			</div>
		)
	};

	//********** Subsription Model Info **********// 
	const subscriptionModelInfo = () => {
		return (
			<div className="sectionWrapper p1rem fs1rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => dispatch(toggleModal({ open: 'companyAuditLog' }))} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr mt2rem">
					<h3>{company['cName']} Subscriptions:</h3>
					<div className='type-customer mt1rem'>
						<p><b>Core Modules:</b></p>
						<div className='pr df jcsb w60 p1rem pb2rem br8px shadow bg1 my1rem'>
							<label for="DevelopmentPartner" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input disabled={company['subscription'] && company['subscription'] == 'Free' ? false : true} defaultChecked={company['subscription'] && company['subscription'] == 'Free' ? true : false} name="free" id="free" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" style={{ cursor: company['subscription'] && company['subscription'] == 'Free' ? 'pointer' : 'not-allowed' }} />
								</span>
								<span className="w100 fs0-75rem lh3 ml0-5rem usn ml1rem">Free</span>
							</label>
							<label for="Lite" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input disabled={company['subscription'] && company['subscription'] == 'Lite' ? false : true} defaultChecked={company['subscription'] && company['subscription'] == 'Lite' ? true : false} name="lite" id="lite" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" style={{ cursor: company['subscription'] && company['subscription'] == 'Lite' ? 'pointer' : 'not-allowed' }} />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Lite</span>
							</label>
							<label for="Pro" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input disabled={company['subscription'] && company['subscription'] == 'Pro' ? false : true} defaultChecked={company['subscription'] && company['subscription'] == 'Pro' ? true : false} name="pro" id="pro" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" style={{ cursor: company['subscription'] && company['subscription'] == 'Pro' ? 'pointer' : 'not-allowed' }} />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Pro</span>
							</label>
							<label for="ProPlus" className="py0-5rem df cp mb0-5rem label-checkbox">
								<span className="checkbox w2rem h2rem pr">
									<input disabled={company['subscription'] && company['subscription'] == 'Pro Plus' ? false : true} defaultChecked={company['subscription'] && company['subscription'] == 'Pro Plus' ? true : false} name="proPlus" id="proPlus" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" style={{ cursor: company['subscription'] && company['subscription'] == 'Pro Plus' ? 'pointer' : 'not-allowed' }} />
								</span>
								<span className="w100  lh3 ml0-5rem usn ml1rem">Pro PLUS</span>
							</label>
							{company['createdAt'] &&
								<span className="pa fs0-75rem fw600" style={{ top: '70px', left: '230px' }}>
									Renewal Date: {moment(new Date(company['createdAt']).setFullYear(new Date(company['createdAt']).getFullYear() + 1)).format('DD/MMM/YYYY')}
								</span>
							}
							<span className="tooltip-area-company-settings pa cp" data-tooltip={"This lists the SaaS modules you have subscribed<br /> to for this company – you can change this by upgrading<br /> from your company dashboard"}>
								<i className="icon" data-tooltip={"This lists the SaaS modules you have subscribed<br /> to for this company – you can change this by upgrading<br /> from your company dashboard"}>
									<img data-tooltip={"This lists the SaaS modules you have subscribed<br /> to for this company – you can change this by upgrading<br /> from your company dashboard"} src={require(`../../../../images/info-icon.svg`)} alt="" />
								</i>
							</span>
						</div>
					</div>
					<div className='type-customer mt1rem'>
						<p><b>Expansion Packs:</b></p>
						<div className='w100 p1rem br8px shadow bg1 mb1rem mt1rem'>
							<ul className='fs0-75rem'>
								{company['expensions'] && Object.keys(company['expensions']).length > 0
									? <>
										{company['expensions']['DEPN004'] && <li><span className='fw500'>DEPN004</span> – Industry Expansion Pack – Software Development{isNaN(company['expensions']['DEPN004']) && <span className='fw600'> - (Renewal Date: {moment(new Date(company['expensions']['DEPN004']).setFullYear(new Date(company['expensions']['DEPN004']).getFullYear() + 1)).format('DD/MMM/YYYY')})</span>}</li>}
										{company['expensions']['DEPF005'] && <li><span className='fw500'>DEPF005</span> – Function Expansion Pack - Environmental Social & Governmental (ESG) {isNaN(company['expensions']['DEPF005']) && <span className='fw600'> - (Renewal Date: {moment(new Date(company['expensions']['DEPF005']).setFullYear(new Date(company['expensions']['DEPF005']).getFullYear() + 1)).format('DD/MMM/YYYY')}) </span>}</li>}
										{company['expensions']['DEPF006'] && <li><span className='fw500'>DEPF006</span> – Function Expansion Pack – Warranty Disclosure Schedule {isNaN(company['expensions']['DEPF006']) && <span className='fw600'> - (Renewal Date: {moment(new Date(company['expensions']['DEPF006']).setFullYear(new Date(company['expensions']['DEPF006']).getFullYear() + 1)).format('DD/MMM/YYYY')}) </span>}</li>}
										{company['expensions']['DEPF007'] && <li><span className='fw500'>DEPF007</span> – Expansion Pack – DD – Directors Questionnaire {isNaN(company['expensions']['DEPF007']) && <span className='fw600'> - (Renewal Date: {moment(new Date(company['expensions']['DEPF007']).setFullYear(new Date(company['expensions']['DEPF007']).getFullYear() + 1)).format('DD/MMM/YYYY')}) </span>}</li>}
									</>
									: <h2 className="w100 my2rem tac fs1rem">This company has no expansion pack subscriptions at this time</h2>
								}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	};

	//********** User Permissions **********// 
	const userPermissions = () => {
		return (
			<div class="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem report-page">
				<div className='df jcc'>
					<button disabled={user['role'] == 'Advisor' || user['role'] == 'Investor'} class={`shadow c1 td250 br5px px1rem py0-5rem mx1rem fw600 cp ttu bsbb tac df jcc aic ${(user['role'] == 'Advisor' || user['role'] == 'Investor') ? 'bg12' : 'bg3 bg4-hover'}`} onClick={() => dispatch(toggleModal({ open: 'editUser' }))}>
						Add New Member
					</button>
					{company['members'].length > 0 && <>
						<button class={`bg3 bg4-hover shadow c1 td250 br5px px1rem py0-5rem mx1rem fw600 cp ttu bsbb tac df jcc aic`} onClick={() => dispatch(toggleModal({ open: 'userList' }))}>
							All Members
						</button>
						<button class={`bg3 bg4-hover shadow c1 td250 br5px px1rem py0-5rem mx1rem fw600 cp ttu bsbb tac df jcc aic`} onClick={() => getPermissionsLogs()}>
							Audit Log
						</button>
					</>}
				</div>
				{company['members'] && company['members'].length == 0
					? <h1 className='tac mt2rem'>No Members Created!</h1>
					: <div className='table-permission mt2rem'>
						<table id="table" className="table-userPermissions">
							<thead className="ps t0">
								<tr>
									<th className='index-table'>Modules</th>
									{company['creatorId'] &&
										<th className='user-area'>
											<div class="df jcc aic">
												<div className='mr1rem'>
													{company['creatorId']?.['profile']
														? <img data-tooltip={company['creatorId']?.firstName + " " + company['creatorId']?.lastName} src={company['creatorId']?.['profile']} class={`w2rem h2rem df jcc aic br100 ${profileClass}`} />
														: <span data-tooltip={company['creatorId']?.firstName + " " + company['creatorId']?.lastName} class={`w2rem h2rem df jcc aic br100 ${profileClass}`}>{company['creatorId']?.firstName ? (company['creatorId']?.firstName[0] + company['creatorId']?.lastName[0]) : "?"}</span>
													}
												</div>
												<span>{company['creatorId']['firstName']} {company['creatorId']['lastName']} <br /> (Owner)</span>
											</div>
											<div className='df jcc fdc'>
												<p class='fs0-75rem ml3rem'>Status: <span className='c7 mr0-5rem'>Activated </span></p>
												{company['creatorId']?.['lastLogin'] && <p class='fs0-75rem ml3rem'> Last Login: <span className={((new Date().getTime() - new Date(company['creatorId']['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(company['creatorId']['lastLogin']).format('ll')}</span></p>}
											</div>
										</th>
									}
									{company['members'] && company['members'].map(member => {
										return (
											<th className='user-area'>
												<div class="df jcc aic cp" onClick={() => handleMemberEdit(member)}>
													<div className='mr1rem'>
														{member?.profile
															? <img data-tooltip={member?.firstName + " " + member?.lastName} src={member?.profile} class="w2rem h2rem df jcc aic br100 bg4 c1" />
															: <span data-tooltip={member?.firstName + " " + member?.lastName} class="w2rem h2rem df jcc aic br100 bg4 c1">{member?.firstName ? (member?.firstName[0] + member?.lastName[0]) : "?"}</span>
														}
													</div>
													<span>{member['firstName']} {member['lastName']} <br /> ({member['designation']})</span>
												</div>
												<div className='df jcc fdc fs0-75rem ml3rem'>
													<p>Status: <span className={`${member['emailVerified'] ? ' c7' : ' c8'} mr0-5rem`}>{member['emailVerified'] ? 'Activated' : <span>Invite Sent - <br /> ({moment(member['updatedAt']).format('ll')})</span>}</span></p>
													{member['emailVerified'] && <p> Last Login: <span className={((new Date().getTime() - new Date(member['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(member['lastLogin']).format('ll')}</span></p>}
												</div>
											</th>
										)
									})}
								</tr>
							</thead>
							<tbody>
								{company['sections'] && company['sections'].map((section, idx) => {
									return <tr>
										{/* Map Sections */}
										<td className='c6' key={idx}>
											<span className='fw600'>{section['sectionNo']}</span> {section['sectionName']}
										</td>
										{/* Map Owner Permission */}
										<td className='c6 cp' key={idx}>
											<label for={`owner-permission-${idx}`} class="df jcc cp fs0-75rem">
												<span className='checkbox w2rem h2rem pr'>
													<input disabled={true} defaultChecked={true} id={`permission-${idx}`} class="shadow bw2px bss cp td250 w2rem h2rem br5px" type="checkbox" style={{ backgroundColor: '#8f8f8f', borderColor: '#8f8f8f' }} />
												</span>
											</label>
										</td>
										{/* Map Members Permission of that section */}
										{company['members'] && company['members'].map((member, idx) => {
											return (
												<td className='c6 cp' key={idx}>
													<label for={`member-permission-${idx}`} class="df jcc cp fs0-75rem">
														<span className='checkbox w2rem h2rem pr'>
															<input checked={company?.['memberPermissions']?.[member['_id']]?.[section['sectionNo']]} onChange={(e) => setPermissions(e, section, member)} id={`${section['sectionNo']}-${member['_id']}`} class="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="checkbox" />
														</span>
													</label>
												</td>
											)
										})}
									</tr>
								})}
							</tbody>
						</table>
					</div>
				}
			</div>
		)
	};

	const setPermissions = async (e, section, member) => {
		let permissions = company?.['memberPermissions']?.[member['_id']];
		permissions[section['sectionNo']] = e.target.checked;

		if (company?.['memberPermissions']?.[member['_id']]) {
			company['memberPermissions'][member['_id']] = permissions;
			dispatch(setCompany({ ...company, company }))
			savePermissions(section['sectionNo'], member, e.target.checked);
		}
	};

	const savePermissions = async (sectionNo, member, action) => {
		// User Updated
		const res = await postRequest(`/company/updatePermission/${companyId}`, { permissions: company['memberPermissions'], sectionNo, member, action });
		if (res || res['code'] == 200) showMessage(res['message']);
	};

	const getPermissionsLogs = async () => {
		const res = await postRequest('/company/permissionsLogs', { companyId });
		if (res || res['code'] == 200) {
			await setPermissionLogList(res['body']);
			dispatch(toggleModal({ open: 'permissionsLogs' }));
		}
	};

	//********** Advisor Permissions **********// 
	const advisorPermissions = () => {
		return (
			<div className="sectionWrapper p1rem fs1rem mt2rem pr mr2rem ml1rem">
				<h3 className="company-name fs1-75rem">Company Name</h3>
				<div className="pr mt2rem">
					<h3>Stage of development:</h3>
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.1 <b>Accountants</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Accountants" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Accountants1" id="Accountants" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.2 <b>Lawyers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Lawyers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Lawyers1" id="Lawyers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.3 <b>Intellectual Property Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="IntellectualPropertyAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="IntellectualPropertyAdvisors1" id="IntellectualPropertyAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.4 <b>Property Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="PropertyAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="PropertyAdvisors1" id="PropertyAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.5 <b>PR & Media Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="PR&MediaAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="PR&MediaAdvisors1" id="PR&MediaAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.6 <b>Corporate Finance Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="CorporateFinanceAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="CorporateFinanceAdvisors1" id="CorporateFinanceAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.7 <b>Commercial Bankers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="CommercialBankers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="CommercialBankers1" id="CommercialBankers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.8 <b>Investment Bankers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="InvestmentBankers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="InvestmentBankers1" id="InvestmentBankers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.9 <b>Insurance Brokers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="InsuranceBrokers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="InsuranceBrokers1" id="InsuranceBrokers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.10 <b>GDPR Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="GDPRAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="GDPRAdvisors1" id="GDPRAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.11 <b>Registrars</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Registrars" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Registrars1" id="Registrars" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.12 <b>Corporate Brokers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="CorporateBrokers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="CorporateBrokers1" id="CorporateBrokers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />

					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.13 <b>Other</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Other" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Other1" id="Other" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
				</div>
			</div>
		)
	};

	return (
		<AnimatedPage>
			<div className="td250 container bg1 py1rem shadow w100 df">
				<div className="w80 mr1rem gg1rem mt1rem">
					<div className="tac ttc df fdc jcc aic">
						<h1 className="w100 text-gradient fs2rem lh2">{tabIndex}</h1>
						{tabIndex == "Companies House Core Info" &&
							<button onClick={() => getCompany()} className='cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500 mt1rem'>Refresh</button>
						}
					</div>
					{tabIndex == "Companies House Core Info" && <>{companiesHouseCore()}</>}
					{tabIndex == "Group Info" && <>{groupInfo()}</>}
					{tabIndex == "Location & Web Site & Socials" && <>{locationWebsite()}</>}
					{tabIndex == "Director's Info" && <>{directorsInfo()}</>}
					{tabIndex == "Direct/Indirect Channel" && <>{directIndirectChannel()}</>}
					{tabIndex == "Company Stage Of Development" && <>{companyStageofDevelopment()}</>}
					{tabIndex == "Company Objectives" && <>{companyObjectives()}</>}
					{tabIndex == "Subscription Model Info" && <>{subscriptionModelInfo()}</>}
					{tabIndex == "User Permissions" && <>{userPermissions()}</>}
					{tabIndex == "Advisor Permissions" && <>{advisorPermissions()}</>}
				</div>
				{getSidebarHTML()}
			</div>

			{/* Filing Read More */}
			<Modal open={modal['open'] == 'filingReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/filinghistory.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Filing History</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setFilingReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Category:</b> {filingReadMore['category'] ? filingReadMore['category'] : '-'}</p>
						<p className='my1rem ttc'><b>Description:</b> {filingReadMore['description'] ? filingReadMore['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Bar Code:</b> {filingReadMore['barcode'] ? filingReadMore['barcode'] : '-'}</p>
						<p className='my1rem ttc'><b>Transaction Id:</b> {filingReadMore['transaction_id'] ? filingReadMore['transaction_id'] : '-'}</p>
						<p className='my1rem ttc'><b>Date:</b> {filingReadMore['date'] ? filingReadMore['date'] : '-'}</p>
						<p className='my1rem ttc'><b>Document:</b> {filingReadMore['Location'] ? <a href={filingReadMore['Location']} target='_blank' className='tdu ttu'>View Document</a> : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Officers Read More */}
			<Modal open={modal['open'] == 'officersReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Officers</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setOfficersReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Name:</b>  {officersReadMore['name'] ? officersReadMore['name'] : '-'}</p>
						<p className='my1rem ttc'><b>Role:</b>  {officersReadMore['officer_role'] ? officersReadMore['officer_role'] : '-'}</p>
						<p className='my1rem ttc'><b>Address:</b> {officersReadMore['address']
							? <>{officersReadMore['address']['premises'] && officersReadMore['address']['premises']}
								{officersReadMore['address']['address_line_1'] && ', ' + officersReadMore['address']['address_line_1']}
								{officersReadMore['address']['address_line_2'] && ', ' + officersReadMore['address']['address_line_2']}
								{officersReadMore['address']['locality'] && ' - ' + officersReadMore['address']['locality']}
								{officersReadMore['address']['region'] && ', ' + officersReadMore['address']['region']}
								{officersReadMore['address']['country'] && ', ' + officersReadMore['address']['country']}
							</>
							: '-'
						}</p>
						<p className='my1rem ttc'><b>Nationality:</b>  {officersReadMore['nationality'] ? officersReadMore['nationality'] : '-'}</p>
						<p className='my1rem ttc'><b>Occupation:</b>  {officersReadMore['occupation'] ? officersReadMore['occupation'] : '-'}</p>
						<p className='my1rem ttc'><b>DOB:</b>  {officersReadMore['date_of_birth'] ? officersReadMore['date_of_birth']['month'] + '/' + officersReadMore['date_of_birth']['year'] : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Controls Read More */}
			<Modal open={modal['open'] == 'controlReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Persons with Significant Control</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setControlReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Name:</b>  {controlReadMore['name'] ? controlReadMore['name'] : '-'}</p>
						<p className='my1rem ttc'><b>Controls:</b>
							{controlReadMore['natures_of_control'] && controlReadMore['natures_of_control'].length > 0
								? <>{controlReadMore['natures_of_control'].map((control, idx) => (
									<ul key={idx}><li>{control}</li></ul>
								))}</>
								: '-'}</p>
						<p className='my1rem ttc'><b>E-Tag:</b>  {controlReadMore['etag'] ? controlReadMore['etag'] : '-'}</p>
						<p className='my1rem ttc'><b>Notified Date:</b>  {controlReadMore['notified_on'] ? controlReadMore['notified_on'] : '-'}</p>
						<p className='my1rem ttc'><b>Address:</b> {controlReadMore['address']
							? <>{controlReadMore['address']['premises'] && controlReadMore['address']['premises']}
								{controlReadMore['address']['address_line_1'] && ', ' + controlReadMore['address']['address_line_1']}
								{controlReadMore['address']['address_line_2'] && ', ' + controlReadMore['address']['address_line_2']}
								{controlReadMore['address']['locality'] && ' - ' + controlReadMore['address']['locality']}
								{controlReadMore['address']['region'] && ', ' + controlReadMore['address']['region']}
								{controlReadMore['address']['country'] && ', ' + controlReadMore['address']['country']}
							</>
							: '-'
						}</p>
						<p className='my1rem ttc'><b>Nationality:</b>  {controlReadMore['nationality'] ? controlReadMore['nationality'] : '-'}</p>
						<p className='my1rem ttc'><b>DOB:</b>  {controlReadMore['date_of_birth'] ? controlReadMore['date_of_birth']['month'] + '/' + controlReadMore['date_of_birth']['year'] : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Charge Read More */}
			<Modal open={modal['open'] == 'chargeReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Charges</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setChargeReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Persons Entitled:</b>  {chargeReadMore['persons_entitled'] ? chargeReadMore['persons_entitled'][0]['name'] : '-'}</p>
						<p className='my1rem ttc'><b>Charge Description:</b>  {chargeReadMore['classification'] ? chargeReadMore?.['classification']?.['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Status:</b>  {chargeReadMore['status'] ? chargeReadMore['status'] : '-'}</p>
						<p className='my1rem ttc'><b>E-Tag:</b>  {chargeReadMore['etag'] ? chargeReadMore['etag'] : '-'}</p>
						<p className='my1rem ttc'><b>Charge Number:</b>  {chargeReadMore['charge_number'] ? chargeReadMore['charge_number'] : '-'}</p>
						<p className='my1rem ttc'><b>Delivered On:</b>  {chargeReadMore['delivered_on'] ? chargeReadMore['delivered_on'] : '-'}</p>
						<p className='my1rem ttc'><b>Satisfied On:</b>  {chargeReadMore['satisfied_on'] ? chargeReadMore['satisfied_on'] : '-'}</p>
						<p className='my1rem ttc'><b>Short Particulars:</b>  {chargeReadMore['particulars'] ? chargeReadMore?.['particulars']?.['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Amount secured description:</b>  {chargeReadMore['secured_details'] ? chargeReadMore?.['secured_details']?.['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Transaction filing type:</b>  {chargeReadMore['transactions'] ? chargeReadMore?.['transactions']?.['filing_type'] : '-'}</p>
						<p className='my1rem ttc'><b>Transaction filing Delivered On:</b>  {chargeReadMore['transactions'] ? chargeReadMore?.['transactions']?.['delivered_on'] : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Upload CV Data */}
			<Modal open={modal['open'] == 'uploadCvData'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => closeDirectorPopup()}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-25rem fw600 mt1rem">Director Info Update</h1>
					<div className='group-inputs mt2rem'>
						<h3 className='fs1rem fw500 mb1rem'>Upload CV Here</h3>
						<label for="fileUpload" className="w100 bsbb bg5 br5px df jcc aic p1rem bsd bo6 bw2px cp">
							<span className="pen fs1rem fw500 tac">Upload CV</span>
							<input onChange={handleCvChange} className="dn" id="fileUpload" type="file" multiple />
						</label>
						{cvData[director['_id']] && <div className="df fww mt0-5rem">
							{cvData[director['_id']] && <a className="df jcc aic br25px lh1 bg3 c1 px0-5rem fw600 mr0-5rem mb0-5rem" target="_blank" href={cvData[director['_id']]?.location}>
								<span className="py0-5rem px0-5rem">{cvData[director['_id']]?.name}</span>
							</a>}
						</div>}
						<p className='fs1rem fw500 mt1rem mb0-5rem'>LinkedIn Profile</p><br />
						<input id="LinkedinUrl" name="LinkedinUrl" type="url" placeholder="Please add LinkedIn url" value={formData['linkedIn'] || ''} onChange={(e) => setFormData({ ...formData, linkedIn: e.target.value })}
							className="w100 show-input shadow bg5 br5px p1rem mb1rem" />

						<div className='df jcse'>
							<span onClick={() => handleSubmit()} className="shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem">Submit</span>
						</div>
					</div>
				</div>
			</Modal>

			{/* Delete File */}
			<Modal open={modal['open'] == 'deleteFiles'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem fw600 my1rem">Delete File</h1>
					<h2 className="c4 fs1rem fs1-5rem-sm ttu w100 tac mb1rem">Are you sure you want to delete this file?</h2>
					<p className="fs1-25rem fw500 mb1rem">{attachment && attachment.originalname}</p>
					<span onClick={() => confirmDeleteFiles()} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac mb1rem df jcc aic">Delete File</span>
					<span onClick={() => dispatch(toggleModal({ open: '' }))} className="c3-hover td250 fs1-25rem cp">Cancel</span>
				</div>
			</Modal>

			{/* Delete Company */}
			<Modal open={modal['open'] == 'companyDelete'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<div className='df jcc'>
						<button onClick={() => dispatch(toggleModal({ open: 'deleteAuditLog' }))} className='pa r0 cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem mr3rem fw500'>Audit Log</button>
						<h1 className="text-gradient fw600 pb1rem">Delete Company</h1>
					</div>
					<div className="tac my1rem">
						<p>Are you certain you want to delete that <b>{company['cName']}</b> Company?</p>
					</div>
					<div className="df jcse">
						<button onClick={() => dispatch(toggleModal({ open: '' }))} className="h100 mt0-5rem shadow bg4 bg3-hover c1 td250 br5px px2rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">Cancel</button>
						<button onClick={() => submitDeleteCompany()} className="h100 mt0-5rem shadow bg3 bg4-hover c1 td250 br5px px2rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">Submit</button>
					</div>
				</div>
			</Modal>

			{/* Add Group Company */}
			<Modal open={modal['open'] == 'addGroupCompany'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 mih35 bg1 shadow w100 p2rem bsbb df fdc m1rem maw700px br5px pr">
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setCompaniesGroup({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">Add Group Company</h1>
					<div className='bottom-company-area'>
						{companies && companies.map((childCompnay, idx) => {
							if (childCompnay['_id'] !== company['_id'] && childCompnay['group'] !== "Parent") {
								return (
									<div key={idx} className='company-detail-box df aic w95'>
										<span className="checkbox active w2rem h2rem pr mr1rem">
											<input onChange={(event) => handleChildCompany({ event, companyId: childCompnay['_id'], group: companiesGroup[childCompnay['_id']]?.['group'], selected: 'true' })}
												id={`childCompnay-${childCompnay['_id']}`}
												className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px"
												type="checkbox"
											/>
										</span>
										<div className="company-detail df fww p1rem br10px shadow bg1 fs1rem mb1rem w100 aic">
											<h4 className='fs0-75rem mr1rem w9rem'>{childCompnay['cName']}</h4>
											<span className='mr0-5rem df jsc w6rem'>
												<h2 className="fs1rem c7 mra tal mr0-5rem w4rem">
													{childCompnay['subscription']
														? childCompnay['subscription']?.toUpperCase()
														: 'INACTIVE'
													}
												</h2>
												<div className='df'>
													<span className='w30 df username-area'>
														<div className="df aic">
															<span className="ass fs0-75rem mt0-3rem ml0-5rem mr0-2rem">Owner</span>
															<span onClick={() => dispatch(toggleModal({ open: 'userDetail' }))} data-tooltip={user?.firstName ? (user?.firstName + " " + user?.lastName) : "Register to show your name here"} className="w2rem h2rem df jcc aic br100 bg3 c1 mr0-5rem fs0-75rem">{user?.firstName ? (user?.firstName?.[0] + user?.lastName?.[0]) : "?"}</span>
														</div>
													</span>
												</div>
											</span>
											<div className='dropdown-company w4rem px1rem py0-5rem ml1rem'>
												<select value={companiesGroup[childCompnay['_id']]?.['group']} name="dropdown" className="fs0-75rem" onChange={(e) => handleChildCompany({ companyId: childCompnay['_id'], group: e.target.value })} id={`group-${childCompnay['_id']}`}>
													<option value='Subsidiary'>Subsidiary</option>
													<option value='Associate'>Associate</option>
												</select>
											</div>
											<div className='percentage-area ml1rem w7rem'>
												<span className='input-area'>
													<input onChange={(e) => handleChildCompany({ companyId: childCompnay['_id'], group: companiesGroup[childCompnay['_id']]?.['group'], percentage: e.target.value })} placeholder='Enter Percentage %' id={`percentage-${childCompnay['_id']}`} type="text" className="fw600" min="1" max="100" />
												</span>
											</div>
										</div>
									</div>
								)
							}
						})}
					</div>
					<span onClick={updateGroupCompany} id="createGroup" className={`${(company == null || companiesGroup.length == 0) && 'op20 pen '} mt0-5rem shadow bg3 bg4-hover c1 td250 br5px pt0-5rem pb0-5rem pl3rem pr3rem fw600 cp ttu bsbb tac df jcc aic`}>Add</span>
				</div>
			</Modal>

			{/* Edit/Delete Group Company */}
			<Modal open={modal['open'] == 'updateGroupCompany'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 mih35 bg1 shadow w100 p2rem bsbb df fdc m1rem maw700px br5px pr">
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })), setCompaniesGroup({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">{updateType} Group Company</h1>
					<div className='bottom-company-area'>
						{company['childrenCompanies'] && company['childrenCompanies'].map((childCompnay, idx) => {
							return (
								<div key={idx} className='company-detail-box df aic w95'>
									<span className="checkbox active w2rem h2rem pr mr1rem">
										<input onChange={(event) => handleChildCompany({ event, companyId: childCompnay['_id'], group: childCompnay['group'], percentage: childCompnay['percentage'], selected: 'true' })}
											id={`childCompnay${childCompnay['_id']}`}
											className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px"
											type="checkbox"
										/>
									</span>
									<div className="company-detail df fww p1rem br10px shadow bg1 fs1rem mb1rem w100 aic">
										<h4 className='fs0-75rem mr1rem w9rem'>{childCompnay['cName']}</h4>
										<span className='mr0-5rem df jsc w6rem'>
											<h2 className="fs1rem c7 mra tal mr0-5rem w4rem">
												{childCompnay['subscription']
													? childCompnay['subscription']?.toUpperCase()
													: 'INACTIVE'
												}
											</h2>
											<div className='df'>
												<span className='w30 df username-area'>
													<div className="df aic">
														<span className="ass fs0-75rem mt0-3rem ml0-5rem mr0-2rem">Owner</span>
														<span onClick={() => dispatch(toggleModal({ open: 'userDetail' }))} data-tooltip={user?.firstName ? (user?.firstName + " " + user?.lastName) : "Register to show your name here"} className="w2rem h2rem df jcc aic br100 bg3 c1 mr0-5rem fs0-75rem">{user?.firstName ? (user?.firstName?.[0] + user?.lastName?.[0]) : "?"}</span>
													</div>
												</span>
											</div>
										</span>
										<div className='dropdown-company w4rem px1rem py0-5rem ml1rem'>
											<select defaultValue={childCompnay['group']} disabled={updateType == "Delete"} name="dropdown" className="fs0-75rem" onChange={(e) => handleChildCompany({ companyId: childCompnay['_id'], group: e.target.value })} id={`group-${childCompnay['_id']}`}>
												<option value='Subsidiary'>Subsidiary</option>
												<option value='Associate'>Associate</option>
											</select>
										</div>
										<div className='percentage-area ml1rem w7rem'>
											<span className='input-area'>
												<input defaultValue={childCompnay['percentage']} disabled={updateType == "Delete"} onChange={(e) => handleChildCompany({ companyId: childCompnay['_id'], percentage: e.target.value })}
													placeholder='Enter Percentage %' id={`percentage-${childCompnay['_id']}`} type="text" className="fw600" min="1" max="100" style={{ color: updateType == 'Delete' && '#aeacac' }} />
											</span>
										</div>
									</div>
								</div>
							)
						})}
					</div>
					<span onClick={() => updateGroupCompany()} id="createGroup" className={`${(company == null || companiesGroup.length == 0) && 'op20 pen '} mt0-5rem shadow bg3 bg4-hover c1 td250 br5px pt0-5rem pb0-5rem pl3rem pr3rem fw600 cp ttu bsbb tac df jcc aic`}>{updateType}</span>
				</div>
			</Modal>

			{/* User Permissions Logs */}
			<Modal open={modal['open'] == 'permissionsLogs'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac">{company['cName']} User Permission Log</h1>
					<h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of User Permissions</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						{permissionLogList && permissionLogList.length > 0
							? permissionLogList.map(log => {
								return (
									<div className="df my0-5rem">
										{log?.['userId']?.['profile']
											&& <img className={`ass ml0-5rem br100 w2rem h2rem ${profileClass}`} src={log['userId']['profile']} data-tooltip={log?.['userId'].firstName ? (log?.['userId'].firstName + " " + log?.['userId'].lastName) : "Register to show your name here"} />
											|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`}>{log?.['userId']?.['firstName']?.[0] || "?"}{log?.['userId']?.['lastName']?.[0] || "?"}</span>
										}
										<div className="w100">
											<span className='fs0-75rem ml1rem'><b>{log['message']}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b></span>
											{(log?.['endpoint']?.includes('newMember') || log?.['endpoint']?.includes('editMember')) ? (
												<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
													<p><b>First Name:</b> {log?.['payload']?.['firstName']}</p>
													<p><b>Last Name:</b> {log?.['payload']?.['lastName']}</p>
													<p><b>Email:</b> {log?.['payload']?.['email']}</p>
													<p><b>Designation:</b> {log?.['payload']?.['designation']}</p>
												</div>
											) : log?.['endpoint']?.includes('updatePermission') ? (
												<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
													<b>Changed Permission:</b>
													<p><b>{log?.['payload']?.['sectionNo']}</b> - ({permissionNames[log?.['payload']?.['sectionNo']]})
														permission is {log?.['payload']?.['action'] ? 'added to' : 'removed from'}
														<b> {log?.['payload']?.['member']?.['firstName']}</b> - ({log?.['payload']?.['member']?.['designation']})
													</p>
												</div>
											) : ''}
										</div>
									</div>
								)
							})
							: <h2 className="c4 fs1-50rem ttu w100 tac mb1rem">No Logs Found!!</h2>
						}
					</div>
				</div>
			</Modal>

			{/* Audit Logs */}
			<Modal open={modal['open'] == 'auditLogs'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac ttu">{company['cName']} Company Audit Log</h1>
					<h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of Company Audit Updated</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						<div className='df'>
							{company?.['creatorId']?.['profile']
								&& <img className={`ass ml0-5rem br100 w2rem h2rem ${profileClass}`} src={company?.['creatorId']['profile']} data-tooltip={company?.['creatorId']?.['firstName'] ? (company?.['creatorId']?.['firstName'] + " " + company?.['creatorId']?.['lastName']) : "Register to show your name here"} />
								|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`}>{company?.['creatorId']?.['firstName']?.[0] || "?"}{company?.['creatorId']?.['lastName']?.[0] || "?"}</span>
							}
							<span className='fs0-75rem ml1rem'><b>Company details updates</b></span>
						</div>
						<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Name:</span> {company['cName']}</p>
							{company?.['creatorId'] && <p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Owner:</span> {company['creatorId']['firstName'] + ' ' + company['creatorId']['lastName']}</p>}
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Created At:</span> {moment(company['creaedAt']).format('ll')}</p>
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Payment Source:</span> {company['paymentSource']}</p>
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Stage of development:</span> {company['cStage']}</p>
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Objectives:</span> {company['testType']}</p>
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Subscription:</span>
								{((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro')
									? 'Portfolio PRO'
									: <>{company['subscription']}</>
								}
							</p>
						</div>
						{auditLogList && auditLogList.length > 0 && auditLogList.map(log => {
							return (
								<div className="df my0-5rem">
									{log?.['userId']?.['profile']
										&& <img className={`ass ml0-5rem br100 w2rem h2rem ${profileClass}`} src={log['userId']['profile']} data-tooltip={log?.['userId'].firstName ? (log?.['userId'].firstName + " " + log?.['userId'].lastName) : "Register to show your name here"} />
										|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`}>{log?.['userId']?.['firstName']?.[0] || "?"}{log?.['userId']?.['lastName']?.[0] || "?"}</span>
									}
									<div className="w100">
										<span className='fs0-75rem ml1rem'><b>{log['message']}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b> by <b>{log?.['userId']?.['firstName']} {log?.['userId']?.['lastName']}</b></span>
										{/* Company Info Logs */}
										{log['payload']['update'] &&
											<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
												<p className="df fw500 fs1rem">Changes to {company['cName']} - Company Details:</p>
												<div class="df w100">
													<div class="mr0-5rem">
														{Object.keys(log['payload']['update']).map(key => {
															return <p className="fw600">{companyKeys[key]}:</p>
														})}
													</div>
													<div>
														{Object.values(log['payload']['update']).map(value => {
															return <p>{value}</p>
														})}
													</div>
												</div>
											</div>
										}
										{/* Company Group Logs */}
										{log['payload']?.['data']?.['childUpdates'] && log['payload']?.['data']?.['childUpdates'].length > 0 &&
											<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
												<p className="df fw500 fs1rem">{log['payload']['data']['type'] && log['payload']['data']['type']} Group Info:</p>
												{log['payload']?.['data']?.['childUpdates'].map(data => {
													return <>
														{companies && companies.length > 0 && companies.map(company => {
															if (company['_id'] == log['payload']?.['data']?.['parentId']) {
																if (company['childrenCompanies']) {
																	for (let childCompnay of company['childrenCompanies']) {
																		if (childCompnay['_id'] == data['companyId']) {
																			return <p><span className='fw600'>Company Name:</span> {childCompnay['cName']}</p>
																		}
																	}
																}
															}
														})}
														<p><span className='fw600'>Group:</span> {data['group']}</p>
														<p><span className='fw600'>Percentage:</span> {data['percentage']}%</p>
													</>
												})}
											</div>
										}
										{/* Company Directors Logs */}
										{log['payload']['directorId'] &&
											<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
												<p className="df fw500 fs1rem">Changes to {log['payload']['directorId']['name']} - Director's Info:</p>
												<p className='df fw500'><span className='fw600 mr0-5rem'>CV:</span>
													{log['files'].length > 0 && log['files'].map(file => (
														<a className="fw400 mr0-5rem fs0-75rem tdu" target="_blank" href={file?.location}><span>{file?.originalname}</span></a>
													))}
												</p>
												<p className='df fw500'><span className='fw600 mr0-5rem'>LinkedIn:</span>
													{log['payload']['linkedIn'] && log['payload']['linkedIn'] !== 'undefined'
														? <a className="fw400 mr0-5rem fs0-75rem tdu" target="_blank" href={log['payload']['linkedIn']}>{log['payload']['linkedIn']}</a> : '-'}
												</p>
											</div>
										}
									</div>
								</div>
							)
						})
						}
					</div>
				</div>
			</Modal>

			{/* Company Audit Logs */}
			<Modal open={modal['open'] == 'companyAuditLog'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac ttu">{company['cName']} - {tabIndex} Log</h1>
					<h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of {tabIndex} Updates</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						<div className="df my0-5rem">
							{company?.['creatorId']?.['profile']
								&& <img className={`ass ml0-5rem br100 w2rem h2rem ${profileClass}`} src={company?.['creatorId']['profile']} data-tooltip={company?.['creatorId'].firstName ? (company?.['creatorId'].firstName + " " + company?.['creatorId'].lastName) : "Register to show your name here"} />
								|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`}>{company?.['creatorId']?.['firstName']?.[0] || "?"}{company?.['creatorId']?.['lastName']?.[0] || "?"}</span>
							}
							<div className="w100">
								<span className='fs0-75rem ml1rem'><b>{tabIndex} Updated</b> at <b>{new Date(company.createdAt).toLocaleString()}</b> {company?.['creatorId'] && <> by <b>{company?.['creatorId']['firstName']} {company?.['creatorId']['lastName']}</b></>}</span>
								<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
									<p className="df fw500 fs1rem mb0-5rem">{tabIndex} Info:</p>
									{tabIndex == "Direct/Indirect Channel" && <>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Name:</span> {company['cName']}</p>
										{company?.['creatorId'] && <p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Owner:</span> {company['creatorId']['firstName'] + ' ' + company['creatorId']['lastName']}</p>}
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Created At:</span> {moment(company['creaedAt']).format('ll')}</p>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Type of customer:</span> {company['customerType']}</p>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Payment Source:</span> {company['paymentSource']}</p>
									</>}
									{tabIndex == "Company Stage Of Development" && <>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Name:</span> {company['cName']}</p>
										{company?.['creatorId'] && <p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Owner:</span> {company['creatorId']['firstName'] + ' ' + company['creatorId']['lastName']}</p>}
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Created At:</span> {moment(company['creaedAt']).format('ll')}</p>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Stage of development:</span> {company['cStage']}</p>
									</>}
									{tabIndex == "Company Objectives" && <>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Name:</span> {company['cName']}</p>
										{company?.['creatorId'] && <p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Owner:</span> {company['creatorId']['firstName'] + ' ' + company['creatorId']['lastName']}</p>}
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Created At:</span> {moment(company['creaedAt']).format('ll')}</p>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Objectives:</span> {company['testType']}</p>
									</>}
									{tabIndex == "Subscription Model Info" && <>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Name:</span> {company['cName']}</p>
										{company?.['creatorId'] && <p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Owner:</span> {company['creatorId']['firstName'] + ' ' + company['creatorId']['lastName']}</p>}
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Created At:</span> {moment(company['creaedAt']).format('ll')}</p>
										<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Subscription:</span>
											{((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro')
												? 'Portfolio PRO'
												: <>{company['subscription']}</>
											}
										</p>
										{company['expensions'] && Object.keys(company['expensions']).length > 0 && <>
											<p className="df fw500 fs1rem my0-5rem">Expansion Info:</p>
											{company['expensions']['DEPN004'] && <li>DEPN004 – Industry Expansion Pack – Software Development {isNaN(company['expensions']['DEPN004']) && <> - {moment(company['expensions']['DEPN004']).format('ll')}</>}</li>}
											{company['expensions']['DEPF005'] && <li>DEPF005 – Function Expansion Pack - Environmental Social & Governmental (ESG) {isNaN(company['expensions']['DEPF005']) && <> - {moment(company['expensions']['DEPF005']).format('ll')}</>}</li>}
											{company['expensions']['DEPF006'] && <li>DEPF006 – Function Expansion Pack – Warranty Disclosure Schedule {isNaN(company['expensions']['DEPF006']) && <> - {moment(company['expensions']['DEPF006']).format('ll')}</>}</li>}
											{company['expensions']['DEPF007'] && <li>DEPF007 – Expansion Pack – DD – Directors Questionnaire {isNaN(company['expensions']['DEPF007']) && <> - {moment(company['expensions']['DEPF007']).format('ll')}</>}</li>}
										</>}
									</>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			{/* Delete Company Audit Logs */}
			<Modal open={modal['open'] == 'deleteAuditLog'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac ttu">{company['cName']} - Log</h1>
					<h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of {company['cName']} Updates</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						<div className="df my0-5rem">
							{company?.['creatorId']?.['profile']
								&& <img className={`ass ml0-5rem br100 w2rem h2rem bg3`} src={company?.['creatorId']['profile']} data-tooltip={company?.['creatorId'].firstName ? (company?.['creatorId'].firstName + " " + company?.['creatorId'].lastName) : "Register to show your name here"} />
								|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`}>{company?.['creatorId']?.['firstName']?.[0] || "?"}{company?.['creatorId']?.['lastName']?.[0] || "?"}</span>
							}
							<div className="w100">
								<span className='fs0-75rem ml1rem'><b>{company['cName']} Updated</b> at <b>{new Date(company.createdAt).toLocaleString()}</b> {company?.['creatorId'] && <> by <b>{company?.['creatorId']['firstName']} {company?.['creatorId']['lastName']}</b></>}</span>
								<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
									<p className="df fw500 fs1rem mb0-5rem">{company['cName']} Info:</p>
									<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Name:</span> {company['cName']}</p>
									{company?.['creatorId'] && <p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Owner:</span> {company['creatorId']['firstName'] + ' ' + company['creatorId']['lastName']}</p>}
									<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Created At:</span> {moment(company['creaedAt']).format('ll')}</p>
									<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Objectives:</span> {company['testType']}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			{/* Warning modal for deleting company who has parrllel companies or are parent of group of companies  */}
			<Modal open={modal['open'] == 'warningDeleteCompany'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 oya bg1 p3rem shadow w100 bsbb df fdc maw600px br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h2 className="c4 fs1-75rem ttu w100 tac mb1rem">Warning</h2>
					<div className="df fdc fs1rem mb1rem">
						<p>Before deleting this company, please ensure that all associated companies under this user are deleted first,
							as this is the primary company. Additionally,
							if this company has any subsidiary companies, you must first remove its status as the parent company in the group.
						</p>
					</div>
					<div className="df jcc fdc fdr-md aic fs1rem px1rem">
						<span onClick={() => dispatch(toggleModal({ open: '' }))}
							className="shadow my0-5rem bg3 bg4-hover c1 td250 br5px p0-5rem fw600 cp ttu w40 bsbb tac">
							Ok
						</span>
					</div>
				</div>
			</Modal>

		</AnimatedPage >
	);
}
