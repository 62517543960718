/**
 * This code was generated by Builder.io.
 */
import React from "react";
import * as styles from "../../BlogPost.module.css";

function SecondaryCard({ title, subTitle, imageSrc, maxWidth }) {
  return (
    <>
      <div className={styles.SecondaryCard}>
        <h2 className={styles.SecondaryCardHeading}>
          {title.includes("<r>") ? (
            <>
              {title.split("<r>")[0]}{" "}
              <span className={styles.ColorRed}>{title.split("<r>")[1]}</span>
            </>
          ) : (
            title
          )}
        </h2>
        {subTitle ? <p> {subTitle}</p> : ""}
        <img
          src={imageSrc}
          alt={`${title} illustration`}
          className={styles.secondaryCardImage}
          style={{ maxWidth: maxWidth }}
        />
      </div>
    </>
  );
}

export default SecondaryCard;
