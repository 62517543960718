import React from "react";
import * as styles from "../../BlogPost.module.css";

const CardWrapper = ({ children, bgImage }) => {
  return (
    <>
      <section
        className={styles.featureSection}
        style={{
          ...(bgImage !== "" ? {
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          } : {})
        }}
      >
        {children}
      </section>
    </>
  );
};

export default CardWrapper;
