import React from 'react'
import CustomTab from '../../components/termsAndConditions/CustomTab'
import SmoothScrollingButton from '../../components/SmoothScrollingButton'
import AnimatedPage from '../../components/AnimatedPage'

const AcceptableUse = () => {
    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className=' poppins-font-style privacy-policy-page terms-and-conditions-page *'>
                <CustomTab type="terms-and-conditions" className="terms-and-conditions-tab" activeIdentifier="acceptable-use" title={'Terms & Conditions'} width="41%" />
                <div className='policy-box' >
                    <div className="fw400 tal c14 fs1rem">
                        <p className="pt1rem pb1rem">Effective: June 30th 2022</p>
                        <p className="pb1rem">Diligentsia is proud of the trust placed in us. In exchange, we trust you to use our services responsibly.</p>
                        <p className="pb1rem">You agree not to misuse the Diligentsia services ("Services") or help anyone else to do so. For example, you must not even try to do any of the following in connection with the Services:</p>
                        <ol className="pb1rem">
                            <li>probe, scan, or test the vulnerability of any system or network, </li>
                            <li>breach or otherwise circumvent any security or authentication measures;</li>
                            <li>access, tamper with, or use non-public areas or parts of the Services, or shared areas of the Services you haven't been invited to;</li>
                            <li>interfere with or disrupt any user, host, or network, for example by sending a virus, overloading, flooding, spamming, or mail-bombing any part of the Services;</li>
                            <li>access, search, or create accounts for the Services by any means other than our publicly supported interfaces (for example, "scraping" or creating accounts in bulk);</li>
                            <li>send unsolicited communications, promotions or advertisements, or spam;</li>
                            <li>send altered, deceptive or false source-identifying information, including "spoofing" or "phishing";</li>
                            <li>promote or advertise products or services other than your own without appropriate authorization;</li>
                            <li>abuse referrals or promotions to get more storage space than deserved or to sell storage space received from referrals or promotions;</li>
                            <li>circumvent storage space limits;</li>
                            <li>sell the Services unless specifically authorized to do so;</li>
                            <li>publish, share, or store materials that constitute child sexually exploitative material (including material which may not be illegal child sexual abuse material but which nonetheless sexually exploits or promotes the sexual exploitation of minors), unlawful pornography, or are otherwise indecent;</li>
                            <li>publish, share, or store content that contains or promotes extreme acts of violence or terrorist activity, including terror propaganda;</li>
                            <li>advocate bigotry or hatred against any person or group of people based on their race, religion, ethnicity, sex, gender identity, sexual orientation, disability, or impairment;</li>
                            <li>harass or abuse Diligentsia personnel or representatives or agents performing services on behalf of Diligentsia;</li>
                            <li>violate the law in any way, including storing, publishing or sharing material that’s fraudulent, defamatory, or misleading, or that violates the intellectual property rights of others;</li>
                            <li>violate the privacy or infringe the rights of others;</li>
                            <li>use the Services to back up, or as infrastructure for, your own cloud services;</li>
                            <ol className="pt1rem ">
                                <li>use the storage space provided by the Services as the basis for cryptographic proof-of-space or proof-of-storage, or any similar proof system.</li>
                            </ol>
                        </ol>
                        <p className="pb1rem">We reserve the right to take appropriate action in response to violations of this policy, which could include removing or disabling access to content, suspending a user’s access to the Services, or terminating an account.</p>
                    </div >
                </div>
            </div>
        </AnimatedPage>
    )

}
export default AcceptableUse