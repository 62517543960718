import React from 'react'
import CustomTab from '../../components/termsAndConditions/CustomTab'
import SmoothScrollingButton from '../../components/SmoothScrollingButton'
import AnimatedPage from '../../components/AnimatedPage'
import HeadingSubheadingContent from '../../components/HeadingSubheadingContent'


const IndividualsTerms = () => {
    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page terms-and-conditions-page *'>
                <CustomTab type="terms-and-conditions" className="terms-and-conditions-tab ttc" activeIdentifier="individual-terms" title={'Terms & Conditions'} width="41%" />
                <div className='policy-box'>
                    <h1 className="pt1rem pb2rem fs1-5rem fw500 tal c15" style={{ fLineHeight: "39.81px" }}>Diligentsia Terms & Conditions of Service</h1>
                    <div className="fw400 tal c14 fs1rem">
                        <p>Effective: 30th June 2022</p>
                        <p className="pt1rem">Thanks for using Diligentsia! Our mission is to create a more enlightened way of working by providing an intuitive, unified platform to keep your content safe and accessible while helping you and those you work with stay coordinated and in sync. These terms of service (‘Terms’) cover your use and access to our services, client software and websites (‘Services’). Your agreement is with Diligentsia Limited, a UK company number registered in England & Wales under registration number 14256673. Our Privacy Policy explains how we collect and use your information while our Acceptable Use Policy outlines your responsibilities when using our Services. By using our Services, you’re agreeing to be bound by these Terms, our Privacy Policy and Acceptable Use Policy.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Your Stuff & Your Permissions</p>
                        <p className="pb1rem">When you use our Services, your organisation provide us with things like your files, content, messages, contacts and so on (“Your Stuff”). Your Stuff belongs to your business. These Terms don’t give us any rights to Your Stuff except for the limited rights that enable us to offer the Services.  </p>
                        <p className="pb1rem">We need your permission to do things like hosting Your Stuff, backing it up and sharing it when you ask us to. It includes our setting up a Diligentsia corporate folder and sub-folders within one or more of your organisation’s third party cloud repositories, such as Sharepoint, Google Drive or Diligentsia.  You authorise us to upload Our Services to our Diligentsia databases and platform more generally.  We may also provide you with features like commenting, sharing, searching, image thumbnails, document previews, optical character recognition (OCR), easy sorting and organisation and personalisation to help reduce busywork. To provide these and other features, Diligentsia accesses, uploads, stores and scans Your Stuff within its own databases. You give us permission to do those things, and this permission extends to our affiliates and trusted third parties we work with.  In particular, unless you opt out, we are free to share your information with your accountants, lawyers, investors where you have been introduced by one or more of those organisations as their client or portfolio company.  Where you enter additional information directly into the Diligentsia folder within your cloud storage, you authorise us to synch that folder with its corresponding copy held within the Diligentsia databases in your organisation’s name.</p>
                        <p className="pb1rem">You authorise us to undertake data analytics on the information your organisation uploads to, and synch’s with, the Diligentsia folder.  We may utilise that information on an anonymised basis both to improve our and to provide additional services to you, either directly or through trusted third parties we work with, provided that you will authorise those third parties.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Your Responsibilities</p>
                        <p className="pb1rem">Your organisation’s use of our Services must comply with our Acceptable Use Policy. Content in the Services may be protected by others’ intellectual property rights. Please don’t copy, upload, download or share content unless you have the right to do so.</p>
                        <p className="pb1rem">Diligentsia may review your organisation’s conduct and content for compliance with these Terms and our Acceptable Use Policy. We are not responsible for the content people post and share via the Services.</p>
                        <p className="pb1rem">Help us keep Your Stuff protected. Safeguard your organisation’s employees’ passwords to the Services, and keep your organisation’s account information current. Don’t share your account credentials or give others access to your account.</p>
                        <p className="pb1rem">You may use our Services only as permitted by applicable law, including export control laws and regulations. Finally, to use our Services you must be aged over 18. If the law where you reside requires that you must be older in order for Diligentsia to lawfully provide the Services to you without parental consent (including use of your personal data), then you must be that older age.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Software</p>
                        <p className="pb1rem">Some of our Services and those provided by our partners allow you to download client software (“Software”) which may be updated automatically. As long as you comply with these Terms, we and they give you a limited, non-exclusive, non-transferable, revocable licence to use the Software, solely to access the Services. To the extent any component of the Software may be offered under an open source licence, we’ll make that licence available to you and the provisions of that licence may expressly override some of these Terms. Unless the following restrictions are prohibited by law, you agree not to reverse engineer or decompile the Services, attempt to do so or assist anyone to do so.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Beta Services</p>
                        <p className="pb1rem">We sometimes release products and features that we’re still testing and evaluating (‘Beta Services’). Beta Services are labelled ‘alpha’, ‘beta’, ‘preview’, ‘early access’, ‘evaluation’ or “MVP” (or with words or phrases with similar meanings) and may not be as reliable as Diligentsia’s other services. Beta Services are made available so that we can collect user feedback, and by using our Beta Services, you agree that we may contact you to collect such feedback.</p>
                        <p className="pb1rem">Beta Services are confidential until official launch. If you use any Beta Services, you agree not to disclose any information about those Services to anyone else without our permission.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'> Additional Features</p>
                        <p className="pb1rem">From time to time, Diligentsia will add additional features to enhance the user experience of our services at no additional charge. However, these free features may be withdrawn without further notice.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Our Stuff</p>
                        <p className="pb1rem">The Services are protected by copyright, trademark, and other UK and foreign laws. These Terms don’t grant you any right, title or interest in the Services, others’ content in the Services, Diligentsia trademarks, logos and other brand features. We welcome feedback, but please note that we may use comments or suggestions without any obligation to you.</p>
                        <p>Copyright</p>
                        <p className="pb1rem">We respond to notices of alleged copyright infringement if they comply with the law, and such notices should be reported using our Copyright Policy. We reserve the right to delete or disable content alleged to be infringing and terminate accounts of repeat infringers. Our designated agent for notice of alleged copyright infringement on the Services is:</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>The CEO</p>
                        <p className="pb1rem" style={{ fontStyle: "italic" }}>Diligentsia Limited</p>
                        <p className="pb1rem" style={{ fontStyle: "italic" }}>The Old Rectory</p>
                        <p className="pb1rem" style={{ fontStyle: "italic" }}>Nock Verges, Stoney Stanton LE9 $LR UK</p>
                        copyright@Diligentsia.com
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Paid Accounts</p>
                        <p className="pb1rem">Billing. You can change plans and/or add paid features to your account (turning your account into a ‘Paid Account’). We’ll automatically bill you from the date you convert to a Paid Account and on each periodic renewal until cancellation. If you’re on an annual plan, we’ll send you a notice email reminding you that your plan is about to renew within a reasonable period of time prior to the renewal date. You’re responsible for all applicable taxes, and we’ll charge tax when required to do so. Some countries have mandatory local laws regarding your cancellation rights, and this paragraph doesn’t override these laws.</p>
                        <p className="pb1rem">Cancellation. You may cancel your account at any time. Refunds are only issued if required by law. For example, users living in the European Union have the right to cancel their Paid Account subscriptions within 14 days of signing up for, upgrading to or renewing a Paid Account by clicking here.  Upon your account being cancelled, Diligentsia has the right to suspend the provision of its Services with immediate effect.  This may mean that some or all of your Stuff may be unavailable within the Diligentsia platform or Diligentsia folder within your cloud platform.  You will be warned of this via an email prior to our taking such action. </p>
                        <p className="pb1rem">Downgrades. Your Paid Account will remain in effect until cancelled or terminated under these Terms If you don’t pay for your Paid Account on time, we reserve the right to suspend it or remove Paid Account features.</p>
                        <p className="pb1rem">Changes. We may change the fees in effect on renewal of your subscription, to reflect factors such as changes to our product offerings, changes to our business or changes in economic conditions. We’ll give you no less than 30 days’ advance notice of these changes via a message to the email address associated with your account and you’ll have the opportunity to cancel your subscription before the new fee comes into effect.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Diligentsia Business Teams</p>
                        <p className="pb1rem">Using Diligentsia Business Teams. If your organisation signs up to Diligentsia and uses our Services, you must use them in compliance with your organisation’s terms and policies. Please note that Diligentsia accounts are subject to your organisation's control. Your administrators may be able to access, disclose, restrict or remove information in or from your Diligentsia account. They may also be able to restrict or terminate your access to a Diligentsia account. </p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Termination</p>
                        <p className="pb1rem">Your organisation is free to stop using our Services at any time. We reserve the right to suspend or terminate your access to the Services with notice to you if:</p>
                        <ol className="pb1rem">
                            <li>Your organisation or any employee is in breach of these Terms,</li>
                            <li>Your organisation’s use of the Services would cause a real risk of harm or loss to us or other users, or</li>
                            <li>Your organisation hasn't accessed our Services for 12 consecutive months.</li>
                            <li>Your organisation has ceased to pay for the relevant Services.</li>
                        </ol>
                        <p className="pb1rem">We’ll provide you with reasonable advance notice via the email address associated with your account to remedy the activity that prompted us to contact you and give you the opportunity to export Your Stuff from our Services. If after such notice you fail to take the steps we ask of you, we’ll terminate or suspend your access to the Services.</p>
                        <p className="pb1rem">We won’t provide notice or an opportunity to export Your Stuff before termination or suspension of access to the Services where:</p>
                        <ol className="pb1rem">
                            <li>you’re in material breach of these Terms,</li>
                            <li>doing so would cause us legal liability or compromise our ability to provide the Services to our other users, or</li>
                            <li>we're prohibited from doing so by law.</li>
                        </ol>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Discontinuation of Services</p>
                        <p className="pb1rem">We may decide to discontinue the Services in response to exceptional unforeseen circumstances, events beyond Diligentsia’s control (for example a natural disaster, fire or explosion) or to comply with a legal requirement. If we do so, we’ll give you reasonable prior notice so that you can export Your Stuff from our systems (we will give you no less than 30 days’ notice where possible under the circumstances). If we discontinue the Services in this way before the end of any fixed or minimum term you have paid us for, we’ll refund the portion of the fees you have pre-paid but haven't received Services for.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Services “AS IS”</p>
                        <p className="pb1rem">We strive to provide great Services, but there are certain things that we can't guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW, DILIGENTSIA AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED ‘AS IS’. WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. Some places don’t allow the disclaimers in this paragraph, so they may not apply to you. For example, these disclaimers do not override the legal protections, including statutory warranties granted to consumers by EU law.</p>
                        <p className='fw500 tal c14 fs1-25rem pb1rem pt1rem'>Limitation of Liability</p>
                        <p className="pb1rem">WE DON’T EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE ILLEGAL TO DO SO – THIS INCLUDES ANY LIABILITY FOR DILIGENTSIA’S OR ITS AFFILIATES’ FRAUD OR FRAUDULENT MISREPRESENTATION IN PROVIDING THE SERVICES. IN COUNTRIES WHERE THE FOLLOWING TYPES OF EXCLUSIONS AREN’T ALLOWED, WE'RE RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL OR OUR BREACH OF OUR CONTRACT WITH YOU. THIS PARAGRAPH DOESN’T AFFECT CONSUMER RIGHTS THAT CAN'T BE WAIVED OR LIMITED BY ANY CONTRACT OR AGREEMENT. IF YOU ARE AN EU OR UK CONSUMER, THESE TERMS DO NOT EXCLUDE DILIGENTSIA’S LIABILITY FOR LOSSES AND DAMAGES THAT ARE A RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL IN PROVIDING THE SERVICES OR OF OUR BREACH OF OUR CONTRACT WITH YOU, AS LONG AS THOSE LOSSES AND DAMAGES ARE REASONABLY FORESEEABLE.</p>
                        <p className="pb1rem">IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED, DILIGENTSIA, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WON’T BE LIABLE FOR:</p>
                        <ol className="pb1rem">
                            <li>ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR</li>
                            <li>ANY LOSS OF USE, DATA, BUSINESS OR PROFITS, REGARDLESS OF LEGAL THEORY.</li>
                        </ol>
                        <p className="pb1rem">THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT DILIGENTSIA OR ANY OF ITS AFFILIATES HAVE BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                        <p className="pb1rem">IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS OR RE-SALE PURPOSE, DILIGENTSIA, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WILL HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS OPPORTUNITY. DILIGENTSIA AND ITS AFFILIATES AREN’T RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USERS OF THE SERVICES.</p>
                        <p className="pb1rem">OTHER THAN FOR THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS DESCRIBED IN THIS SECTION), WE LIMIT OUR LIABILITY TO YOU TO THE GREATER OF £20 OR 100% OF ANY AMOUNT YOU'VE PAID UNDER YOUR CURRENT SERVICE PLAN WITH DILIGENTSIA. THIS PROVISION DOES NOT APPLY TO EU CONSUMERS WHERE PROHIBITED BY APPLICABLE LAW.</p>
                        <p className='fw500 tal c14 fs1-5rem pb1rem pt1rem'>Resolving Disputes</p>
                        <p className="pb1rem">Let’s try to sort things out first. We want to address your concerns without needing a formal legal case. Before filing a claim against Diligentsia, you agree to try to resolve the dispute informally by sending us a written Notice of Dispute at dispute-notice@Diligentsia.co.uk that includes your name, a detailed description of the dispute and the relief you seek. We’ll try to resolve the dispute informally by contacting you via email. If a dispute is not resolved within 60 days after submission, you or Diligentsia may bring a formal proceeding. If you reside in the EU, the European Commission provides for an online dispute resolution platform, which you can access here: https://ec.europa.eu/consumers/odr.</p>
                        <p className="pb1rem">Judicial Forum for Disputes. You and Diligentsia agree that any judicial proceeding to resolve claims relating to these Terms or the Services will be brought in the UK courts of England and Wales, and will be subject to UK law, subject to the mandatory arbitration provisions below. Both your organsation and Diligentsia consent to venue and personal jurisdiction in such courts. If you reside in a country (for example, a member state of the European Union) with laws that give consumers the right to bring disputes in their local courts, this paragraph doesn’t affect those requirements.</p>
                        <p className="pb1rem">IF YOUR ORGANISATION IS US RESIDENT, IT ALSO AGREES TO THE FOLLOWING MANDATORY ARBITRATION PROVISIONS:</p>
                        <p className="pb1rem">We both agree to arbitrate. You and Diligentsia agree to resolve any claims relating to these Terms or the Services through final and binding individual arbitration by a single arbitrator, except as set forth under the ‘Exceptions to Agreement to Arbitrate’ below. This includes disputes arising out of or relating to the interpretation or application of this ‘Mandatory Arbitration Provisions’ section, including its scope, enforceability, revocability or validity. The arbitrator may award relief only individually and only to the extent necessary to redress your individual claim(s); the arbitrator may not award relief on behalf of others or the general public.</p>
                        <p className="pb1rem">Opt out of Agreement to Arbitrate. You can decline this agreement to arbitrate by clicking here and submitting the opt-out form within 30 days of first registering your account or agreeing to these Terms. However, if you agreed to a previous version of these Terms that allowed you to opt out of arbitration, your previous choice to opt out or not opt out remains binding.</p>
                        <p className="pb1rem">Arbitration Procedures. The American Arbitration Association (AAA) will administer the arbitration under its Consumer Arbitration Rules. The AAA’s rules and filing instructions are available at www.adr.org or by calling 1-800-778-7879. The arbitration will be held in the United States county where you live or work, San Francisco (CA) or any other location we agree to.</p>
                        <p className="pb1rem">Arbitration Fees and Incentives. The AAA rules will govern payment of all arbitration fees. For individual arbitration of non-frivolous claims less than US$75,000 for which you timely provided Diligentsia with a Notice of Dispute, Diligentsia will reimburse arbitration filing fees at the conclusion of the arbitration and will pay other arbitration fees. For all other claims, the costs and fees of arbitration shall be allocated in accordance with the arbitration provider’s rules, including rules regarding frivolous or improper claims. If you receive an arbitration award that is more favourable than any offer we make to resolve the claim, we will pay you US$1,000 in addition to the award. Diligentsia will not seek its lawyers’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous or brought for an improper purpose.</p>
                        <p className="pb1rem">Exceptions to agreement to arbitrate. Either you or Diligentsia may assert claims, if they qualify, in a small claims court in San Francisco (CA) or any United States county where you live or work. Either party may bring a lawsuit solely for injunctive relief to stop unauthorised use or abuse of the Services, or intellectual property infringement (for example, trademark, trade secret, copyright or patent rights) without first engaging in arbitration or the informal dispute resolution process described above. If the agreement to arbitrate is found not to apply to you or your claim, you agree to the exclusive jurisdiction of the state and federal courts in San Francisco County, California, USA to resolve your claim.</p>
                        <p className="pb1rem">NO CLASS OR REPRESENTATIVE ACTIONS. You may only resolve disputes with us on an individual basis and may not bring a claim as a plaintiff or a class member in a class, consolidated or representative action. Class arbitrations, class actions, private lawyer general actions and consolidation with other arbitrations aren’t allowed.</p>
                        <p className="pb1rem">Severability. If any part of this ‘Mandatory Arbitration Provisions’ section is found to be illegal or unenforceable, the remainder will remain in effect, except that if a finding of partial illegality or unenforceability would allow class or representative arbitration, this ‘Mandatory Arbitration Provisions’ section will be unenforceable in its entirety. If you are found to have a non-waivable right to bring a particular claim or to request a particular form of relief that the arbitrator lacks authority to redress or award according to this ‘Mandatory Arbitration Provisions’ section, including public injunctive relief, then only that respective claim or request for relief may be brought in court, and you and we agree that litigation of any such claim or request for relief shall be stayed pending the resolution of any individual claim(s) or request(s) for relief in arbitration.</p>
                        <p className='fw500 tal c14 fs1-5rem pb1rem pt1rem'>Controlling Law</p>
                        <p className="pb1rem">These Terms will be governed by UK law. However, some countries (including those in the European Union) have laws that require agreements to be governed by the local laws of the consumer's country. This paragraph doesn’t override those laws.</p>
                        <p className='fw500 tal c14 fs1-5rem pb1rem pt1rem'>Entire Agreement</p>
                        <p className="pb1rem">These Terms constitute the entire agreement between you and Diligentsia with respect to the subject matter of these Terms and supersede and replace any other prior or contemporaneous agreements or terms and conditions applicable to the subject matter of these Terms. Our past, present and future affiliates and agents can invoke our rights under this agreement in the event they become involved in a dispute with you. Otherwise, these Terms do not give rights to any third parties.</p>
                        <p>Waiver, Severability & Assignment</p>
                        <p className="pb1rem">Diligentsia’s failure to enforce a provision is not a waiver of its right to do so later. If a provision is found to be unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under these Terms, and any such attempt will be void. Diligentsia may assign its rights to any of its affiliates or subsidiaries, or to any successor in the interest of any business associated with the Services.</p>
                        <p className='fw500 tal c14 fs1-5rem pb1rem pt1rem'>Modifications</p>
                        <p className="pb1rem">We may revise these Terms from time to time to better reflect:</p>
                        <ol className="pb1rem">
                            <li>changes to the law,</li>
                            <li>new regulatory requirements, or</li>
                            <li>improvements or enhancements made to our Services.</li>
                        </ol>
                        <p className="pb1rem">If an update affects your use of the Services or your legal rights as a user of our Services, we’ll notify you prior to the update's effective date by sending an email to the email address associated with your organisation’s account or via an in-product notification. These updated terms will be effective no less than 30 days from when we notify you.</p>
                        <p className="pb1rem">If you do not agree to the updates we make, please cancel your account and stop using the Services before the updated Terms become effective. Where applicable, we’ll offer you a pro-rata refund based on the amounts you have prepaid for Services and your account cancellation date. By continuing to use or access the Services after the updates come into effect, you agree to be bound by the revised Terms.</p>
                    </div>
                </div >
            </div>
        </AnimatedPage >
    )

}
export default IndividualsTerms