import React from "react";
import { Link } from "react-router-dom";

const AboutDiligentsiaSection = () => {
  return (
    <>
      <div className="df aic jcsb smfdc">
        <div className="df fdc aifs jcfs">
          <h3 className="asfs mb1-5rem c14 fw6 font-poppins">
            About Diligentsia
          </h3>
          <p className="c14 font-poppins asfs w80">
            Diligentsia Limited (
            <Link
              className="font-poppins"
              to="https://www.diligentsia.co.uk"
              target="_blank"
            >
              www.diligentsia.co.uk
            </Link>
            ) is an ultra-low-cost SaaS professional services AI platform
            founded to deliver value to early stage companies, their advisors
            and investors throughout a company's lifecycle, with AI at its
            core.
          </p>
        </div>

        <div>
          <img
            src="https://dev.diligentsia.co.uk/cogs-homepage.1628e455.gif"
            className="w100"
            alt="companyHeroAnimation"
          />
        </div>
      </div>
    </>
  );
};

export default AboutDiligentsiaSection;
