import React from "react";
import * as styles from "../../../pages/blogs/BlogPage.module.css";
import BlogCard from "../BlogCard/index";
import { ArrowDownward } from "@mui/icons-material";

function BlogGrid({ blogPosts, featuredPost, filtered }) {
  return (
    <>
      {
        filtered === false ? <section className="container">
          <article className={styles.featuredPost}>
            <BlogCard key={Math.random()} post={featuredPost} featured={true} />
          </article>
        </section> : null
      }

      <section className="container">
        <div className={`${styles.BlogGrid} ${filtered === false && "py4rem"}`}>
          {blogPosts.map((post, index) => (
            <BlogCard key={`blog-card-${index}`} post={post} />
          ))}
        </div>
        {
          blogPosts.length > 9 ? <div className="df jcc aic fdc my3rem">
            <div className={styles.showMoreBtn}>Show More</div>
            <ArrowDownward className="mt1rem" color="#060606" />
          </div> : null
        }

      </section>
    </>
  );
}

export default BlogGrid;
