import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { getRequest, showMessage } from "../../../../config.js";
import { toggleModal } from "../../../../reducers/modalReducer.js";

export default function ChecklistModules({ company, setTab, setIsWorkflowReport, setDashboardTab, setCompanyDashboardTab, }) {

    const dispatch = useDispatch();
    const navigation = useNavigate();

    const user = useSelector(state => state.user);
    const modal = useSelector(state => state.modal);


    const navigateSection = async (tab, sectionNo) => {
        await setTab(tab);
        setTimeout(() => {
            let element = document.getElementById(sectionNo);
            if (element) element.scrollIntoView({ behavior: 'instant' }, true);
        }, 1000);
    };

    const checklistModuleTooltip = (sections) => {
        return (
            <div className='cp module-tooltip-box'>
                <div className='hover-list'><img src={require('../../../../../images/hover-list-icon.png')} className="pa" alt="list-img" /></div>
                {sections && sections.length > 0 &&
                    <span className="tooltip-area">
                        {sections.map((section, idx) => {
                            let completeness = section?.['stats']?.['completionPercent'] ? section?.['stats']?.['completionPercent'] : 0;

                            let riskColour;
                            if (section?.['stats']?.['riskPercent'] < 38) riskColour = 7;
                            else if (section?.['stats']?.['riskPercent'] > 62) riskColour = 9;
                            else riskColour = 8;

                            let hexColour = riskColour == 8 ? "#ddb64044" : riskColour == 9 ? "#dd404044" : "#40dda144";

                            return <button type='button' key={idx} onClick={async () => { await navigateSection(section['moduleName'], section['sectionNo']); setDashboardTab('Company Dashboard'); setCompanyDashboardTab('Main Dashboard') }} style={{ background: `linear-gradient(90deg, ${hexColour} 0%, ${hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section['sectionNo']} className="overlay-box tal w100 tac cp c3-hover"><span>{section['sectionName']}</span></button>
                        })}
                    </span>
                }
            </div>
        )
    };

    const redirectWorkflowTab = async (moduleName) => {
        await setIsWorkflowReport(true);
        await setTab(`${moduleName}-report`);
        setDashboardTab('Company Dashboard');
        setCompanyDashboardTab('Main Dashboard');
    };

    const sortModules = (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
    };

    const checklistModules = (modules) => {
        return <>{Object.values(modules).length > 0
            ? <>{Object.values(modules).map(module => {
                let isRedirect = false;
                if (module?.['stats'].completionPercent > 0) isRedirect = true;

                return <div className={`bo7 w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem mx1rem my1-5rem pr bsbb advisor-bg`}>
                    <Link className="ttc fs1rem fw500 c15 lh2 zi1 w100">
                        <div className='right-info-hyper-icon right-info-dashboard-area' style={{ right: '-60px' }}>
                            <img className="w50 bg1" src={require(`../../../../../images/thecfocenter.png`)} alt="" />
                        </div>
                        {module?.['sections'] && checklistModuleTooltip(module?.['sections'])}
                        <Link to={isRedirect && `#${module['type']}-report`} onClick={() => { isRedirect ? redirectWorkflowTab(module['type']) : showMessage('Module not started yet!') }} class="cp w100 h100" style={{ minHeight: '130px' }}>
                            <div className='w100 df aic'>
                                <i className='icon-diligenstia mr1rem'>
                                    <img className="br5px" src={module?.['imageURL'] || require(`../../../../../images/cfo-center.png`)} alt="" />
                                </i>
                                <button className='cp fs1rem fw500 bg1 c4-hover c15 lh2 title-box-feature tal advisor-bg-lite'>{module['type']}</button>
                            </div>
                            <div className="w100 df jcsb">
                                <p className="c12 fw300 fs0-75rem mt4rem">{(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']) ? moment(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']).format('L') : '-'}</p>
                                <div className="df fdc jcc aic">
                                    <div className={`progress-bar workflow-report-progress ${module?.stats?.workflowCompletionPercent > 75 ? 'workflow-progress-green' : module?.stats?.workflowCompletionPercent > 26 ? 'workflow-progress-yellow' : module?.stats?.workflowCompletionPercent > 1 ? ' workflow-progress-pink' : '  workflow-progress-grey'}`}
                                        style={{ width: 50, height: 50, borderRadius: '50%', margin: 0, border: '2px solid #F1F1F1' }}>
                                        <CircularProgressbar
                                            text={module?.stats?.workflowCompletionPercent ? (module?.stats?.workflowCompletionPercent) + "%" : 'N/A'}
                                            styles={buildStyles({
                                                strokeWidth: '0',
                                                textSize: '20px',
                                                trailColor: '#FBFBFB',
                                                stroke: 'transparent',
                                                strokeLinecap: 'butt',
                                                backgroundColor: '#FBFBFB',
                                                pathTransitionDuration: 0.5,
                                                dominantBaseline: 'ideographic',
                                            })}
                                        />
                                    </div>
                                    <span style={{ marginBottom: "0", }} className="tac c15 fs0-75rem fw300">Workflow <br /> Completeness</span>
                                </div>
                            </div>

                        </Link>
                    </Link>
                </div>
            })}</>
            : <h3 className="w100 fs2rem fw500 tac c3 pl2rem mb1rem">No Checklist Modules</h3>
        }</>

        return Object.values(modules).map(module => {
            let isRedirect = false;
            if (module?.['stats'].completionPercent > 0) isRedirect = true;

            return <div className={`bo7 w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem mx1rem my1-5rem pr bsbb advisor-bg`}>
                <Link className="ttc fs1rem fw500 c15 lh2 zi1 w100">
                    <div className='right-info-hyper-icon right-info-dashboard-area' style={{ right: '-60px' }}>
                        <img className="w50 bg1" src={require(`../../../../../images/thecfocenter.png`)} alt="" />
                    </div>
                    {module?.['sections'] && checklistModuleTooltip(module?.['sections'])}
                    <Link to={isRedirect && `#${module['type']}-report`} onClick={() => { isRedirect ? redirectWorkflowTab(module['type']) : showMessage('Module not started yet!') }} class="cp w100 h100" style={{ minHeight: '130px' }}>
                        <div className='w100 df aic'>
                            <i className='icon-diligenstia mr1rem'>
                                <img className="br5px" src={module?.['imageURL'] || require(`../../../../../images/cfo-center.png`)} alt="" />
                            </i>
                            <button className='cp fs1rem fw500 bg1 c4-hover c15 lh2 title-box-feature tal advisor-bg-lite'>{module['type']}</button>
                        </div>
                        <div className="w100 df jcsb">
                            <p className="c12 fw300 fs0-75rem mt4rem">{(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']) ? moment(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']).format('L') : '-'}</p>
                            <div className="df fdc jcc aic">
                                <div className={`progress-bar workflow-report-progress ${module?.stats?.workflowCompletionPercent > 75 ? 'workflow-progress-green' : module?.stats?.workflowCompletionPercent > 26 ? 'workflow-progress-yellow' : module?.stats?.workflowCompletionPercent > 1 ? ' workflow-progress-pink' : '  workflow-progress-grey'}`}
                                    style={{ width: 50, height: 50, borderRadius: '50%', margin: 0, border: '2px solid #F1F1F1' }}>
                                    <CircularProgressbar
                                        text={module?.stats?.workflowCompletionPercent ? (module?.stats?.workflowCompletionPercent) + "%" : 'N/A'}
                                        styles={buildStyles({
                                            strokeWidth: '0',
                                            textSize: '20px',
                                            trailColor: '#FBFBFB',
                                            stroke: 'transparent',
                                            strokeLinecap: 'butt',
                                            backgroundColor: '#FBFBFB',
                                            pathTransitionDuration: 0.5,
                                            dominantBaseline: 'ideographic',
                                        })}
                                    />
                                </div>
                                <span style={{ marginBottom: "0", }} className="tac c15 fs0-75rem fw300">Workflow <br /> Completeness</span>
                            </div>
                        </div>

                    </Link>
                </Link>
            </div>
        });
    };

    return (
        <>
            <div className='sectionWrapper py1rem fs0-75rem pr anylist-builder-tab'>
                <button className="df cp bg1 br50px mr0-5rem bw2px bss pr1rem p0-5rem bsbb aic mr1rem cp add-btn-dashboard pa t0 r0 audit-log-btn" style={{ width: '206px', marginBottom: '1rem' }}>
                    <span className='bg1 bsbb mr0-5rem br100 w40px h40px tac df jcc aic'>
                        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3623_3962)">
                                <path d="M19 0C8.52298 0 0 8.52298 0 19C0 29.477 8.52298 38 19 38C29.477 38 38 29.477 38 19C38 8.52298 29.477 0 19 0Z" fill="#4A67FF" />
                                <path d="M27.3098 20.5805H20.5805V27.3098C20.5805 28.1839 19.8714 28.893 18.9973 28.893C18.1232 28.893 17.4141 28.1839 17.4141 27.3098V20.5805H10.6848C9.8107 20.5805 9.10156 19.8714 9.10156 18.9973C9.10156 18.1232 9.8107 17.4141 10.6848 17.4141H17.4141V10.6848C17.4141 9.8107 18.1232 9.10156 18.9973 9.10156C19.8714 9.10156 20.5805 9.8107 20.5805 10.6848V17.4141H27.3098C28.1839 17.4141 28.893 18.1232 28.893 18.9973C28.893 19.8714 28.1839 20.5805 27.3098 20.5805Z" fill="#FAFAFA" />
                            </g>
                            <defs>
                                <clipPath id="clip0_3623_3962">
                                    <rect width="38" height="38" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <span className='c15'>Add/Edit Checklist</span>
                </button>
                <div className="mt1rem bss px2rem py1rem br25px w100 keyfeature-box-portfolio anylist-checklist df fww mb2rem pr bsbb" style={{ backgroundColor: '#ff00960a' }}>
                    <div className='mt1rem bss px1rem py2rem br25px w100 keyfeature-box-portfolio anylist-checklist df fww mb1rem pr bsbb'>
                        <div className="w100 df jcsb aic px2rem mb1rem">
                            <h3 className="fs1-25rem fw500 c15">{user['role']} - Internal Use Only</h3>
                            {/* <img className="pr2rem" src={require('../../../../../images/thecfocenter.png')} alt="list-img" /> */}
                        </div>
                        <h3 className="w100 fs1-25rem fw500 c15 pl2rem mb1rem">The 12 Boxes</h3>
                        {(company?.['modules'] && Object.values(company['modules']).length > 0) && checklistModules(Object.values(company['modules']).filter(item => item['category'] == 'internal').sort(sortModules))}
                    </div>
                    <div className='mt0-5rem bss px1rem py2rem br25px w100 keyfeature-box-portfolio anylist-checklist df fww mb1rem pr bsbb'>
                        <h3 className="fs1-25rem fw500 c15 w100 pl2rem mb1rem">{user['role']} - Generic Checklists - Across All Clients</h3>
                        {(company?.['modules'] && Object.values(company['modules']).length > 0) && checklistModules(Object.values(company['modules']).filter(item => item['category'] == 'external').sort(sortModules))}
                        {/* <div className="w100 df jcfe pr2rem my1rem">
                            <img src={require('../../../../../images/numbers-that-matter.png')} alt="list-img" />
                        </div> */}
                    </div>
                </div>
                {/* <div className='mt2rem bss px1rem py2rem br25px w100 keyfeature-box-portfolio anylist-checklist df fww mb1rem pr bsbb'>
                    <div className="w100 df jcsb aic px2rem mb1rem">
                        <h3 className="fs1-25rem fw500 c15">Advisor - Generic Checklists - Across All Clients</h3>
                        <img className="pr2rem" src={require('../../../../../images/liberti-group.png')} alt="list-img" />
                    </div>
                    {(company?.['modules'] && Object.values(company['modules']).length > 0) && checklistModules(Object.values(company['modules']).filter(item => item['category'] == 'all'))}
                </div> */}
            </div>
        </>
    )
}