import moment from "moment";

export default function PassFailCertification({ className, testType, level, expiryTimestamp, tab }) {

	let expires = "";
	if (expiryTimestamp && level !== 'None') {
		let date = new Date(expiryTimestamp);
		expires = date.setMonth(date.getMonth() + 3)
	}

	return (
		<>
			{(level == "Pass" || level == "Fail") && (tab && tab.match(/two-minute-test-.*-report/)) &&
				<div className="tar tooltip-box w100 db">
					<i className="icon"><img style={{ height: '20px' }} src={require(`../../images/info-icon.svg`)} alt="info" /></i>
					<span className="tooltip-area" style={{ width: "305px", top: "95px", right: "-240px" }}>
						<div className="overlay-box">
							{level == "Pass"
								? <p className="fs0.8rem">🎉 Hurrah! You've splendidly conquered the Two-Minute Test! 🎉 With every question answered and a risk score of less than 50%, you've truly showcased your business prowess.
									But remember, this is merely a teaser of the grand adventure our platform can embark upon with your business.
									Curious to see what lies beyond? Then, my noble ally, click the 'Pro Certification' button below. Witness how our Pro Certification weaves its magic, yielding a badge of honor you can proudly display to your investors, partners, and advisors. Let's proceed! ⚔️🚀</p>
								: <p className="fs0.8rem">🛡️ Stay strong, brave adventurer! 🛡️ It appears the Two-Minute Test posed quite a challenge. Either not all the questions were answered, or your risk score surged over 50%.
									Don't lose heart, though! This is merely a brief glimpse of the incredible journey our platform offers your business. It's time to level up and harness the power of our Lite and Pro tools to turn the tide in your favor.
									Interested in embarking on this transformative quest? Click on the 'Pro Certification' button below. Discover how our Pro Certification can enhance your standing, yielding a badge of trust to present to your investors, partners, and advisors. Onwards to victory! ⚔️🚀</p>
							}
						</div>
					</span>
				</div>
			}
			{level && <div className={className + ` pr df aic ${level == "None" ? 'c6' : 'c1'}`}>
				{level == 'None' && <img style={level == "None" ? { filter: "" } : null} src={require(`../../images/white.png`)} className={tab == "test-certification" ? "w310px" : "w250px"} alt='Test Certificate' />}
				{level == 'Pass' && <img style={level == "None" ? { filter: "" } : null} src={require(`../../images/pass.png`)} className={tab == "test-certification" ? "w310px" : "w250px"} alt='Test Certificate' />}
				{level == 'Fail' && <img style={level == "None" ? { filter: "" } : null} src={require(`../../images/fail.png`)} className={tab == "test-certification" ? "w310px" : "w250px"} alt='Test Certificate' />}
				<div className="pr t0 l0 w100 h100 df jcsa aic py0-5rem px1rem bsbb pr certificate-fail-pass">
					<div className="toparea pa">
						<p className={`tac ttc c14 mt0-5rem ${tab == "test-certification" ? "fs1-5rem" : "fs0-5rem"}`}>{`2 Min`}</p>
						<p className={`tac ttc c14 lh1 ${tab == "test-certification" ? "fs1-5rem" : "fs0-5rem"}`}>{`${testType} Ready Test`}</p>
					</div>
					<div className={`tac pa bottom-area ${tab == "test-certification" ? "fs1-5rem" : "fs0-5rem"}`}>
						{/* <p>Valid Until</p> */}
						<p className={`c2 fw600 ${tab == "test-certification" ? "fs1-5rem" : "fs0-5rem"}`}>{expires ? moment(expires).format('DD/MMM/YY') : "-"}</p>
					</div>
					{/* <div>
						{level == "None"
							? <img className="db w7rem" src={require(`../../images/Dg-logo.png`)} alt="Diligentsia Logo" />
							: <img className="db w7rem" src={require(`../../images/logo_white.png`)} alt="Diligentsia Logo" />
						}
						
						<h4 className={`tac ${tab == "test-certification" ? "fs1-5rem" : "fs0-75rem"}`}>{level}</h4>
					</div> */}
				</div>
			</div>
			}
		</>
	);
}
