import React from 'react'

const Button = ({ text, onClick, icon, loading, btnType, customClassName }) => {

    const btnClasses = btnType === "warning" ? "bg4 " : "bg3"

    return (
        <div onClick={onClick} disabled={loading} className={`shadow bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac mb1rem df jcc aic ${loading && 'button-disabled'} ${btnClasses} ${customClassName}`}>
            {text}
            {icon}
        </div>
    )
}

export default Button