/**
 * This code was generated by Builder.io.
 */
.blogPost {
  max-width: none !important;
}

.mainContent {
  background-color: #fff;
  padding-top: 33px;
  overflow: hidden;
}
.blogBannerCardTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.blogBannerCardTitle .featureTitleImage {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.SecondaryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.SecondaryCardHeading {
  color: #151313;
  margin-bottom: 0px !important;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 600;
}

.ColorRed {
  color: #fd6474;
}
.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 61px;
}

@media (max-width: 991px) {
  .header {
    padding: 0 20px;
  }
}

.headerContent {
  display: flex;
  width: 100%;
  max-width: 1713px;
  gap: 20px;
  color: #505050;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 400 16px/1 Poppins, sans-serif;
}

@media (max-width: 991px) {
  .headerContent {
    max-width: 100%;
  }
}

.logo {
  aspect-ratio: 4.63;
  object-fit: contain;
  object-position: center;
  width: 231px;
  max-width: 100%;
}

.navigation {
  display: flex;
  gap: 34px;
  flex-wrap: wrap;
  margin: auto 0;
}

@media (max-width: 991px) {
  .navigation {
    max-width: 100%;
  }
}

.navList {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: start;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  list-style-type: none;
  padding: 0;
}

.navItem {
  align-self: stretch;
  margin: auto 0;
  text-decoration: none;
  color: inherit;
}

.active {
  color: #151313;
  font-weight: 600;
}

.headerDecoration {
  aspect-ratio: 6.8;
  object-fit: contain;
  object-position: center;
  width: fit-content;
  border-radius: 15px;
  flex-grow: 1;
  flex-basis: 0;
}

.headerImage {
  aspect-ratio: 2.8;
  object-fit: contain;
  object-position: center;
  width: 100%;
  margin-top: 31px;
}

@media (max-width: 991px) {
  .headerImage {
    max-width: 100%;
    margin-right: 2px;
  }
}

.blogContent {
  padding: 0 65px;
}

.authorInfo {
  display: flex;
  gap: 9px;
  font-family: Poppins, sans-serif;
  color: #4c6b92;
  line-height: 1;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .authorInfo {
    margin-top: 40px;
  }
}

.authorAvatar {
  border-radius: 43px;
  width: 49px;
  height: 49px;
  background-color: #d3d9fc;
}

.authorDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  margin: auto 0;
}

@media (max-width: 991px) {
  .authorDetails {
    max-width: 100%;
  }
}

.authorName {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.authorTitle {
  font-weight: 400;
}

.postDate {
  font-size: 12px;
  font-weight: 500;
  align-self: start;
  margin-top: 6px;
}

.blogTitle {
  color: #151313;
  font-size: 32px;
  font-weight: 700;
  margin-top: 20px;
}

.blogText {
  color: #505050;
  margin-top: 38px;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins";
}

.blogText h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #151313;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 900;
}

.blogText h3 {
  font-size: 18px;
  margin-top: 32px;
  margin-bottom: 4px;
  font-family: "Poppins";
}

.blogText li {
  display: flex;
  font-family: "Poppins";
}

.blogText ul {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: "Poppins";
}

.blogText li p {
  margin-left: 12px;
  font-family: "Poppins";
}

.blogText p {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
  color: #505050;
}

.BlogCard {
  color: #505050;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins";
}

.BlogCard h2 {
  margin: 8px;
  color: #151313;
  font-family: "Poppins";
}

.BlogCard h3 {
  margin-top: 20px;
  font-family: "Poppins";
}

.BlogCard li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-family: "Poppins";
}

.BlogCard ul {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: "Poppins";
}

.BlogCard li p {
  margin-left: 12px;
  font-family: "Poppins";
}

.BlogCard p {
  font-size: 17px;
  font-weight: 300;
  font-family: "Poppins";
}

@media (max-width: 991px) {
  .blogText {
    max-width: 100%;
  }
}

.featureSection {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  border-radius: 60px;
  padding: 20px 50px 0;
  background-color: #f9faff;
  box-shadow: 0px 5px 52px rgba(7, 7, 7, 0.09);
  box-sizing: border-box;
}

.customUL {
  list-style-type: disc !important;
}
.customList {
  list-style-type: decimal !important;
}
.customUL li,
.customList li {
  display: list-item;
  margin-left: 35px;
  margin-top: 6px;
}
.customUL li p,
.customList li p {
  margin-left: 0px;
}

.customList li span {
  font-weight: 600;
}

.listTopic {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  white-space: nowrap;
  display: contents;
}

.featureContent {
  width: 60%;
}

.featureImage {
  width: 40%;
}
.secondaryCardImage {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .featureSection {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .featureContent {
    width: 100%;
  }
}

.featureTitle {
  color: #151313;
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 16px;
}

.featureList {
  padding-left: 50px;
  font-size: 20px;
  color: #505050;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 1.4;
  list-style-type: none;
  position: relative;
}

@media (max-width: 991px) {
  .featureList {
    padding-left: 20px;
  }
}

.featureItem {
  position: relative;
  padding-left: 12px;
}

.featureItem::before {
  content: "";
  position: absolute;
  left: -38px;
  top: 0px;
  width: 21px;
  height: 21px;
  background-color: #d3d9fc;
  border-radius: 50%;
}

.featureList::before {
  content: "";
  background-color: #d3d9fc;
  width: 2px;
  height: calc(100% - 2.5rem);
  position: absolute;
  top: 1.25rem;
  left: 22px;
}

.latestBlogPosts {
  margin-top: 80px;
  border-top: 4px solid #f1f3f4;
  padding-top: 20px;
}

@media (max-width: 991px) {
  .latestBlogPosts {
    margin-top: 40px;
    padding: 0 20px;
  }
}

.sectionTitle {
  color: #151313;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 28px;
}

.blogPostGrid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.blogPostCard {
  width: calc(33.333% - 14px);
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .blogPostCard {
    width: 100%;
  }
}

.blogPostImage {
  aspect-ratio: 1.38;
  object-fit: cover;
  width: 100%;
  border-radius: 40px;
}

.blogPostCategories {
  margin-top: 33px;
}

.category {
  color: #44d991;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.99px;
}

.category:not(:last-child)::after {
  content: "|";
  color: #505050;
  font-weight: 400;
  margin: 0 5px;
}

.blogPostTitle {
  color: #151313;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 21px;
}

.blogPostExcerpt {
  color: #505050;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 15px;
}

.blogPostAuthor {
  display: flex;
  margin-top: 12px;
  gap: 4px;
  color: #4c6b92;
  line-height: 1;
}

.authorAvatar {
  aspect-ratio: 1;
  object-fit: cover;
  width: 49px;
  border-radius: 43px;
  margin: auto 0;
}

.authorInfo {
  display: flex;
  flex-direction: column;
}

.authorName {
  font-size: 14px;
  font-weight: 600;
}

.authorTitle {
  font-weight: 400;
}

.postDate {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}

.blogFooter {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

@media (max-width: 991px) {
  .blogFooter {
    margin-top: 40px;
  }
}

.footerContent {
  display: flex;
  justify-content: space-between;
  padding: 0 61px;
  max-width: 1438px;
  margin: 73px auto 0;
}

@media (max-width: 991px) {
  .footerContent {
    flex-direction: column;
    padding: 0 20px;
  }
}

.companyInfo {
  width: 38%;
}

@media (max-width: 991px) {
  .companyInfo {
    width: 100%;
    margin-bottom: 40px;
  }
}

.footerLogo {
  aspect-ratio: 4.63;
  object-fit: contain;
  width: 185px;
}

.companyAddress {
  color: #151313;
  font: 400 14px/24px Poppins, sans-serif;
  margin-top: 32px;
}

.footerNav {
  display: flex;
  width: 62%;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .footerNav {
    width: 100%;
    flex-wrap: wrap;
  }
}

.footerNavColumn {
  display: flex;
  flex-direction: column;
}

.footerNavTitle {
  color: #151313;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 34px;
}

.footerNavList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footerNavList li {
  color: #151313;
  font-size: 14px;
  line-height: 30px;
}

.socialIcons {
  aspect-ratio: 4.29;
  object-fit: contain;
  width: 103px;
  margin-top: 34px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 768px) {
  .blogContent, .bannerBlogTitle  {
    padding: 0 12px;
  }
  .bannerBlogTitle  {
    font-size: 18px;
  }
  .listTopic {
    white-space: unset;
    font-size: 16px;
  }
  .blogText ul{
    width: 100%;
  }
  .blogText .df.aifs {
    flex-direction: column;
  }
  .blogText img {
    width: 100%!important;
  }
  .featureTitle {
    font-size: 20px;
    line-height: 1.5;
  }
  .featureSection {
    padding: 20px 40px !important;
  }
  .featureList:before{
    left: 0px !important;
  }
  .featureItem:before {
    left: -30px!important;
  }
  .BlogCard li {
    font-size: 14px;
  }
  .featureImage {
    width: 100%;
  }
  .blogBannerCardTitle .featureTitleImage {
    width: 30px;
    height: 30px;
  }
  .latestBlogPosts {
    padding: 20px 0px;
  }
}
