import React from "react";
import * as styles from "./BlogDetailWarpper.module.css";

const BlogDetailWarpper = ({ children, bannerImage, title, categories }) => {
  return (
    <div className={`${styles.blogDetailPage}`}>
      <div className={`container py4rem`}>
        <div className={`pr ${styles.blogDetailHero}`}>
          <img src={bannerImage} alt={title} className="w100 h100 ofcov" loading="lazy" />
          <div className={styles.heroSectionFooter}>
            <div className="df aic jcfs w100 h100 pr">
              <div className={styles.BlogCategories}>
                {categories.map((category, catIndex) => {
                  return (
                    <>
                      <div
                        key={`${catIndex}-blogCateogry-${category}`}
                        className={styles.BlogCategory}
                      >
                        {category}
                      </div>
                    </>
                  );
                })}
              </div>
              <p className={styles.bannerBlogTitle}>{title}</p>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default BlogDetailWarpper;
