import { useState } from 'react';
import Modal from "@mui/material/Modal";
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { toggleModal } from "../../reducers/modalReducer";

export default function LogoPermission({ isManualAI, setIsManualAI, setStepTab }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [accordion, setAccordion] = useState('');
    const modal = useSelector((state) => state.modal);
    const [userProfile, setUserProfile] = useState("");

    return (
        <>
            <div className='checklist-form-area df fww w100'>
                <div className='checklist-form w100 mb5rem'>
                    <Accordion expanded={accordion == 'one'} onChange={() => setAccordion('one')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='fw500'>1. Logo upload</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='df fdc jcsb'>
                                <div className='discription-box df fww'>
                                    <div className='left-box w60'>
                                        <div className='pr mb1rem'>
                                            <input
                                                id='companiesAlready'
                                                name='registerAs'
                                                style={{ height: '19px', width: '19px', top: '4px' }}
                                                type='radio'
                                                value='companiesAlready'
                                                className='pr'
                                                checked
                                            // checked={registerAs == 'companiesAlready'}
                                            // onChange={(e) => handleRegisterAs(e['target']['value'])}
                                            />
                                            <label className='fw400 fs1-125rem ml1rem c15' for='companiesAlready' style={{ lineHeight: '22.39px' }}>Use your existing company logo​</label>
                                        </div>
                                        <span className='pr db ml2rem mb1rem'>
                                            <img className="db" src={require(`../../../images/logo-ext.png`)} alt="" />
                                        </span>
                                        <div className='pr mb1rem'>
                                            <input
                                                id='companiesAlready'
                                                name='registerAs'
                                                style={{ height: '19px', width: '19px', top: '4px' }}
                                                type='radio'
                                                value='companiesAlready'
                                                className='pr'
                                            // checked={registerAs == 'companiesAlready'}
                                            // onChange={(e) => handleRegisterAs(e['target']['value'])}
                                            />
                                            <label className='fw400 fs1-125rem ml1rem c15' for='companiesAlready' style={{ lineHeight: '22.39px' }}>Upload alternative company logo​​</label>
                                        </div>
                                        <button onClick={() => { dispatch(toggleModal({ open: "uploadProfile" })) }} className='pr ml2rem bsbb jcc w9rem df aic fw500 py0-5rem px1rem c1 bg3 bg4-hover br8px cp h50px'>
                                            <i className='icon pr mr0-5rem' style={{ top: '4px' }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.6087 4.78261H15.5217L14.3696 3.04348C13.9348 2.3913 13.1739 2 12.3913 2H7.6087C6.82609 2 6.06522 2.3913 5.63043 3.04348L4.47826 4.78261H2.3913C1.06522 4.78261 0 5.84783 0 7.17391V15.6087C0 16.9348 1.06522 18 2.3913 18H17.6087C18.9348 18 20 16.9348 20 15.6087V7.17391C20 5.84783 18.9348 4.78261 17.6087 4.78261ZM10 16.0435C7.02174 16.0435 4.6087 13.6304 4.6087 10.6522C4.6087 7.67391 7.02174 5.28261 10 5.28261C12.9783 5.28261 15.3913 7.69565 15.3913 10.6739C15.3913 13.6304 12.9783 16.0435 10 16.0435ZM17.3043 8.15217C17.2826 8.15217 17.2609 8.15217 17.2174 8.15217H16.3478C15.9565 8.13043 15.6522 7.80435 15.6739 7.41304C15.6957 7.04348 15.9783 6.76087 16.3478 6.73913H17.2174C17.6087 6.71739 17.9348 7.02174 17.9565 7.41304C17.9783 7.80435 17.6957 8.13043 17.3043 8.15217Z" fill="white" />
                                                    <path d="M10 7.67578C8.34783 7.67578 7 9.02361 7 10.6758C7 12.328 8.34783 13.654 10 13.654C11.6522 13.654 13 12.3062 13 10.654C13 9.00187 11.6522 7.67578 10 7.67578Z" fill="white" />
                                                </svg>
                                            </i>
                                            Upload
                                        </button>
                                        <span className='fs0-75rem db ml2rem'>Max file size 5MB</span>
                                    </div>
                                    <div className='right-box w40 bsbb pr1rem pl4rem'>
                                        <div className='tip-box w100 p2rem bg20 w100 bsbb' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                                <i className='icon mr1rem'>
                                                    <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                                </i>
                                                Example
                                            </h5>
                                            <img className="db mt1rem" src={require(`../../../images/example-1.png`)} alt="" />
                                            <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                                                Logo will appear on the face of the checklist.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={accordion == 'two'} onChange={() => setAccordion('two')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='fw500'>2. Checklist access permissions <span className='c40'>*</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='df fdc jcsb'>
                                <div className='discription-box df fww mt0-5rem'>
                                    <h5 className='fw400 c15 fs1rem w100'>Choose team members you want to grant access to</h5>
                                    <div className='left-box w70'>
                                        <div className='pr mt1rem'>
                                            <label for='team1' className='py0-5rem df cp'>
                                                <span className='checkbox w2rem h2rem pr'>
                                                    <input id='team1' className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' />
                                                </span>
                                                <span className='w250px fs1rem lh3 ml1rem usn df aic bo5 bw2px bss px1rem py0-5rem br8px'>
                                                    <i className='user-img mr1rem pr' style={{ top: '4px' }}>
                                                        <img className="w2rem h2rem br100 bo5 bw2px bss" src={require(`../../../images/pic.png`)} alt="" />
                                                    </i>
                                                    <div className='text-box'>
                                                        <h5 className='fs0-75rem'>Team Member 1</h5>
                                                        <i className='fs0-75rem db' style={{ marginTop: '-3px' }}>Position</i>
                                                    </div>
                                                </span>
                                            </label>
                                            <label for='team2' className='py0-5rem df cp'>
                                                <span className='checkbox w2rem h2rem pr'>
                                                    <input id='team2' className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' />
                                                </span>
                                                <span className='w250px fs1rem lh3 ml1rem usn df aic bo5 bw2px bss px1rem py0-5rem br8px'>
                                                    <i className='user-img mr1rem pr' style={{ top: '4px' }}>
                                                        <img className="w2rem h2rem br100 bo5 bw2px bss" src={require(`../../../images/pic-1.png`)} alt="" />
                                                    </i>
                                                    <div className='text-box'>
                                                        <h5 className='fs0-75rem'>Team Member 2</h5>
                                                        <i className='fs0-75rem db' style={{ marginTop: '-3px' }}>Position</i>
                                                    </div>
                                                </span>
                                            </label>
                                            <label for='team3' className='py0-5rem df cp'>
                                                <span className='checkbox w2rem h2rem pr'>
                                                    <input id='team3' className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' checked />
                                                </span>
                                                <span className='w250px fs1rem lh3 ml1rem usn df aic bo5 bw2px bss px1rem py0-5rem br8px'>
                                                    <i className='user-img mr1rem pr' style={{ top: '4px' }}>
                                                        <img className="w2rem h2rem br100 bo5 bw2px bss" src={require(`../../../images/pic-2.png`)} alt="" />
                                                    </i>
                                                    <div className='text-box'>
                                                        <h5 className='fs0-75rem'>Team Member 3</h5>
                                                        <i className='fs0-75rem db' style={{ marginTop: '-3px' }}>Position</i>
                                                    </div>
                                                </span>
                                            </label>
                                            <label for='team4' className='py0-5rem df cp'>
                                                <span className='checkbox w2rem h2rem pr'>
                                                    <input id='team4' className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' />
                                                </span>
                                                <span className='w250px fs1rem lh3 ml1rem usn df aic bo5 bw2px bss px1rem py0-5rem br8px'>
                                                    <i className='user-img mr1rem pr' style={{ top: '4px' }}>
                                                        <img className="w2rem h2rem br100 bo5 bw2px bss" src={require(`../../../images/profile-photo.png`)} alt="" />
                                                    </i>
                                                    <div className='text-box'>
                                                        <h5 className='fs0-75rem'>Team Member 4</h5>
                                                        <i className='fs0-75rem db' style={{ marginTop: '-3px' }}>Position</i>
                                                    </div>
                                                </span>
                                            </label>
                                            <label for='team5' className='py0-5rem df cp'>
                                                <span className='checkbox w2rem h2rem pr'>
                                                    <input id='team5' className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' checked />
                                                </span>
                                                <span className='w250px fs1rem lh3 ml1rem usn df aic bo5 bw2px bss px1rem py0-5rem br8px'>
                                                    <i className='user-img mr1rem pr' style={{ top: '4px' }}>
                                                        <img className="w2rem h2rem br100 bo5 bw2px bss" src={require(`../../../images/profile-photo.png`)} alt="" />
                                                    </i>
                                                    <div className='text-box'>
                                                        <h5 className='fs0-75rem'>Team Member 5</h5>
                                                        <i className='fs0-75rem db' style={{ marginTop: '-3px' }}>Position</i>
                                                    </div>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='right-box w30 bsbb pr1rem pl4rem'>
                                        <div className='tip-box w100 p2rem bg20 w100 bsbb' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                                <i className='icon mr1rem'>
                                                    <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                                </i>
                                                Top Tip
                                            </h5>
                                            <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>You can allocate access permissions​ to your colleagues in the ​company/companies you have selected to​ receive this checklist​.
                                                <br /><br />You can also later amend these permissions from your dashboard.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className='bottom-btn-area df aic w100 jcsb mt5rem bsbb'>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>5/6 Question Creation</p>
                <button onClick={() => setStepTab('Question Creation')} className='shadow c1 bg4 bg3-hover c1 cp fw400 fs1rem pr br38px w200px h3rem df aic jcc'>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>

            {/* Upload Profile */}
            <Modal
                open={modal["open"] == "uploadProfile"}
                onClose={() => dispatch(toggleModal({ open: "" }))}
                className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
                <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
                    <button className="close-popup" onClick={() => { setUserProfile(""), dispatch(toggleModal({ open: "" })) }}>
                        <svg style={{ position: "relative", top: "4px" }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path
                                className="pen fa-primary"
                                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                            />
                        </svg>
                    </button>
                    <h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">
                        Upload Profile Picture
                    </h1>
                    {userProfile == "" ? (
                        <label for="profile" className="w100 bsbb bg5 br5px tac p1rem bsd bo6 bw2px cp pr upload-pic-area">
                            <span className="pen fs1rem fw500 tac">Upload picture</span>
                            <br />
                            <div className="">
                                <input className="" onChange={(e) => handleProfile(e)} id="profile" type="file" accept="image/jpeg, image/jpg, image/png" />
                            </div>
                            <label>Max file size 5MB</label>
                        </label>
                    ) : (
                        <div className="img-box pr">
                            <Cropper
                                style={{ maxHeight: "500px" }}
                                ref={cropper}
                                guides={false}
                                aspectRatio={1 / 1}
                                className="cropper-settings"
                                src={userProfile}
                            />
                        </div>
                    )}
                    <div className="df jcse">
                        <span
                            onClick={() => { setUserProfile(""), dispatch(toggleModal({ open: "" })); }}
                            className="shadow bg4 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem">
                            Cancel
                        </span>
                        <span
                            onClick={() => { if (!loading) updateProfile() }}
                            className={`shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem ${loading && "button-disabled"}`}>
                            {Object.keys(userProfile).length == 0
                                ? "Upload"
                                : "Crop & Upload"}
                        </span>
                    </div>
                </div>
            </Modal>
        </>
    )
}