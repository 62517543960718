import { createAction, createSlice } from "@reduxjs/toolkit";

export default function dataRoomReducer() {
    const dataRoomSlice = createSlice({
        name: "DATAROOM",
        initialState: {
            syncing: false,
        },
        reducers: {
            SET_DATAROOM: (state, action) => {
                return {
                    ...state,
                    ...action.payload
                };
            },
            SET_SYNC_DATAROOM_STATUS: (state, action) => {
                return {
                    ...state,
                    syncing: action.payload.syncing
                };
            }
        }
    });

    return dataRoomSlice.reducer;
}

export const setDataroomConfig = createAction("DATAROOM/SET_DATAROOM");
export const setSyncDataRoomStatus = createAction("DATAROOM/SET_SYNC_DATAROOM_STATUS");

